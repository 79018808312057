import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Breadcrumb, Divider, Row, Col, Typography, Form, Select, Table, Input, Spin, Button, Space, notification } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    rowColNomeAndTipo,
    inputNome,
    inputSelectTipoDaAssociacao,
    botaoSalvar,
    inputSelectEstadosCity,
    inputSelectCidades,
} from './GridStyle';
import Icon from '@ant-design/icons';
import { FaSitemap } from 'react-icons/fa';
import axios from 'axios';
import {
    urlBuscaCidadesPorIdEstado, urlBuscaEstados, urlAssocCreate, urlAssocUpdate
} from '../../../../services/request/urls';
import './index.css';

import { getToken } from '../../../../services/token';
import { removeAcento } from '../../../../utils/RemoveAcentos';

const { Title, Text } = Typography;

const IconHand = (props: any) => <Icon {...props} component={FaSitemap} ></Icon>

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
}

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    estadoId: number,
    nome: string,
    uf: string,
    uuid: string
};

interface dadosDaTabelaAssoc {
    id: string;
    nome: string;
    estado: string;
};

const AdicionarAssociacao: React.FC = () => {

    const formRef = useRef<any>(null);
    const refFormSelectRota = useRef<any>(null);
    const refFormCidadesSelect = useRef<any>(null);
    const refFormEstadoCitySelect = useRef<any>(null);
    const refBotaoEstadoCity = useRef<any>(null);
    const refDadosCidades = useRef<any>([]);
    const refDadosTotaisCidades = useRef<any>([]);

    const history = useHistory();

    // localStorage.setItem('@GovFacil:userData', JSON.stringify(this.user));

    // userData = JSON.parse(localStorage.getItem('@GovFacil:userData');

    //Search da tabela de listagem
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDadosCidades>>([]);
    const [disableSelectTipoAssoc, setDisableSelectTipoAssoc] = useState(false);
    const [isHiddenCidades, setIsHiddenCidades] = useState(false);

    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(true);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [isOneEstadoOr, setIsOneEstadoOr] = useState(false);

    //Lista de estados antes da cidades
    const [dadosEstadosAndCityList, setDadosEstadosAndCityList] = useState<Array<interfaceDadosEstados>>([]);
    const [loadingEstadosAndCitySelected, setLoadingEstadosAndCitySelected] = useState(false);

    const [dadosCidades, setDadosCidades] = useState<Array<interfaceDadosCidades>>([]);
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);
    const [dadosTotaisCidades, setDadosTotaisCidades] = useState<Array<interfaceDadosCidades>>([]);

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:associacao');
        }

        //remover as requisiçoes quando não terminou
        signal.current.cancel("Requisicao cancelada!")
    }, []);

    //setando dados das cidades no selected
    const buscaEstadosAndCityESetSelected = useCallback(() => {
        setLoadingEstadosAndCitySelected(true);
        const buscaEstados = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosEstadosAndCityList(resultCidade.data);
                setLoadingEstadosAndCitySelected(false);
            } catch (error) {
                setLoadingEstadosAndCitySelected(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });
            }
        }

        buscaEstados();

    }, []);

    //funcao de setar cidades na tabela no select e estado no select quando for para editar contrato
    const buscaCityAndSetTabelaParaEditar = useCallback((arrayidEstado: Array<number> | [], arrayidCidade: Array<any> | []) => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {

                //verifica se o arau de estado enviado e diferented e 0
                if (arrayidEstado.length !== 0) {

                    switch (localUserData.tipo) {
                        case "Regional":
                            formRef.current.setFieldsValue(
                                {
                                    estadosCityS: arrayidEstado[0],
                                }
                            );
                            break;
                        case "Estadual":
                            formRef.current.setFieldsValue(
                                {
                                    estadosCityS: arrayidEstado[0],
                                }
                            );
                            break;
                        case "Federal":
                            formRef.current.setFieldsValue(
                                {
                                    estadosCity: arrayidEstado,
                                }
                            );
                            break;

                        default:
                            break;
                    }

                    //variaveis que receber todos as cidades selecionadas
                    let citySelecTable: any = [];
                    //todos cidades nao selecionada dos estados que foram selecionados
                    let cityNaoSelecTable: any = [];

                    //e total das cidades
                    let resultAxiosData: any = [];

                    //percorre o arrai de stado para fazer requisicao
                    for (let index = 0; index < arrayidEstado.length; index++) {
                        const idEstado = arrayidEstado[index];


                        let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                cancelToken: signal.current.token
                            });


                        let cidadesNSelecionadas: any = resultCidade.data;
                        let cidadesSelecionadasTable: any = [];

                        //percorremos o arrai de cidades selecionadas
                        for (let iCidade = 0; iCidade < arrayidCidade.length; iCidade++) {
                            const cidadeObj = arrayidCidade[iCidade];

                            //compara se a cidade que foi selecionada e do estado que fez a busca
                            if (cidadeObj.estadoId === idEstado) {

                                //retorna todas as cidades nao selecionadas desse estado
                                cidadesNSelecionadas = cidadesNSelecionadas.filter((val: any) => {
                                    return val.id !== cidadeObj.id
                                })

                                //seta todas as cidades que foi selecionada desse estado
                                resultCidade.data.forEach((element: any) => {
                                    if (element.id === cidadeObj.id) {
                                        cidadesSelecionadasTable.push(element);
                                    }
                                });


                            }
                        }

                        //da push adicionando nas variaveis fora do for para dar setState de uma unica vez
                        cidadesSelecionadasTable.forEach((element: any) => {
                            citySelecTable.push(element);
                        });
                        cidadesNSelecionadas.forEach((element: any) => {
                            cityNaoSelecTable.push(element);
                        });
                        resultCidade.data.forEach((element: any) => {
                            resultAxiosData.push(element);
                        });

                    }

                    //desbloqueia o campo cidade
                    setIsHiddenCidades(true);

                    //setando no select de cidade as cidade que nao estado na tabela
                    refDadosCidades.current = cityNaoSelecTable;
                    setDadosCidades(cityNaoSelecTable);

                    //setando o total de tudo
                    refDadosTotaisCidades.current = resultAxiosData;
                    setDadosTotaisCidades(resultAxiosData);

                    //setando os dados da tabela
                    setDadosTabelaState(citySelecTable);
                }

                setLoadingCidadesSelected(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    const comonentDidMount = useEffect((): void => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            buscaEstadosAndCityESetSelected();

            setDisableSelectTipoAssoc(true);
            formRef.current.setFieldsValue(
                {
                    nome: localUserData?.nome ? localUserData.nome : undefined,
                    tipo: localUserData?.tipo ? localUserData.tipo : undefined,
                    observacao: localUserData?.observacao ? localUserData.observacao : undefined,
                    modalidade: localUserData?.modalidade?.id ? localUserData.modalidade.id : undefined,
                }
            );
            setIsSalvarOrEditar(false);

            if (localUserData?.tipo === 'Federal') {
                setIsOneEstadoOr(true);
            } else {
                setIsOneEstadoOr(false);
            }

            if (localUserData?.cidades && localUserData?.estados && localUserData?.cidades.length !== 0 &&
                localUserData?.estados.length !== 0) {

                buscaCityAndSetTabelaParaEditar(localUserData.estados, localUserData.cidades)

            }
        }
    }, [buscaEstadosAndCityESetSelected]);

    //setando dados das cidades no selected
    const buscaCityAndSetSelected = useCallback((idEstado: number) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });

                if (refDadosTotaisCidades.current.length == 0) {
                    //para poder adicionar no array
                    refDadosCidades.current = resultCidade.data;
                    setDadosCidades(resultCidade.data);

                    refDadosTotaisCidades.current = resultCidade.data;
                    setDadosTotaisCidades(resultCidade.data);
                    setLoadingCidadesSelected(false);
                } else {
                    //adicionar no array com outras cidades

                    let arrayCidades: any = [];

                    refDadosCidades.current.map((valor: any) => {
                        arrayCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayCidades.push(valor);
                    });

                    refDadosCidades.current = arrayCidades;
                    setDadosCidades(arrayCidades);

                    let arrayToddasCidades: any = [];

                    refDadosTotaisCidades.current.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });

                    refDadosTotaisCidades.current = arrayToddasCidades;
                    setDadosTotaisCidades(arrayToddasCidades);

                    setLoadingCidadesSelected(false);
                }

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    const onDeselectEstadosAndCity = useCallback((id: number) => {
        if (dadosTabelaState.length != 0) {

            let filter = dadosTabelaState.filter((val) => {
                return val.estadoId != id
            })

            setDadosTabelaState(filter);

        }
        let filter = dadosCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosCidades.current = filter;
        setDadosCidades(filter);

        let filterTotais = dadosTotaisCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosTotaisCidades.current = filterTotais;
        setDadosTotaisCidades(filterTotais);

        if (formRef.current.getFieldValue('estadosCity').length == 0) {
            setIsHiddenCidades(false);
        }

    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades, dadosEstadosAndCityList]);

    const onSelectEstadosAndCity = useCallback((e: number) => {
        setDisableSelectTipoAssoc(true);
        setIsHiddenCidades(true);
        buscaCityAndSetSelected(e);
    }, [buscaCityAndSetSelected]);

    const onSelectEstadosAndCitySemSerMultiple = useCallback((e: number) => {

        refDadosCidades.current = [];
        setDadosCidades([]);
        refDadosTotaisCidades.current = [];
        setDadosTotaisCidades([]);
        setDadosTabelaState([]);

        setDisableSelectTipoAssoc(true);
        setIsHiddenCidades(true);
        buscaCityAndSetSelected(e);
    }, [buscaCityAndSetSelected]);

    const onClickAddTodosEstadosCidades = useCallback(() => {
        setDisableSelectTipoAssoc(true);
        let valInputEstados = formRef.current.getFieldValue('estadosCity');

        if (formRef.current.getFieldValue('estadosCity')?.length != 27) {

            //verifica se inputs se ja foi preenchido
            if (valInputEstados != undefined && valInputEstados.length != 0) {

                //verificando qual esta selecionado qual nao foi selecionado
                let array: any = [];
                let arrayIdsTodos: any = [];
                dadosEstadosAndCityList.forEach((dadosEstadoCity) => {
                    arrayIdsTodos.push(dadosEstadoCity.id);
                    let bool = false;
                    valInputEstados.forEach((idInInput: number) => {

                        if (dadosEstadoCity.id == idInInput) {
                            bool = true
                        }
                    });

                    if (!bool) {
                        array.push(dadosEstadoCity);
                    }
                });

                array.forEach(async (estadosFiltro: any) => {
                    await buscaCityAndSetSelected(estadosFiltro.id);
                });

                formRef.current.setFieldsValue(
                    { estadosCity: arrayIdsTodos }
                );

            } else {
                //se nao foi preenchido
                let arrayIds: any = [];
                dadosEstadosAndCityList.forEach(async (estadosFiltro: any) => {

                    await buscaCityAndSetSelected(estadosFiltro.id);

                    arrayIds.push(estadosFiltro.id);
                });

                formRef.current.setFieldsValue(
                    { estadosCity: arrayIds }
                );
                setIsHiddenCidades(true);
            }

            refBotaoEstadoCity.current?.blur();
            refFormEstadoCitySelect.current?.blur();
        }
    }, [dadosEstadosAndCityList]);

    const onClickRemoverTodasAsCidadesContratoTable = useCallback((): void => {

        setDadosTabelaState([]);

        refDadosCidades.current = [];
        setDadosCidades([]);

        refDadosTotaisCidades.current = [];
        setDadosTotaisCidades([]);

        formRef.current.setFieldsValue(
            {
                estadosCity: [],
                estadosCityS: [],
            }
        );
        setIsHiddenCidades(false);

    }, [dadosTotaisCidades]);

    const onChangeTipoAssoc = useCallback((val): void => {
        switch (val) {
            case 'Estadual':
                setIsOneEstadoOr(false);
                setDadosTabelaState([]);
                buscaEstadosAndCityESetSelected();
                break;
            case 'Regional':
                setIsOneEstadoOr(false);
                setDadosTabelaState([]);
                buscaEstadosAndCityESetSelected();
                break;
            case 'Federal':
                setIsOneEstadoOr(true);
                setDadosTabelaState([]);
                buscaEstadosAndCityESetSelected();
                break;

            default:
                setDadosTabelaState([]);
                buscaEstadosAndCityESetSelected();
                break;
        }
    }, [buscaEstadosAndCityESetSelected, setIsOneEstadoOr]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, []);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //seact da tabela
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    // fim do search da tabela de listagem

    const excluirDaTabela = useCallback((recorde: any): void => {
        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val != recorde
        })
        setDadosTabelaState(filter);

        //incluirndo o valor removido no select
        let arrayTabela = dadosCidades.concat(recorde);

        refDadosCidades.current = arrayTabela;
        setDadosCidades(arrayTabela);
    }, [dadosTabelaState, dadosCidades]);

    //ao selecionar um item das rotas remove do select e add na tabela
    const onChangeSelectCidade = useCallback((e: number): void => {

        if (dadosTabelaState.length == 0) {

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    setDadosTabelaState([item]);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != item.id
                    })
                    refDadosCidades.current = filter
                    setDadosCidades(filter)
                }
            })
        } else {
            let arrayAddTodos: any = [];

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    const obj: any = {};

                    //Alimentado var obj
                    // obj["ibge_id_antigo"] = item.ibge_id_antigo
                    obj["id"] = item.id
                    obj["estadoId"] = item.estadoId
                    obj["uf"] = item.uf
                    // obj["latitude"] = item.latitude
                    // obj["longitude"] = item.longitude
                    // obj["microrregiao"] = item.microrregiao
                    // obj["microrregiao_id"] = item.microrregiao_id
                    obj["nome"] = item.nome
                    // obj["populacao"] = item.populacao
                    obj["uuid"] = item.uuid

                    arrayAddTodos.push(obj);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != e
                    })

                    refDadosCidades.current = filter
                    setDadosCidades(filter);
                }
            })

            dadosTabelaState.forEach((valor: dadosDaTabela | interfaceDadosCidades) => {
                arrayAddTodos.push(valor);
            });

            setDadosTabelaState(arrayAddTodos);
        }
        formRef.current.setFieldsValue({ "cidadesSelect": null });

        refFormCidadesSelect.current?.blur();
    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasCidadesInTable = useCallback(() => {

        if (dadosCidades.length != 0) {

            let arrayAddTodos: any = [];
            let tabelaISVazia = false;

            if (dadosTabelaState.length != 0) {
                tabelaISVazia = true;
            }

            //SALVAR DADOS
            dadosCidades.forEach((valor) => {

                const obj: any = {};

                //Alimentado var obj
                // obj["ibge_id_antigo"] = valor.ibge_id_antigo
                obj["id"] = valor.id
                obj["estadoId"] = valor.estadoId
                obj["uf"] = valor.uf
                // obj["latitude"] = valor.latitude
                // obj["longitude"] = valor.longitude
                // obj["microrregiao"] = valor.microrregiao
                // obj["microrregiao_id"] = valor.microrregiao_id
                obj["nome"] = valor.nome
                // obj["populacao"] = valor.populacao
                obj["uuid"] = valor.uuid

                arrayAddTodos.push(obj);
            });

            //se a tabela não é vazia
            if (tabelaISVazia) {
                dadosTabelaState.forEach((valor: interfaceDadosCidades) => {
                    arrayAddTodos.push(valor);
                });
            }

            setDadosTabelaState(arrayAddTodos);

            refDadosCidades.current = [];
            setDadosCidades([]);

            //Setando null em cidade
            formRef.current.setFieldsValue({ "cidadesSelect": null });
        }

    }, [dadosCidades, dadosTabelaState]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado'),
            render: (text: any, record: any) => {
                if (text) {
                    return text
                } else {
                    return record.uf
                }
            }
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <Button type="primary" danger onClick={() => excluirDaTabela(record)}>
                        <DeleteOutlined />
                    </Button>
                ) : null,
        },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/associacao");
    }, [history]);

    const onClickBotaoSalvar = useCallback((): void => {

        const salvarDadosModal = async (valores: any) => {
            setLoadingFormAdd(true);
            try {

                let resultCidade = await axios.post(urlAssocCreate, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                setLoadingFormAdd(false);
                history.push("/associacao");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',

                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                if (dadosTabelaState.length != 0) {
                    //seta dados da tabela em obj da requisição
                    values.cidades = dadosTabelaState

                    salvarDadosModal(values);
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Selecione uma cidade!',
                    });
                }

            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dadosTabelaState]);

    const onClickBotaoEditar = useCallback((): void => {

        const EditarDados = async (valores: any) => {
            setLoadingFormAdd(true);
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
            }
            try {

                valores.id = localUserData.id

                let resultCidade = await axios.put(urlAssocUpdate, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });
                setLoadingFormAdd(false);
                history.push("/associacao");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                if (dadosTabelaState.length != 0) {
                    //seta dados da tabela em obj da requisição
                    values.cidades = dadosTabelaState

                    EditarDados(values);
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Selecione uma cidade!',
                    });
                }

            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dadosTabelaState]);

    const onClickBotaoCancelar = useCallback((): void => {
        history.push("/associacao");
    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconHand style={{ fontSize: 35, marginRight: 10 }} />  {isSalvarOrEditar ? "Adicionar" : "Editar"} Associação</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Associações</a></Breadcrumb.Item>
                        <Breadcrumb.Item> {isSalvarOrEditar ? "Adicionar" : "Editar"} Associação</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col {...rowColNomeAndTipo}>
                    <Spin spinning={loadingFormAdd}>
                        <Form
                            ref={formRef}
                            name="formAdicionarAssociacoes"
                            layout="vertical"
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col {...inputNome}>
                                    <Form.Item
                                        name="nome"
                                        label="Nome"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor digite o nome!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>
                                <Col {...inputSelectTipoDaAssociacao}>
                                    <Form.Item
                                        name="tipo"
                                        label="Tipo da Associação"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione a esfera!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            disabled={disableSelectTipoAssoc}
                                            optionFilterProp="children"
                                            onChange={onChangeTipoAssoc}
                                            placeholder="Selecione..."
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            <Select.Option value="Regional">Regional</Select.Option>
                                            <Select.Option value="Estadual">Estadual</Select.Option>
                                            <Select.Option value="Federal">Federal</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <>
                                    {isOneEstadoOr ?
                                        <Col {...inputSelectEstadosCity}>
                                            <Form.Item
                                                name="estadosCity"
                                                label="Estados"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Por favor selecione um estado!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    ref={refFormEstadoCitySelect}
                                                    showSearch
                                                    loading={loadingEstadosAndCitySelected}
                                                    notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                                    placeholder="Selecione..."
                                                    mode="multiple"
                                                    onDeselect={onDeselectEstadosAndCity}
                                                    onSelect={onSelectEstadosAndCity}
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                    dropdownRender={menu => (
                                                        <div>

                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Button
                                                                    ref={refBotaoEstadoCity}
                                                                    block onClick={onClickAddTodosEstadosCidades}
                                                                    type="dashed"
                                                                >Todos</Button>
                                                            </div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            {menu}
                                                        </div>
                                                    )}
                                                >
                                                    {
                                                        dadosEstadosAndCityList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        :
                                        <Col {...inputSelectEstadosCity}>
                                            <Form.Item
                                                name="estadosCityS"
                                                label="Estados"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Por favor selecione um estado!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    ref={refFormEstadoCitySelect}
                                                    showSearch
                                                    loading={loadingEstadosAndCitySelected}
                                                    notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                                    placeholder="Selecione..."
                                                    onSelect={onSelectEstadosAndCitySemSerMultiple}
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dadosEstadosAndCityList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {isHiddenCidades ?
                                        <Col {...inputSelectCidades}>
                                            <Form.Item
                                                name="cidadesSelect"
                                                label="Cidade"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Por favor selecione uma cidade!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    onChange={onChangeSelectCidade}
                                                    ref={refFormCidadesSelect}
                                                    showSearch
                                                    loading={loadingCidadesSelected}
                                                    notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                                    placeholder="Selecione..."
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                    dropdownRender={menu => (
                                                        <div>

                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Button block onClick={onClickAddTodasCidadesInTable} type="dashed">Todos</Button>
                                                            </div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            {menu}
                                                        </div>
                                                    )}
                                                >
                                                    {
                                                        dadosCidades.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome + " - " + item.uf}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : ""}
                                </>
                            </Row>
                        </Form>
                    </Spin>
                </Col>
                <Col {...cardLayout}>
                    <Text>Associados</Text><Button onClick={onClickRemoverTodasAsCidadesContratoTable} hidden={dadosTabelaState.length != 0 ? false : true} className="botaoRemoverCidadesContratoAll" danger type="primary">Remover Todos</Button>
                    <Table
                        rowKey={(record) => record.uuid}
                        style={{ marginTop: 6 }}
                        className="tabelaAssociacaoAdd"
                        size="small"
                        columns={columns} dataSource={dadosTabelaState}
                        scroll={{ y: 200 }}
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Row>
                <Col {...botaoSalvar}>
                    <Button style={{ marginRight: 10 }} onClick={onClickBotaoCancelar} >
                        Cancelar
                    </Button>
                    {isSalvarOrEditar ?
                        <Button type="primary" onClick={onClickBotaoSalvar} >
                            Salvar
                        </Button>
                        :
                        <Button type="primary" onClick={onClickBotaoEditar} >
                            Atualizar
                        </Button>
                    }
                </Col>
            </Row>

        </>
    );
}


export default AdicionarAssociacao;
