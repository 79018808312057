import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
    Col, Row, Form, notification, Select, Divider, Button,
    Typography, Table, Input, Space, Spin, Modal
} from 'antd';
import { DeleteOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
    selectedPoderOrgaoAdd,
    modulosCidadesInPermApp,
    moduloEstadosInPemApp,
    modulosIndicesGerenciaisInPemApp,
    modulosProcessosJuridicosInPemApp,
    modulosProcessosJuridicosEstadosInPermApp,
    modulosSINCOVEstadoInPermApp,
    modulosSINCOVCidadesPermInApp,
    selectedComparacoesAdd,
    complementoFimComparacoesCidades,
    rowColNomeAndTipo,
    inputSelectTipoDaAssociacao,
    modulosIndicadoresGerenciaisEstadosInPermApp,
    inputSelectEstadosCity,
    inputSelectCidades,
    cardLayout,
    modalListDeEstados
} from './GridStyle';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';

// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as UsuariosActions from '../../../../store/modules/usuario/actions';
import axios from 'axios';
import {
    urlCidadesContrato, urlBuscaModulosApp,
    urlContratoEstados, urlModulo, urlBuscaEstadosUsuarioContrato,
    urlCidadesUsuarioContrato, urlModulosUsuarioContrato
} from './../../../../services/request/urls';
import { getToken } from './../../../../services/token';
import { useGetColumnSearchProps } from '../../../../hooks/FilterTable/stringCLearFiltros';

const { Text } = Typography;

// import { stateGlobal } from '../../../../interfaces/Global';

interface interfaceModulos {
    "id": number,
    "uuid": string,
    "nome": string,
    "descricao": string | null,
    "path": string,
    "status": boolean,
    "modo_estado": boolean
}
interface interfaceModulosPermissao {
    "id": number,
    modulo?: {
        "id": number,
        "uuid": string,
        "nome": string,
        "descricao": string | null,
        "path": string,
        "status": boolean,
        "modo_estado": boolean
    }
};

interface interfaceModulosItem {
    id: number,
    nome: string,
    moduloId: number
};

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
};

interface interfaceDadosCidades {
    id: number,
    ibge_id_antigo: number,
    nome: string,
    microrregiao: {
        id: number,
        mesorregiao: {
            id: number,
            estado: {
                id: number,
                sigla: string,
                regiao_id: number,
                nome: string,
                populacao: number
            },
            estado_id: number,
            nome: string
        },
        mesorregiao_id: number,
        nome: string
    },
    microrregiao_id: number,
    latitude: string,
    longitude: string,
    populacao: number
};

interface interfaceDadosCidadesBuscaContrato {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string,
    estadoSigla: string
};

interface interfaceEstdaos {
    estadoId: number,
    estadoNome: string,
    estadoSigla: string
}

const PermissoesAplicativo: React.FC = () => {


    const refFuncaoLimparFiltrosStringCidade = useRef<any>(null);
    const refFuncaoLimparFiltrosStringEstado = useRef<any>(null);

    const formRef = useRef<any>(null);

    const refSelectListModulosAPP = useRef<any>(null);
    const refBotaoAddTodosModulosAPP = useRef<any>(null);
    const refBotaoLimparModulosAPP = useRef<any>(null);

    const refFormModoEstadoSelect = useRef<any>(null);
    const refBotaoModoEstado = useRef<any>(null);
    const refBotaoLimparModoEstado = useRef<any>(null);

    const refFormCidadesSelect = useRef<any>(null);
    const refDadosCidades = useRef<any>([]);
    const refDadosTotaisCidades = useRef<any>([]);

    const refInitValueModulosCidades = useRef<any>([]);

    const refdadosCidadesCopyState = useRef<any>([]);
    const refAllObjEstadosParaAddCidades = useRef<any>([]);
    const refAllArrIDEstadosParaAddCidades = useRef<any>([]);

    const dispatch = useDispatch();

    const [dadosCidades, setDadosCidades] = useState<Array<interfaceDadosCidadesBuscaContrato>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDadosCidadesBuscaContrato>>([]);
    // const [dadosTotaisCidades, setDadosTotaisCidades] = useState<Array<interfaceDadosCidadesBuscaContrato>>([]);

    //estados modo
    const [dadosEstadosGovList, setDadosEstadosGovList] = useState<Array<interfaceDadosEstados>>([]);
    const [loadingEstadosGovSelected, setLoadingEstadosGovSelected] = useState(false);

    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);
    const [loadingModulosSelected, setLoadingModulosSelected] = useState(false);

    const [dadosTotaisCidades, setDadosTotaisCidades] = useState<Array<interfaceDadosCidadesBuscaContrato>>([]);

    //list modulos
    const [loadingDosModulos, setloadingDosModulos] = useState(false);
    const [dadosModulosCidadesList, setDadosModulosCidadesList] = useState<Array<interfaceModulos>>([]);

    const [obrigatorioModoCidadeEstado, setObrigatorioModoCidadeEstado] = useState(true);

    const [modalVisibleSelectCidadesPorEstado, setModalVisibleSelectCidadesPorEstado] = useState(false);
    const [modalObjEstadosList, setModalObjEstadosList] = useState<Array<interfaceEstdaos>>([]);

    //Search da tabela de listagem
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());
    const signal1 = useRef(axios.CancelToken.source());
    const signal2 = useRef(axios.CancelToken.source());
    const signal3 = useRef(axios.CancelToken.source());

    //setando dados das cidades no selected
    const setarCidadesNaTabelaPeloIdEstado = useCallback((idEstado): void => {

        const setarCidadesPorEstadofunc = async () => {

            try {

                let tabelaISVazia = false;

                if (dadosTabelaState.length != 0) {
                    tabelaISVazia = true;
                }

                let objCidadesSetar: any = [];
                let objCidadesNaoSelecionadas: any = [];
                refdadosCidadesCopyState.current.forEach((
                    cidades: interfaceDadosCidadesBuscaContrato,
                    indexCidades: number
                ) => {

                    if (cidades.estadoId === idEstado) {
                        objCidadesSetar.push(cidades)
                    } else {
                        objCidadesNaoSelecionadas.push(cidades)
                    }

                })

                //se a tabela não é vazia
                if (tabelaISVazia) {
                    dadosTabelaState.forEach((valor: interfaceDadosCidadesBuscaContrato) => {
                        objCidadesSetar.push(valor);
                    });
                }


                //setando na tabela e em seguida no select
                setDadosTabelaState(objCidadesSetar);

                refDadosCidades.current = objCidadesNaoSelecionadas;
                setDadosCidades(objCidadesNaoSelecionadas);
                refdadosCidadesCopyState.current = objCidadesNaoSelecionadas



                //remover estado dos arr
                let indice = refAllArrIDEstadosParaAddCidades.current.indexOf(idEstado);


                let objEstados: any = [...refAllObjEstadosParaAddCidades.current];
                let arrEstadosID: any = [...refAllArrIDEstadosParaAddCidades.current];
                if (indice != -1) {

                    objEstados.splice(indice, 1)
                    arrEstadosID.splice(indice, 1)

                }

                refAllObjEstadosParaAddCidades.current = objEstados;
                refAllArrIDEstadosParaAddCidades.current = arrEstadosID;
                setModalObjEstadosList(objEstados)


            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        setarCidadesPorEstadofunc();

    }, [dadosTabelaState]);

    //setando dados das cidades no selected
    const setarBotoesCidadesPoEstados = useCallback((): void => {

        const setarCidadesPorEstadofunc = async () => {

            try {

                let objEstados: any = [];
                let arrEstadosID: any = [];
                refdadosCidadesCopyState.current.forEach((
                    cidades: interfaceDadosCidadesBuscaContrato,
                    indexCidades: number
                ) => {

                    if (objEstados.lenght === 0) {
                        objEstados.push({
                            estadoId: cidades.estadoId,
                            estadoNome: cidades.estadoNome,
                            estadoSigla: cidades.estadoSigla,
                        })
                        arrEstadosID.push(cidades.estadoId)
                    } else {

                        if (arrEstadosID.includes(cidades.estadoId)) {

                        } else {
                            objEstados.push({
                                estadoId: cidades.estadoId,
                                estadoNome: cidades.estadoNome,
                                estadoSigla: cidades.estadoSigla,
                            })
                            arrEstadosID.push(cidades.estadoId)
                        }

                    }

                })

                refAllObjEstadosParaAddCidades.current = objEstados;
                refAllArrIDEstadosParaAddCidades.current = arrEstadosID;
                setModalObjEstadosList(objEstados)



            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        setarCidadesPorEstadofunc();

    }, []);

    //setando dados das cidades no selected
    const buscaCidadesDoContratoESetSelected = useCallback((setarBotoes?: boolean): Promise<boolean> => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;

        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscacidadesdoContrato = async () => {
            setLoadingCidadesSelected(true);
            try {
                let resultCidade = await axios.get(urlCidadesContrato + "/" + localUserData.step2.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal2.current.token
                    });

                if (resultCidade.data.length === 1) {
                    setDadosTabelaState(resultCidade.data);

                } else {

                    setDadosCidades(resultCidade.data);
                    refdadosCidadesCopyState.current = resultCidade.data
                }

                refDadosTotaisCidades.current = resultCidade.data;
                setDadosTotaisCidades(resultCidade.data);

                if (setarBotoes) {
                    setarBotoesCidadesPoEstados();
                }

                setLoadingCidadesSelected(false);
                return true


            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
                setLoadingCidadesSelected(false);
                return false
            }
        }

        if (localUserData?.step2) {

            return buscacidadesdoContrato();

        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio Selecionar contrato!',
            });

            return new Promise<boolean>((resolve) => {
                resolve(false);
            });
        }

    }, []);

    //setando dados das cidades no selected
    const buscaCidadesDoContratoEReturnData = useCallback((): any => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;

        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscacidadesdoContrato = async () => {
            setLoadingCidadesSelected(true);
            try {
                let resultCidade = await axios.get(urlCidadesContrato + "/" + localUserData.step2.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal2.current.token
                    });

                setLoadingCidadesSelected(false);
                return resultCidade.data;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                    setLoadingCidadesSelected(false);
                    return new Promise<[]>((resolve, reject) => {
                        resolve([])
                    });
                }
            }
        }

        if (localUserData?.step2) {

            return buscacidadesdoContrato();

        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio Selecionar contrato!',
            });
            return new Promise<[]>((resolve, reject) => {
                resolve([])
            });
        }

    }, []);

    //setando dados das cidades no selected
    const buscaEstadosGov = useCallback(() => {

        //setar valors iniciais nos campos se nao existir local storage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;

        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscaEstados = async () => {
            setLoadingEstadosGovSelected(true);
            try {
                let resultCidade = await axios.get(urlContratoEstados + "/" + localUserData.step2.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });
                setDadosEstadosGovList(resultCidade.data);

                let idsEstados: Array<number> = []
                if (resultCidade.data) {
                    resultCidade.data.forEach((element: any) => {
                        idsEstados.push(element.id)
                    });
                }

                setLoadingEstadosGovSelected(false);
                return idsEstados
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
                setLoadingEstadosGovSelected(false);
                return [-1]
            }
        }

        if (localUserData?.step2) {

            return buscaEstados();

        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio Selecionar contrato!',
            });
            return [-1]
        }


    }, []);

    const comonentDidMount = useEffect((): void => {

        //para poder construir todo momento
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //scroll sempre no topo
        window.scrollTo(0, 0);

        dispatch(UsuariosActions.formularioRefUsers(formRef));

        //INICIO REQUISICAO MODULOS

        // setloadingDosModulos(true)
        // dispatch(UsuariosActions.setSpinnerStep(true));
        const buscaModulos = async () => {
            try {
                setLoadingModulosSelected(true);

                const arrayIdsEstadosPermitidosReq = await buscaEstadosGov();

                // let resultModulos = await axios.get(urlBuscaModulosApp,
                let resultModulos = await axios.get(urlModulo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal3.current.token
                    });

                //iniciar setandoValores
                let arrayModulos: any = [];

                let initValueModulos: Array<any> = [];

                resultModulos.data.forEach((modulos: interfaceModulos) => {

                    if (modulos.status) {
                        //array de ids dos modulos
                        initValueModulos.push(modulos.id);

                        //array de modulos
                        arrayModulos.push(modulos);
                    }

                });


                //Setando todos modulos in
                setDadosModulosCidadesList(arrayModulos);

                //VAR REF DE VALORES INICIAIS
                refInitValueModulosCidades.current = [...initValueModulos];

                //setar valors iniciais nos campos se nao existir local storage
                //pega dados do localStorage se existir

                if (localUserData?.step3) {

                    setTimeout(() => {
                        formRef.current.setFieldsValue(
                            {
                                modulos: localUserData?.step3.modulos ? localUserData?.step3.modulos : initValueModulos,
                                estados: localUserData?.step3.estados ? localUserData?.step3.estados : undefined,
                                cidadesSelect: localUserData?.step3.cidadesSelect ? localUserData?.step3.cidadesSelect : undefined,
                            }
                        );

                        //caso tenha cidades selecionadas no localstorage
                        if (localUserData?.step3?.cidades && localUserData?.step3?.cidades?.length !== 0) {

                            //retorna cidades buscadas da requisicao
                            buscaCidadesDoContratoEReturnData().then((valorPromessa: Array<interfaceDadosCidadesBuscaContrato>) => {

                                let isEqualsCity = false;
                                let copyCidadesSelected = [...valorPromessa];

                                for (let index = 0; index < valorPromessa.length; index++) {
                                    const objTOTALcity = valorPromessa[index];

                                    localUserData.step3.cidades.forEach((element: any, posicao: any) => {
                                        if (objTOTALcity.uuid == element.uuid) {
                                            isEqualsCity = true;
                                        }
                                    });

                                    if (isEqualsCity) {
                                        copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                            return valores.uuid != objTOTALcity.uuid
                                        })
                                        isEqualsCity = false
                                    }

                                }

                                refDadosTotaisCidades.current = valorPromessa;
                                setDadosTotaisCidades(valorPromessa);

                                //caso nao tenha apenas uma cidade
                                if (copyCidadesSelected.length === 1 &&
                                    !localUserData.step3?.cidades ||
                                    localUserData.step3?.cidades?.length === 0) {

                                    setDadosTabelaState(copyCidadesSelected);

                                } else {
                                    setDadosCidades(copyCidadesSelected);
                                    refdadosCidadesCopyState.current = copyCidadesSelected

                                    setDadosTabelaState(localUserData.step3.cidades);

                                    setarBotoesCidadesPoEstados();
                                }

                            }).catch((error: any) => {
                                notification.error({
                                    message: 'Erro',
                                    description:
                                        'Não foi possivel buscar os dados!',
                                });
                            })

                        } else {
                            //caso nao tenha localstorage step cidades busca o slect de cidades contrato
                            buscaCidadesDoContratoESetSelected(true);

                        }

                        //funcao valida de foi selecionado modo estado ou cidade
                        if (localUserData.step3?.cidades && localUserData.step3?.cidades.length != 0 ||
                            localUserData.step3?.estados && localUserData.step3?.estados.length != 0) {
                            setObrigatorioModoCidadeEstado(false);
                        } else {
                            setObrigatorioModoCidadeEstado(true);
                        }

                    }, 100);

                } else {

                    //VERIFICAR SE ESTA EDITANDO OU CRIANDO

                    if (localUserData?.isEdit) {

                        let resultadosDoEditarEstado = await axios.get(
                            urlBuscaEstadosUsuarioContrato(localUserData?.idUser, localUserData?.step2?.contrato),
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() },
                                cancelToken: signal3.current.token,
                            }
                        );


                        //Setando valor no estados
                        if (resultadosDoEditarEstado?.data) {

                            let modosEstadosID: any = [];
                            resultadosDoEditarEstado.data.forEach((element: {
                                id: number, nome: string
                            }, posicao: any) => {

                                if (arrayIdsEstadosPermitidosReq.includes(element.id)) {

                                    modosEstadosID.push(element.id)
                                }
                            });

                            formRef.current.setFieldsValue(
                                { estados: modosEstadosID }
                            );
                        }

                        let resultadosDoEditarCidades = await axios.get(
                            urlCidadesUsuarioContrato(localUserData?.idUser, localUserData?.step2?.contrato),
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() },
                                cancelToken: signal3.current.token,
                            }
                        );

                        //setando valor na cidade pois necesario biscar cidades do contrato
                        await buscaCidadesDoContratoEReturnData().then((valorPromessa: Array<interfaceDadosCidadesBuscaContrato>) => {

                            let isEqualsCity = false;
                            let copyCidadesSelected = [...valorPromessa];

                            let testeCidadesParaSetar: any = []

                            for (let index = 0; index < valorPromessa.length; index++) {
                                const objTOTALcity = valorPromessa[index];

                                resultadosDoEditarCidades.data.forEach((element: any, posicao: any) => {
                                    if (objTOTALcity.id == element.id) {
                                        isEqualsCity = true;

                                        testeCidadesParaSetar.push(objTOTALcity);
                                    }
                                });

                                if (isEqualsCity) {
                                    copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                        return valores.id != objTOTALcity.id
                                    })
                                    isEqualsCity = false
                                }

                            }

                            //esta editando ja, nao precisa setar apenas um
                            refDadosTotaisCidades.current = valorPromessa;
                            setDadosTotaisCidades(valorPromessa);

                            // if (copyCidadesSelected.length === 1 &&
                            //     testeCidadesParaSetar.length === 0) {

                            //     setDadosTabelaState(copyCidadesSelected);

                            // } else {
                            setDadosCidades(copyCidadesSelected);
                            refdadosCidadesCopyState.current = copyCidadesSelected

                            setDadosTabelaState(testeCidadesParaSetar);

                            setarBotoesCidadesPoEstados();
                            // }

                        }).catch((error: any) => {

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel buscar os dados!',
                            });
                        })

                        //fim de setar valor nas cidades

                        let resultadosDoEditarModulos = await axios.get(
                            urlModulosUsuarioContrato(localUserData?.idUser, localUserData?.step2?.contrato),
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() },
                                cancelToken: signal3.current.token,
                            }
                        );

                        //SE ESTIVER VAZIO PREENCHER COM TOTAL DE VALORES CASO TIVER PREEENCHA COMM OS QUE TEM
                        if (resultadosDoEditarModulos.data && resultadosDoEditarModulos.data?.length != 0) {

                            //APENAS PARA SEPARAR OS VALORES DOS MODULOS
                            //iniciar setandoValores
                            let initValueModulosElse: any = [];

                            resultadosDoEditarModulos.data.forEach((modulos: interfaceModulosPermissao) => {

                                //verificando para poder setar os modulos somente nos modulos ativos
                                if (modulos?.modulo?.id && initValueModulos.includes(modulos.modulo.id)) {
                                    //var init value modulos
                                    initValueModulosElse.push(modulos.modulo.id);
                                }


                            });

                            //setando valores da busca
                            formRef.current.setFieldsValue(
                                {
                                    modulos: initValueModulosElse,
                                }
                            );

                        } else {
                            //se for diferente de vazio setar tudo
                            formRef.current.setFieldsValue(
                                {
                                    modulos: initValueModulos,
                                }
                            );
                        }



                    } else {

                        //caso nao tenha localstorage busca o slect de cidades contrato
                        await buscaCidadesDoContratoESetSelected();

                        setarBotoesCidadesPoEstados();

                        //setando valores totais, pq nao tem localStorage
                        formRef.current.setFieldsValue(
                            {
                                modulos: initValueModulos,
                            }
                        );
                    }
                }


                setLoadingModulosSelected(false);
                setloadingDosModulos(false)
                setTimeout(() => {

                    dispatch(UsuariosActions.setSpinnerStep(false));
                }, 800);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setloadingDosModulos(false)
                    dispatch(UsuariosActions.setSpinnerStep(false));
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Modulos!',
                    });
                }
                setLoadingModulosSelected(false);
            }
        }

        buscaModulos();

        //FIM REQUISICAO MODULOS

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.current.cancel("Requisicao cancelada!")
        signal1.current.cancel("Requisicao cancelada!")
        signal2.current.cancel("Requisicao cancelada!")
        signal3.current.cancel("Requisicao cancelada!")
    }, []);

    const onFinish = useCallback((formValues): void => {

    }, []);

    //modo estado e cidades
    const onClickAddTodosEstadosGov = useCallback(() => {

        if (formRef.current.getFieldValue('estados')?.length != 27) {
            let arrayIds: any = [];
            dadosEstadosGovList.forEach(val => {
                arrayIds.push(val.id);
            });
            formRef.current.setFieldsValue(
                { estados: arrayIds }
            );

            refBotaoModoEstado.current?.blur();
            refFormModoEstadoSelect.current?.blur();

            //pois foi add todos os estados modo estado e nao é mais obrigatorio
            setObrigatorioModoCidadeEstado(false);
        }
    }, [dadosEstadosGovList]);

    const onClickLimparTodosEstadosGov = useCallback(() => {

        if (formRef.current.getFieldValue('estados')?.length >= 1) {

            formRef.current.setFieldsValue(
                { estados: [] }
            );

            refBotaoLimparModoEstado.current?.blur();
            refFormModoEstadoSelect.current?.blur();

            //pois foi add todos os estados modo estado e nao é mais obrigatorio
            setObrigatorioModoCidadeEstado(true);
        }
    }, []);

    //Adicionar todos modulos no Campo
    const onClickAddTodosModulosAPP = useCallback(() => {

        if (formRef.current.getFieldValue('modulos')?.length != dadosModulosCidadesList.length) {
            let arrayIds: any = [];
            dadosModulosCidadesList.forEach(val => {
                arrayIds.push(val.id);
            });
            formRef.current.setFieldsValue(
                { modulos: arrayIds }
            );

            refBotaoAddTodosModulosAPP.current?.blur();
            refSelectListModulosAPP.current?.blur();

        }
    }, [dadosModulosCidadesList]);

    const onClickLimparTodosModulosAPP = useCallback(() => {

        if (formRef.current.getFieldValue('modulos')?.length >= 1) {

            formRef.current.setFieldsValue(
                { modulos: [] }
            );

            refBotaoLimparModulosAPP.current?.blur();
            refSelectListModulosAPP.current?.blur();

        }
    }, []);

    //ao selecionar um item das rotas remove do select e add na tabela
    const onChangeSelectCidade = useCallback((e: number): void => {

        if (dadosTabelaState.length == 0) {

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    setDadosTabelaState([item]);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != item.id
                    })
                    refDadosCidades.current = filter
                    setDadosCidades(filter)
                    refdadosCidadesCopyState.current = filter
                }
            })
        } else {
            let arrayAddTodos: any = [];

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    const obj: any = {};

                    //Alimentado var obj
                    obj["ibge_id_antigo"] = item.ibge_id_antigo
                    obj["id"] = item.id
                    obj["uuid"] = item.uuid
                    obj["latitude"] = item.latitude
                    obj["longitude"] = item.longitude
                    obj["nome"] = item.nome
                    obj["populacao"] = item.populacao
                    obj["microrregiaoId"] = item.microrregiaoId
                    obj["estadoId"] = item.estadoId
                    obj["estadoNome"] = item.estadoNome
                    obj["uf"] = item.estadoSigla

                    arrayAddTodos.push(obj);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != e
                    })

                    refDadosCidades.current = filter
                    setDadosCidades(filter);
                    refdadosCidadesCopyState.current = filter
                }
            })

            dadosTabelaState.forEach((valor: dadosDaTabela | interfaceDadosCidadesBuscaContrato) => {
                arrayAddTodos.push(valor);
            });

            setDadosTabelaState(arrayAddTodos);
        }
        formRef.current.setFieldsValue({ "cidadesSelect": null });

        refFormCidadesSelect.current?.blur();

        setarBotoesCidadesPoEstados();
    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasCidadesInTable = useCallback(() => {

        if (dadosCidades.length != 0) {

            let arrayAddTodos: any = [];
            let tabelaISVazia = false;

            if (dadosTabelaState.length != 0) {
                tabelaISVazia = true;
            }

            //SALVAR DADOS
            dadosCidades.forEach((valor) => {

                const obj: any = {};

                //Alimentado var obj
                obj["ibge_id_antigo"] = valor.ibge_id_antigo
                obj["id"] = valor.id
                obj["uuid"] = valor.uuid
                obj["latitude"] = valor.latitude
                obj["longitude"] = valor.longitude
                obj["nome"] = valor.nome
                obj["populacao"] = valor.populacao
                obj["microrregiaoId"] = valor.microrregiaoId
                obj["estadoId"] = valor.estadoId
                obj["estadoNome"] = valor.estadoNome
                obj["estadoSigla"] = valor.estadoSigla

                arrayAddTodos.push(obj);
            });

            //se a tabela não é vazia
            if (tabelaISVazia) {
                dadosTabelaState.forEach((valor: interfaceDadosCidadesBuscaContrato) => {
                    arrayAddTodos.push(valor);
                });
            }

            setDadosTabelaState(arrayAddTodos);

            refDadosCidades.current = [];
            setDadosCidades([]);
            refdadosCidadesCopyState.current = []

            //Setando null em cidade
            formRef.current.setFieldsValue({ "cidadesSelect": null });

            setarBotoesCidadesPoEstados();
        }


    }, [dadosCidades, dadosTabelaState]);

    const onClickRemoverTodasAsCidadesContratoTable = useCallback((): void => {

        setDadosTabelaState([]);

        refDadosCidades.current = [...refDadosTotaisCidades.current];
        setDadosCidades(refDadosTotaisCidades.current);
        refdadosCidadesCopyState.current = refDadosTotaisCidades.current


        setarBotoesCidadesPoEstados();
    }, []);

    const onFieldsChange = useCallback((changedFields, allFields): void => {
        let obj: any = new Object;
        allFields.forEach((element: any) => {
            let name = element.name[0];
            let value = element.value;
            obj[name] = value;
        });

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
            localUserData = {
                ...localUserData,
                "step3": obj
            }

            localUserData.step3.cidades = dadosTabelaState;

            //OBJ PARA PODER SALVAR modulos
            let objModuloItemCidades: any = [];
            if (obj?.modulos) {
                obj?.modulos.forEach((element: number) => {

                    let idModulos = {
                        "id": element
                    }

                    objModuloItemCidades.push(idModulos);
                });
            }
            let objEstadosSalvar: any = [];
            if (obj?.estados) {
                obj?.estados.forEach((element: number) => {

                    let idEstados = {
                        "id": element
                    }

                    objEstadosSalvar.push(idEstados);
                });
            }

            localUserData.step3.salvar = {
                "cidades": dadosTabelaState,
                "estados": objEstadosSalvar,
                "contratoId": localUserData.step2.contrato,
                "modulos": objModuloItemCidades
            }

            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

            //funcao valida de foi selecionado modo estado ou cidade
            if (localUserData.step3?.cidades && localUserData.step3?.cidades.length != 0 ||
                localUserData.step3?.estados && localUserData.step3?.estados.length != 0) {
                setObrigatorioModoCidadeEstado(false);

            } else {
                setObrigatorioModoCidadeEstado(true);
            }

        } else {
            localStorage.setItem('@GovFacil:userData', JSON.stringify({ "step3": obj }));
        }

    }, [dadosTabelaState]);

    //comonentDidUpdate
    useEffect(() => {
        let campoFormModoEstado = formRef.current.getFieldValue('estados')

        //funcao valida de foi selecionado modo estado ou cidade
        if (dadosTabelaState.length != 0 ||
            campoFormModoEstado && campoFormModoEstado.length != 0) {
            setObrigatorioModoCidadeEstado(false);

        } else {
            setObrigatorioModoCidadeEstado(true);
        }
    }, [dadosTabelaState]);

    //comonentDidUpdate
    useEffect(() => {
        setTimeout(() => {
            formRef?.current?.validateFields(['estados', 'cidadesSelect']);
        }, 1000);

    }, [obrigatorioModoCidadeEstado]);

    const excluirDaTabela = useCallback((recorde: any): void => {
        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val != recorde
        })
        setDadosTabelaState(filter);

        //incluirndo o valor removido no select
        let arrayTabela = dadosCidades.concat(recorde);

        refDadosCidades.current = arrayTabela;
        setDadosCidades(arrayTabela);
        refdadosCidadesCopyState.current = arrayTabela

        setarBotoesCidadesPoEstados();
    }, [dadosTabelaState, dadosCidades]);

    //seact da tabela
    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    //tabela luisgaem estado
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome', refFuncaoLimparFiltrosStringCidade),
        },
        {
            title: 'Estado',
            dataIndex: 'uf',
            ...useGetColumnSearchProps('uf', refFuncaoLimparFiltrosStringEstado),
            render: (text: any, record: any) => {
                if (text) {
                    return text
                } else {
                    return record.uf
                }
            }
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            width: '37%',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <Button type="primary" danger onClick={() => excluirDaTabela(record)}>
                        <DeleteOutlined />
                    </Button>
                ) : null,
        },
    ];

    return (
        <>
            <Spin spinning={
                (loadingEstadosGovSelected
                    || loadingCidadesSelected
                    || loadingModulosSelected)
            }>
                <Form
                    ref={formRef}
                    name="formNewUserStepPermissoesApp"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFieldsChange={onFieldsChange}
                    onFinish={onFinish}
                    onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        {dadosEstadosGovList.length != 0 ?
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        name="estados"
                                        label="Modo Estado (App)"
                                        rules={[
                                            {
                                                required: obrigatorioModoCidadeEstado,
                                                message: "Por favor selecione um estado!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            ref={refFormModoEstadoSelect}
                                            // loading={loadingEstadosGovSelected}
                                            // notFoundContent={loadingEstadosGovSelected ? <Spin size="small" /> : null}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            mode="multiple"
                                            filterOption={(input: any, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            dropdownRender={menu => (
                                                <div>

                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button
                                                            ref={refBotaoLimparModoEstado}
                                                            block
                                                            danger
                                                            style={{
                                                                width: 80,
                                                                marginRight: 10
                                                            }}
                                                            onClick={onClickLimparTodosEstadosGov}
                                                            type="primary"
                                                        ><CloseCircleOutlined /></Button>
                                                        <Button
                                                            ref={refBotaoModoEstado}
                                                            block
                                                            onClick={onClickAddTodosEstadosGov}
                                                            type="dashed"
                                                        >Todos</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dadosEstadosGovList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Divider className="dividerPermisaoDoApp" />
                            </>
                            : ""}
                    </Row>
                    <Row>
                        <Col {...rowColNomeAndTipo}>

                            <Row>
                                <Col
                                    hidden={modalObjEstadosList.length === 0}
                                    {...inputSelectTipoDaAssociacao}
                                >
                                    <Button
                                        style={{
                                            marginBottom: 10
                                        }}
                                        // type="dashed"
                                        type="primary"
                                        onClick={() => { setModalVisibleSelectCidadesPorEstado(true) }}
                                    >
                                        Selecionar todas as cidades de um estado
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...inputSelectTipoDaAssociacao}>
                                    <Form.Item
                                        name="cidadesSelect"
                                        // label="Modo Cidade (App)"
                                        label="Municípios"
                                        rules={[
                                            {
                                                required: obrigatorioModoCidadeEstado,
                                                message: "Por favor selecione um município!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={onChangeSelectCidade}
                                            ref={refFormCidadesSelect}
                                            showSearch
                                            // loading={loadingCidadesSelected}
                                            // notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                            placeholder="Selecione..."
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            dropdownRender={menu => (
                                                <div>

                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button block onClick={onClickAddTodasCidadesInTable} type="dashed">Todos</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dadosCidades.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome + " - " + item.estadoSigla}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col {...cardLayout}>
                            <Text>Municípios</Text><Button onClick={onClickRemoverTodasAsCidadesContratoTable} hidden={dadosTabelaState.length != 0 ? false : true} className="botaoRemoverCidadesContratoAll" danger type="primary">Remover Todos</Button>
                            <Table
                                style={{ marginTop: 6 }}
                                className="tabelaAssociacaoAdd"
                                rowKey={(record) => record.uuid}
                                // loading={loadingCidadesSelected}
                                size="small"
                                columns={columns} dataSource={dadosTabelaState}
                                scroll={{ y: 200 }}
                                pagination={{ locale: { items_per_page: '' } }}
                            />
                        </Col>
                        <Divider className="dividerPermisaoDoApp" />
                    </Row>
                    <Row>
                        <Col {...modulosCidadesInPermApp}>
                            <Form.Item
                                name="modulos"
                                label="Módulos"
                                rules={[{
                                    required: true,
                                    message: "Por favor selecione pelomenos um modulo!",
                                },
                                ]}
                            >
                                <Select
                                    showSearch
                                    ref={refSelectListModulosAPP}
                                    optionFilterProp="children"
                                    loading={loadingDosModulos}
                                    notFoundContent={loadingDosModulos ? <Spin size="small" /> : null}
                                    mode="multiple"
                                    // onSelect={onSelectModulosCidades}
                                    // onDeselect={onDesselectModulosCidades}
                                    filterOption={(input: any, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    dropdownRender={menu => (
                                        <div>

                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button
                                                    ref={refBotaoLimparModulosAPP}
                                                    block
                                                    danger
                                                    style={{
                                                        width: 80,
                                                        marginRight: 10
                                                    }}
                                                    onClick={onClickLimparTodosModulosAPP}
                                                    type="primary"
                                                ><CloseCircleOutlined /></Button>
                                                <Button
                                                    ref={refBotaoAddTodosModulosAPP}
                                                    block
                                                    onClick={onClickAddTodosModulosAPP}
                                                    type="dashed"
                                                >Todos</Button>
                                            </div>
                                            <Divider style={{ margin: '4px 0' }} />
                                            {menu}
                                        </div>
                                    )}
                                >
                                    {
                                        dadosModulosCidadesList.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Divider className="dividerPermisaoDoApp" /> */}

                    </Row>
                </Form>
                <Modal
                    title="Selecionar todas as cidades de um estado"
                    visible={modalVisibleSelectCidadesPorEstado}
                    className="modalAddAtendimentos"
                    onOk={() => setModalVisibleSelectCidadesPorEstado(false)}
                    onCancel={() => setModalVisibleSelectCidadesPorEstado(false)}
                    footer={[
                        <Button key="back" onClick={() => setModalVisibleSelectCidadesPorEstado(false)}>
                            Fechar
                        </Button>,
                    ]}
                >
                    <Row>
                        {
                            modalObjEstadosList.map((estados, index) => (
                                <Col
                                    key={estados.estadoId}
                                    {...modalListDeEstados}
                                >
                                    <Button
                                        style={{
                                            margin: 5
                                        }}
                                        type="dashed"
                                        onClick={() => {
                                            setarCidadesNaTabelaPeloIdEstado(estados.estadoId)
                                        }}
                                    >
                                        {estados.estadoNome}
                                    </Button>
                                </Col>
                            ))
                        }

                    </Row>
                </Modal>
            </Spin>
        </>
    );
}

export default PermissoesAplicativo;
