
export const urlHttpGov = typeof process.env?.REACT_APP_API_URL === 'string' ? process.env?.REACT_APP_API_URL : 'http://localhost:3333';

// const urlHttpGov = 'http://localhost:3333';//local

type strOrInt = string | number | null | undefined;
// const urlHttpGov = 'https://api.govfacil.app';//producao
// const urlHttpGov = 'https://api.govfacilbrasil.com.br';//homologacao

//SESSAO AND USER
export const urlLogin = urlHttpGov + '/sessions'

export const urlUsers = urlHttpGov + '/users'
export const urlUsersStatusAtivos = urlHttpGov + '/users/status/ativos'
export const urlUsersStatusInativos = urlHttpGov + '/users/status/inativos'
export const urlUsersStatusTodos = urlHttpGov + '/users/status/todos'
export const urlUsersAutomatico = urlHttpGov + '/users/automatico'
export const urlUsersContratoCidades = urlHttpGov + '/users/contrato/cidades'
export const urlUsersAutomaticoAtendimento = urlHttpGov + '/users/automatico/atendimento'

//URL PRocessos Juridicos
export const urlProcessosJuridicos = urlHttpGov + '/processos_juridicos'
export const urlProcessosJuridicosAtualizar = urlHttpGov + '/processos_juridicos/atualizar'

//url contrato
export const urlContratosAtendimentos = urlHttpGov + '/contratos/atendimentos'
export const urlContratosAutomatico = urlHttpGov + '/contratos/automatico'
export const urlContrato = urlHttpGov + '/contratos'
export const urlContratoUsuarios = urlHttpGov + '/contratos/usuarios'
export const urlContratoUsuariosVinculados = urlHttpGov + '/contratos/usuarios/vinculados'
export const urlContratosUsuarioControlador = urlHttpGov + '/contratos/usuarios/controlador'
export const urlContratoAtivos = urlHttpGov + '/contratos/status/ativos'
export const urlContratoInativos = urlHttpGov + '/contratos/status/inativos'
export const urlContratoVendedores = urlHttpGov + '/contratos/vendedores'
export const urlContratoEstados = urlHttpGov + '/contratos/estados'
// export const urlContratoCidades = urlHttpGov+'/contratos/cidades'
export const urlCidadesContrato = urlHttpGov + '/cidades/contrato'
export const urlContratoCidadesQuantidadeUsuarios = urlHttpGov + '/contratos/cidades/quantidade/usuarios'
export const urlContratosGestorContrato = urlHttpGov + '/contratos/gestor/contrato'
export const urlContratosUsuariosNotificacao = urlHttpGov + '/contratos/usuarios/notificacao'
export const urlContratosGestor = urlHttpGov + '/contratos/gestor'

export const urlContratoAditivo = urlHttpGov + '/contratos/aditivo'
export const urlContratoAditivos = urlHttpGov + '/contratos/aditivos'

//Contrato Planos
export const urlContratosPlanoModulo = urlHttpGov + '/contratos/plano/modulo'
export const urlContratosPlanoRecurso = urlHttpGov + '/contratos/plano/recurso'
export const urlContratosPlano = urlHttpGov + '/contratos/plano'

export const urlModulosContrato = urlHttpGov + '/modulos/contrato'

//URLS Associacoes
export const urlAssocFederal = urlHttpGov + '/associacoes/federal'
export const urlAssocFederalCidades = urlHttpGov + '/associacoes/federal/cidades'
export const urlAssocEstadual = urlHttpGov + '/associacoes/estadual'
export const urlAssocEstadualCidades = urlHttpGov + '/associacoes/estadual/cidades'
export const urlAssocRegional = urlHttpGov + '/associacoes/regional'
export const urlAssocRegionalCidades = urlHttpGov + '/associacoes/regional/cidades'
export const urlAssociacaoAll = urlHttpGov + '/associacoes/find'

export const urlAssocCreate = urlHttpGov + '/associacoes/create'
export const urlAssocUpdate = urlHttpGov + '/associacoes/update'

//CONSULT USER
export const urlBuscaUserByEmail = urlHttpGov + '/users/email/'
export const urlBuscaUserByCelular = urlHttpGov + '/users/celular/'
export const urlUserContratoAutomatico = urlHttpGov + '/users/contrato/automatico';


// PERMISSAO APP
export const urlBuscaModulosApp = urlHttpGov + '/modulos'

// /estados/contrato/:contratoId/usuario/:usuarioId
export const urlBuscaEstadosUsuarioContrato = (idUsuario: strOrInt, idContrato: strOrInt) => urlHttpGov + `/estados/usuario/${idUsuario}/contrato/${idContrato}`
export const urlCidadesUsuarioContrato = (idUsuario: strOrInt, idContrato: strOrInt) => urlHttpGov + `/cidades/usuario/${idUsuario}/contrato/${idContrato}`
export const urlModulosUsuarioContrato = (idUsuario: strOrInt, idContrato: strOrInt) => urlHttpGov + `/modulos/usuario/${idUsuario}/contrato/${idContrato}`

//Notificacao
export const urlBuscaNotificacaoTreeArvore = urlHttpGov + '/modulo_permissao_app/arvore'
export const urlNotificacao = urlHttpGov + '/notificacao'
export const urlBuscaNotificacaoHorario = urlHttpGov + '/notificacao/horario'
export const urlBuscaNotificacaoIntervalo = urlHttpGov + '/notificacao/intervalo'

//CONFIG
export const urlPoder = urlHttpGov + '/config/poder'
export const urlCargo = urlHttpGov + '/config/cargo'
export const urlEsfera = urlHttpGov + '/config/esfera'
export const urlTiposContrato = urlHttpGov + '/config/tipo_contrato'
export const urlSituacaoContrato = urlHttpGov + '/config/situacao_contrato'
export const urlModalidade = urlHttpGov + '/config/modalidade'
export const urlTipoProcessoJuridico = urlHttpGov + '/config/proc_juridico_tipo'

export const urlConfigModulosNotificaIndicesConstitucionais = urlHttpGov + '/modulo_permissao_app/indice_const'
export const urlConfigModulosNotificaIndicesConstitucionaisCidades = urlHttpGov + '/modulo_permissao_app/indice_const/cidades'
export const urlConfigModulosNotificaCertidao = urlHttpGov + '/modulo_permissao_app/certidoes'
export const urlConfigModulosNotificaCertidaoCidades = urlHttpGov + '/modulo_permissao_app/certidoes/cidades'
export const urlConfigModulosNotificaCauc = urlHttpGov + '/modulo_permissao_app/cauc'
export const urlConfigModulosNotificaCaucCidades = urlHttpGov + '/modulo_permissao_app/cauc/cidades'
export const urlConfigModulosNotificaProcessosJuridicos = urlHttpGov + '/modulo_permissao_app/ent_proc_juridicos'
export const urlConfigModulosNotificaOrbigacoes = urlHttpGov + '/modulo_permissao_app/obrigacoes'
export const urlConfigModulosNotificaEntidade = urlHttpGov + '/modulo_permissao_app/entidades'
export const urlConfigModulosNotificaJornais = urlHttpGov + '/modulo_permissao_app/jornais'
export const urlConfigModulosNotificaEstadosByJornais = urlHttpGov + '/modulo_permissao_app/jornais/estados'

export const urlConfigRotasPainelPermissao = urlHttpGov + '/rotas_painel/rotas'
export const urlRotasPainelPermissao = urlHttpGov + '/rotas_painel/permissoes'

export const urlConfigVersaoApp = urlHttpGov + '/config/versao_app'

export const urlTiposAditivos = urlHttpGov + '/config/tipos_aditivo'

export const urlModulo = urlHttpGov + '/config/modulo'
export const urlModuloItem = urlHttpGov + '/config/modulo_item'

//CIDADE ESTADOS
export const urlBuscaEstados = urlHttpGov + '/estados'
export const urlBuscaCidadesPorIdEstado = urlHttpGov + '/cidades/estado/'

export const urlBuscaCidades = urlHttpGov + '/cidades'

//API IBGE
export const todasAsCidades = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/mg/municipios'
export const todasAsCidadesPR = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/pr/municipios'

//App Codes
export const urlsessionCodesSms = urlHttpGov + '/sessionsapp/codes';
export const urlsessionUsuariosCapitados = urlHttpGov + '/sessionsapp/captados';
export const urlsessionUsuarioCapitado = urlHttpGov + '/sessionsapp/captado';

export const urlMensagemUpload = urlHttpGov + '/mensagem/painel/upload';
export const urlMensagem = urlHttpGov + '/mensagem/painel';
export const urlFiles = urlHttpGov + '/files/';

export const urlAtendimentosAtendimento = urlHttpGov + '/atendimento';
export const urlAtendimentoIdContato = (idAtendimento: strOrInt) => urlHttpGov + `/atendimento/${idAtendimento}/contato`;
export const urlAtendimentosAtendimentoVerificaExiste = urlHttpGov + '/atendimento/verifica_existe';
export const urlAtendimentoIdContrato = (idAtendimento: strOrInt) => urlHttpGov + `/atendimento/${idAtendimento}/contrato`;
export const urlAtendimentoIdTelefone = (idAtendimento: strOrInt) => urlHttpGov +`/atendimento/${idAtendimento}/telefone`;

export const urlOutrosAtendimentosAtendimento = urlHttpGov + '/outros_atendimentos/atendimento';
export const urlOutrosAtendimentosContato = urlHttpGov + '/outros_atendimentos/contato';
export const urlOutrosAtendimentosAtendimentoContato = urlHttpGov + '/outros_atendimentos/atendimento/contato';
export const urlOutrosAtendimentosAtendimentoVerificaExiste = urlHttpGov + '/outros_atendimentos/atendimento/verifica_existe';
export const urlOutrosAtendimentosAtendimentoContrato = urlHttpGov + '/outros_atendimentos/atendimento/contrato';
export const urlOutrosAtendimentosAtendimentoTelefone = urlHttpGov + '/outros_atendimentos/atendimento/telefone';


export const urlOutrosAtendimentosProduto = urlHttpGov + '/outros_atendimentos/produto';
export const urlOutrosAtendimentosAtendimentoIDProduto = (idAtendimento: strOrInt) => urlHttpGov + `/outros_atendimentos/atendimento/${idAtendimento}/produto`;



export const urlConfigOrdenacaoModulos = urlHttpGov + '/config/ordenacao_modulos';

export const urlRelatorioDispositivos = urlHttpGov + '/relatorio/dispositivos';
export const urlPesquisa = urlHttpGov + '/pesquisa';
export const urlPesquisaUsuario = urlHttpGov + '/pesquisa/usuario';
export const urlPesquisaResposta = urlHttpGov + '/pesquisa/resposta';
export const urlPesquisaPerguntas = urlHttpGov + '/pesquisa/perguntas';
export const urlPesquisaPergunta = urlHttpGov + '/pesquisa/pergunta';

export const urlContratosEntidade = urlHttpGov + '/contratos/entidade';
export const urlEntidadeLogo = urlHttpGov + '/entidade-logo';
export const urlMembroEntidadeFoto = urlHttpGov + '/membro-entidade-foto';
export const urlNoticiaEntidadeImagem = urlHttpGov + '/noticia-entidade-imagem/';

export const urlRelatorioPendenciasMunicipiosEstado = urlHttpGov + '/relatorio/pendencias/municipios/estado';
export const urlDadosAppIntegracao = urlHttpGov + '/dadosapp/integracao';

export const urlDadosAppIntegracaoMunicipio = urlHttpGov + '/dadosapp/integracao/municipio';
export const urlDadosAppIntegracaoSiconOrgaos = urlHttpGov + '/dadosapp/integracao/sicon/orgaos';

// ?idIbge=2511202&excluirUsuarios=9550,9551
export const urlAcessosContrato = (idContrato: strOrInt) => urlHttpGov + `/dadosapp/acessos/contrato/${idContrato}`
export const urlAcessosUsuario = (idUsuario: strOrInt) => urlHttpGov + `/dadosapp/acessos/usuario/${idUsuario}`
export const urlDadosAcessosContratoUsuarioCidade = (idContrato: strOrInt, idUsuario: strOrInt, idIbge: strOrInt) => urlHttpGov + `/dadosapp/acessos/contrato/${idContrato}/usuario/${idUsuario}/cidade/${idIbge}`

export const urlEntidadeVotacao = (idEntidade: strOrInt) => urlHttpGov + `/app/entidade/${idEntidade}/votacao`
