import React from "react";
import { Button, Col, Form, Modal, Popconfirm, Row, Select, Spin, Table, Typography } from "antd";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { removeAcento } from "../../../../../utils/RemoveAcentos";
import { col12G } from "../Form/GridStyle";
import { IAcessoById } from "../../interface";
import { useRecursosDegustacao } from "./useRecursosDegustacao";

const { Text } = Typography;

export interface IReqRecursos {
    "id": number,
    "uuid": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "path": string,
    "modoEstado": boolean,
    "modoMunicipio": boolean,
    "restritoAlgunsEstados": boolean,
    // "estados": null|não sei o que vem
}

interface PropsTypes {
    funcSetValorRecursosDegustacao: any;
    dataAcessosEdit: IAcessoById | undefined;
}

export const RecursosDegustacao: React.FC<PropsTypes> = (props) => {


    const {
        clickOpenModalFiltros,
        clickRemoveAllRecursosDegustacao,
        dadosTabelaState,
        loadingTable,
        columns,
        openModal,
        clickSubmitModal,
        clickCancelModalFiltros,
        loadingModal,
        isEdit,
        refFormModal,
        loadingModalRecursos,
        reqDadosRecursos,
        selectedDateValidade,
        setSelectedDateValidade,
    } = useRecursosDegustacao(props);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Text>Recursos Degustação</Text>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}
                >
                    <Button
                        onClick={clickOpenModalFiltros}
                        style={{
                            width: 'fit-content',
                            marginRight: 5,
                            marginBottom: 5
                        }}
                        type="primary">
                        Adicionar Recursos
                    </Button>
                    <Popconfirm
                        title="Deseja realmente excluir?"
                        onConfirm={clickRemoveAllRecursosDegustacao}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            onClick={clickRemoveAllRecursosDegustacao}
                            hidden={dadosTabelaState.length != 0 ? false : true}
                            // className="botaoRemoverCidadesContratoAll"
                            style={{
                                width: 'fit-content',
                                marginRight: 5,
                                marginBottom: 5
                            }}
                            danger
                            type="primary">
                            Remover Todos
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <Table
                rowKey={(record) => record.id.toString()}
                loading={loadingTable}
                style={{ marginTop: 6 }}
                size="small"
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ y: 200 }}
                pagination={{ locale: { items_per_page: '' } }}
            />
            <Modal
                title="Adicionar recurso degustação"
                className="modalFiltros"
                open={openModal}
                onOk={clickSubmitModal}
                onCancel={clickCancelModalFiltros}
                destroyOnClose
                footer={[
                    <Button key="back" loading={loadingModal} onClick={clickCancelModalFiltros}>
                        Cancelar
                    </Button>,
                    <Button hidden={isEdit} key="submitAndAdd" loading={loadingModal} type="primary" onClick={() => clickSubmitModal(false)}>
                        Salvar e continuar
                    </Button>,
                    <Button key="submit" loading={loadingModal} type="primary" onClick={clickSubmitModal}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingModal}>
                    <Form
                        name="formModalFiltros"
                        layout="vertical"
                        ref={refFormModal}
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...col12G}>
                                <Form.Item
                                    name="id"
                                    label="Recursos"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, selecione.',
                                        },
                                    ]}
                                    style={{
                                        margin: 5
                                    }}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingModalRecursos}
                                        notFoundContent={loadingModalRecursos ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        optionFilterProp="children"
                                        disabled={isEdit}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            reqDadosRecursos.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.descricao}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...col12G}>
                                <Form.Item
                                    name="validade_degustacao"
                                    label="Validade degustação"
                                    style={{
                                        margin: 5
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor, selecione a data.",
                                        },
                                    ]}
                                >
                                    <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                        <DatePicker
                                            value={selectedDateValidade}
                                            onChange={setSelectedDateValidade}
                                            required={false}
                                            style={{
                                                width: '100%',
                                                marginTop: -1
                                            }}
                                            okLabel="Ok"
                                            clearLabel="Limpar"
                                            cancelLabel="Cancelar"
                                            clearable
                                            format="dd/MM/yyyy"
                                            className="datePickerCOlors"

                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}
