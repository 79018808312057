import { useState, useRef, useCallback, useEffect } from 'react';
import {
    Button,
    notification, Modal,
    message
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import {
    urlBuscaEstados, urlBuscaCidadesPorIdEstado,
    urlAssocFederal, urlAssocFederalCidades, urlAssocEstadual, urlAssocEstadualCidades,
    urlAssocRegionalCidades, urlAssocRegional,
    urlContratosPlano,
    urlContratoAtivos
} from './../../../../../services/request/urls';
import { getToken } from './../../../../../services/token';
import './styles.css';
import moment from 'moment';
import { useGetColumnSearchProps } from '../../../../../hooks/FilterTable/stringCLearFiltros';
import { SairDoSistema } from '../../../../../services/token/SairSistema';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { useHistory, useParams } from 'react-router';
import AcessosService from '../../../../../services/http/services/Acessos/AcessosService';
import { AcessoCidade, IAcessoById } from '../../interface';
import OthersService from '../../../../../services/http/services/Acessos/OthersService';
import { IReqModulos } from '../ModulosDegustacao';

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
};

interface interfaceAssocData {
    id: number;
    nome: string;
    uuid: string;
};

interface interfaceCidadeEstados {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string
};

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    nome: string,
    uuid: string,
    estadoId: number,
    uf: string,
};

interface interfaceNewConfigs {
    nome: string,
    id: number
    uuid: string
}

interface IContratoList {
    nome: string,
    id: number
    uuid: string
    poder?: { nome?: string }
    plano?: { id: number }
    vigencia_atual?: string | null
}

interface IDadosPlanoSelecionadoModal {
    "uuid": string,
    "nome": string,
    // "descricao": string,
    "valor": string,
    "modulos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "path": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "recursos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "createdAt": string,
    "updatedAt": string
}

interface IProps {
    formRef: any;
    funcSetValorTabela: any;
    funcSetValorModulosDegustacao: any;
    funcSetValorRecursosDegustacao: any;
    setLoadingFormAdd?: (val: boolean) => void;
}

export const useForm = (props: IProps) => {

    const history = useHistory();
    const { idParam }: any = useParams();

    const refAllDataFindById = useRef<IAcessoById>();
    const [allDataAcessoFindById, setAllDataAcessoFindById] = useState<IAcessoById>();

    const refFuncaoLimparFiltrosStringCidade = useRef<any>(null);
    const refFuncaoLimparFiltrosStringEstado = useRef<any>(null);

    const modalFiltroAssocFormRef = useRef<any>(null);
    const refFormCidadesSelect = useRef<any>(null);

    const refBotaoModoEstado = useRef<any>(null);
    const refFormModoEstadoSelect = useRef<any>(null);
    const refBotaoEstadoCity = useRef<any>(null);
    const refFormEstadoCitySelect = useRef<any>(null);

    const refDadosCidades = useRef<any>([]);
    const refDadosTotaisCidades = useRef<any>([]);


    const [dadosCidades, setDadosCidades] = useState<Array<interfaceDadosCidades>>([]);
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);

    //Lista de estados antes da cidad
    const [dadosEstadosAndCityList, setDadosEstadosAndCityList] = useState<Array<interfaceDadosEstados>>([]);
    const [loadingEstadosAndCitySelected, setLoadingEstadosAndCitySelected] = useState(false);

    //Associacao listagem modal
    const [dadosSelectAssocList, setDadosSelectAssocList] = useState<Array<interfaceAssocData>>([]);
    const [loadingDadosSelectAssocList, setLoadingDadosSelectAssocList] = useState(false);

    const [loadingSpinModal, setLoadingSpinModal] = useState(false);

    const [isHiddenCidades, setIsHiddenCidades] = useState(false);


    const [loadingTableCidades, setloadingTableCidades] = useState(false);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDadosCidades>>([]);
    const [dadosTotaisCidades, setDadosTotaisCidades] = useState<Array<interfaceDadosCidades>>([]);

    const [estaEditandoUsuario, setEstaEditandoUsuario] = useState(false);

    const [modalFiltrosVisible, setModalFiltrosVisible] = useState(false);

    const [isFiltroAssoc, setIsFiltroAssoc] = useState(false);

    const [loadingGlobal, setLoadingGlobal] = useState(false);
    const [disabledEstadosAssocModal, setDisabledEstadosAssocModal] = useState(false);

    const [selectedDateVigenciaDeAcesso, setSelectedDateVigenciaDeAcesso] = useState<any>(null);

    const refVIEWDadosPlanoSelect = useRef<IDadosPlanoSelecionadoModal>();
    const refDadosPlanoList = useRef<Array<interfaceNewConfigs>>([]);
    const [dadosPlanoList, setDadosPlanoList] = useState<Array<interfaceNewConfigs>>([]);
    const [valuePlano, setValuePlano] = useState<number | string>();
    const [loadingPlanoList, setLoadingPlanoList] = useState(false);
    const [isModalDadosDoPlanoOpen, setIsModalDadosDoPlanoOpen] = useState(false);

    const [loadingContratoList, setLoadingContratoList] = useState(false);
    const [dadosContratoList, setDadosContratoList] = useState<Array<IContratoList>>([]);
    const refDadosContratosList = useRef<Array<IContratoList>>([]);

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());

    //funcao de setar cidades na tabela no select e estado no select quando for para editar contrato
    const buscaCityAndSetTabelaParaEditar = useCallback((arrayidEstado: Array<number> | [], arrayidCidade: Array<AcessoCidade> | []) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                setloadingTableCidades(true);

                //verifica se o arau de estado enviado e diferented e 0
                if (arrayidEstado.length !== 0) {

                    //ja seta os estados no campo estado
                    props.formRef.current.setFieldsValue(
                        {
                            estadosCity: arrayidEstado,
                        }
                    );

                    //variaveis que receber todos as cidades selecionadas
                    let citySelecTable: any = [];
                    //todos cidades nao selecionada dos estados que foram selecionados
                    let cityNaoSelecTable: any = [];

                    //e total das cidades
                    let resultAxiosData: any = [];

                    //percorre o arrai de stado para fazer requisicao
                    for (let index = 0; index < arrayidEstado.length; index++) {
                        const idEstado = arrayidEstado[index];


                        let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                cancelToken: signal.current.token
                            });


                        let cidadesNSelecionadas: any = resultCidade.data;
                        let cidadesSelecionadasTable: any = [];

                        //percorremos o arrai de cidades selecionadas
                        for (let iCidade = 0; iCidade < arrayidCidade.length; iCidade++) {
                            const cidadeObj = arrayidCidade[iCidade];

                            //compara se a cidade que foi selecionada e do estado que fez a busca
                            if (cidadeObj.estadoId === idEstado) {

                                //retorna todas as cidades nao selecionadas desse estado
                                cidadesNSelecionadas = cidadesNSelecionadas.filter((val: any) => {
                                    return val.id !== cidadeObj.cidadeId
                                })

                                //seta todas as cidades que foi selecionada desse estado
                                resultCidade.data.forEach((element: any) => {
                                    if (element.id === cidadeObj.cidadeId) {
                                        cidadesSelecionadasTable.push(element);
                                    }
                                });


                            }
                        }

                        //da push adicionando nas variaveis fora do for para dar setState de uma unica vez
                        cidadesSelecionadasTable.forEach((element: any) => {
                            citySelecTable.push(element);
                        });
                        cidadesNSelecionadas.forEach((element: any) => {
                            cityNaoSelecTable.push(element);
                        });
                        resultCidade.data.forEach((element: any) => {
                            resultAxiosData.push(element);
                        });

                    }

                    //desbloqueia o campo cidade
                    setIsHiddenCidades(true);

                    //setando no select de cidade as cidade que nao estado na tabela
                    refDadosCidades.current = cityNaoSelecTable;
                    setDadosCidades(cityNaoSelecTable);

                    //setando o total de tudo
                    refDadosTotaisCidades.current = resultAxiosData;
                    setDadosTotaisCidades(resultAxiosData);

                    //setando os dados da tabela
                    setDadosTabelaState(citySelecTable);

                    setloadingTableCidades(false)
                    setLoadingCidadesSelected(false);

                } else {
                    setloadingTableCidades(false)
                    setLoadingCidadesSelected(false);

                }


            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    setloadingTableCidades(false)
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosDespesas = useCallback((id: string) => {

        const requestAsync = async () => {
            try {
                setLoadingGlobal(true)

                const acesso = await AcessosService.getAcessosById(id, signal.current.token)

                if (acesso) {

                    setAllDataAcessoFindById(acesso)
                    refAllDataFindById.current = acesso

                    props.formRef.current?.setFieldsValue(
                        {
                            id: typeof acesso?.id === 'number' ? acesso?.id : undefined,
                            nome: acesso?.nome ? acesso?.nome : undefined,
                            planoId: acesso?.planoId ? acesso?.planoId : undefined,
                            contratoId: acesso?.contratoId ? acesso?.contratoId : undefined,
                            contratoAcessoId: acesso?.contratoAcessoId ? acesso?.contratoAcessoId : undefined,
                            teste: acesso?.teste,
                            observacao: acesso.observacao ? acesso.observacao : undefined,
                        }
                    );


                    if (acesso?.planoId) {

                        props.formRef.current?.setFieldsValue(
                            {
                                planoId: acesso?.planoId,
                            }
                        );
                        setValuePlano(acesso?.planoId)

                        onChangePlanoSelect(acesso?.planoId)

                    }

                    if (acesso?.vigencia_acesso) {
                        const data = moment(acesso.vigencia_acesso)
                        setSelectedDateVigenciaDeAcesso(data)
                        props.formRef.current?.setFieldsValue(
                            {
                                vigencia_acesso: data,
                            }
                        );
                    }


                    //para preeencher campo estado e cidade table
                    let estadosID: any = []
                    acesso?.acesso_cidades.forEach((vende) => {
                        if (estadosID.length === 0) {
                            estadosID.push(vende.estadoId)
                        } else {
                            let indice = estadosID.indexOf(vende.estadoId);
                            if (indice === -1) {
                                estadosID.push(vende.estadoId)
                            }
                        }
                    });

                    buscaCityAndSetTabelaParaEditar(estadosID, acesso.acesso_cidades)


                    // Modo estado
                    const arrEstadoSelect = acesso?.acesso_estados.map((estado) => {
                        return estado.estadoId
                    })

                    props.formRef.current?.setFieldsValue(
                        {
                            estados: arrEstadoSelect,
                        }
                    );


                    setLoadingGlobal(false)

                }

            } catch (error) {
                setLoadingGlobal(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    history.push('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        requestAsync();

    }, []);


    useEffect((): void => {

        if (typeof idParam === 'string') {
            if (idParam !== 'adicionar') {
                buscaDadosDespesas(idParam);
            }
        }
    }, [idParam])

    const buscaCityAndSetTabelaParaFiltroAssoc = useCallback((arrayidEstado: any, arrayidCidade: Array<interfaceCidadeEstados> | [], tabelaDados: Array<interfaceDadosCidades>) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {

                //SETtotal cidades
                let totalCIDADE: any = [];

                //verifica se o arau de estado enviado e diferented e 0
                if (arrayidEstado.length !== 0) {

                    //variaveis que receber todos as cidades selecionadas
                    let citySelecTable: any = [];
                    //todos cidades nao selecionada dos estados que foram selecionados
                    let cityNaoSelecTable: any = [];

                    //e total das cidades
                    let resultAxiosData: any = [];

                    //percorre o array de stado para fazer requisicao
                    for (let index = 0; index < arrayidEstado.length; index++) {
                        const idEstado = arrayidEstado[index];


                        let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                cancelToken: signal.current.token
                            });


                        let cidadesNSelecionadas: any = resultCidade.data;
                        let cidadesSelecionadasTable: any = [];

                        //percorremos o arrai de cidades selecionadas
                        for (let iCidade = 0; iCidade < arrayidCidade.length; iCidade++) {
                            const cidadeObj = arrayidCidade[iCidade];

                            //compara se a cidade que foi selecionada e do estado que fez a busca
                            if (cidadeObj.estadoId === idEstado) {

                                //retorna todas as cidades nao selecionadas desse estado
                                cidadesNSelecionadas = cidadesNSelecionadas.filter((val: any) => {
                                    return val.id !== cidadeObj.id
                                })

                                //seta todas as cidades que foi selecionada desse estado
                                resultCidade.data.forEach((element: any) => {
                                    if (element.id === cidadeObj.id) {
                                        cidadesSelecionadasTable.push(element);
                                    }
                                });


                            }
                        }

                        //da push adicionando nas variaveis fora do for para dar setState de uma unica vez
                        cidadesSelecionadasTable.forEach((element: any) => {
                            citySelecTable.push(element);
                        });
                        cidadesNSelecionadas.forEach((element: any) => {
                            cityNaoSelecTable.push(element);
                        });
                        resultCidade.data.forEach((element: any) => {
                            resultAxiosData.push(element);
                        });

                    }

                    //ja seta os estados no campo estado
                    let valorCampoEstado: any = props.formRef.current.getFieldValue('estadosCity');

                    if (valorCampoEstado && valorCampoEstado?.length != 0) {

                        let isEquals = false;
                        let copyTabelaDados: any = [...valorCampoEstado];

                        for (let index = 0; index < valorCampoEstado.length; index++) {
                            const objCampoEstado: number = valorCampoEstado[index];

                            // for (let pos = 0; pos < arrayidEstado.length; pos++) {

                            arrayidEstado.forEach((element: any, posicao: any) => {
                                if (objCampoEstado == element) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyTabelaDados = copyTabelaDados.filter((valores: any) => {
                                    return valores != objCampoEstado
                                })
                                isEquals = false
                            }
                        }

                        copyTabelaDados.forEach((element: any) => {
                            arrayidEstado.push(element);
                        });

                        //seta em campo estado o array de idEstado
                        props.formRef.current.setFieldsValue(
                            {
                                estadosCity: arrayidEstado,
                            }
                        );

                        //para setar cidades totais faz requisicao das cidades e seta
                        for (let index = 0; index < arrayidEstado.length; index++) {
                            const idEstado = arrayidEstado[index];

                            let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + getToken()
                                    },
                                    cancelToken: signal.current.token
                                });

                            resultCidade.data.forEach((element: any) => {
                                totalCIDADE.push(element);
                            });
                        }
                        refDadosTotaisCidades.current = totalCIDADE;
                        setDadosTotaisCidades(totalCIDADE);

                    } else {
                        //seta em campo estado o array de idEstado
                        props.formRef.current.setFieldsValue(
                            {
                                estadosCity: arrayidEstado,
                            }
                        );

                        //para setar cidades totais faz requisicao das cidades e seta
                        for (let index = 0; index < arrayidEstado.length; index++) {
                            const idEstado = arrayidEstado[index];

                            let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + getToken()
                                    },
                                    cancelToken: signal.current.token
                                });

                            resultCidade.data.forEach((element: any) => {
                                totalCIDADE.push(element);
                            });
                        }

                        refDadosTotaisCidades.current = totalCIDADE;
                        setDadosTotaisCidades(totalCIDADE);

                    }

                    //desbloqueia o campo cidade
                    setIsHiddenCidades(true);

                    //percorrer ja selecionados da tabela
                    if (tabelaDados.length != 0) {

                        let isEquals = false;
                        let copyTabelaDados = [...tabelaDados];

                        for (let index = 0; index < tabelaDados.length; index++) {
                            const objDadosTabela = tabelaDados[index];

                            //percorre as ceidades selecionadas
                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objDadosTabela.uuid == element.uuid) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyTabelaDados = copyTabelaDados.filter((valores) => {
                                    return valores.uuid != objDadosTabela.uuid
                                })
                                isEquals = false
                            }

                        }

                        //percorre a copia da tabela removida os iguais e push nas cidades selecionadas
                        copyTabelaDados.forEach((element: any) => {
                            citySelecTable.push(element);
                        });

                        setDadosTabelaState(citySelecTable);

                        //SET CIDADES nao selecionadas no select
                        let isEqualsCity = false;
                        let copyCidadesSelected = [...totalCIDADE];

                        for (let index = 0; index < totalCIDADE.length; index++) {
                            const objTOTALcity = totalCIDADE[index];

                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objTOTALcity.uuid == element.uuid) {
                                    isEqualsCity = true;
                                }
                            });

                            if (isEqualsCity) {
                                copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                    return valores.uuid != objTOTALcity.uuid
                                })
                                isEqualsCity = false
                            }

                        }

                        refDadosCidades.current = copyCidadesSelected;
                        setDadosCidades(copyCidadesSelected);
                    } else {
                        setDadosTabelaState(citySelecTable);

                        //SET CIDADES nao selecionadas no select
                        let isEquals = false;
                        let copyCidadesSelected = [...totalCIDADE];

                        for (let index = 0; index < totalCIDADE.length; index++) {
                            const objTOTALcity = totalCIDADE[index];

                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objTOTALcity.uuid == element.uuid) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                    return valores.uuid != objTOTALcity.uuid
                                })
                                isEquals = false
                            }

                        }

                        refDadosCidades.current = copyCidadesSelected;
                        setDadosCidades(copyCidadesSelected);

                    }

                }

                setLoadingCidadesSelected(false);
                setLoadingSpinModal(false);
                setModalFiltrosVisible(false);

                setDisabledEstadosAssocModal(false);
                modalFiltroAssocFormRef.current.resetFields();

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSpinModal(false);
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    // // const executaAoIniciar
    // useEffect((): void => {

    //     //pega dados do localStorage se existir
    //     let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
    //     let localUserData: any = null;
    //     if (typeof dadosLocalStorage == 'string') {
    //         localUserData = JSON.parse(dadosLocalStorage);
    //     }

    //     if (localUserData) {

    //         setIsHiddenBotaoAddProrrogacao(true);

    //         setEstaEditandoUsuario(true);

    //         if (localUserData?.cidades && localUserData?.cidades.length !== 0) {

    //             let estadosID: any = []
    //             localUserData?.cidades.forEach((vende: any) => {
    //                 if (estadosID.length === 0) {
    //                     estadosID.push(vende.estadoId)
    //                 } else {
    //                     let indice = estadosID.indexOf(vende.estadoId);
    //                     if (indice === -1) {
    //                         estadosID.push(vende.estadoId)
    //                     }
    //                 }
    //             });

    //             buscaCityAndSetTabelaParaEditar(estadosID, localUserData.cidades)

    //         }

    //         if (localUserData?.validade_teste) {
    //             setSelectedDateVigenciaDeAcesso(moment(localUserData?.validade_teste));
    //         }


    //     }

    // }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //remover as requisiçoes quando não terminou
        signal.current.cancel("Requisicao cancelada!")
    }, []);

    const openModalDadosPlano = useCallback(() => {

        const buscaDados = async () => {
            try {
                setLoadingGlobal(true)
                const uuidPlanoSelecionado = props.formRef.current.getFieldValue('planoId');

                const { data } = await axios.get(urlContratosPlano + '/' + uuidPlanoSelecionado,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    }
                );

                refVIEWDadosPlanoSelect.current = data

                setTimeout(() => {
                    setIsModalDadosDoPlanoOpen(true)
                    setLoadingGlobal(false)
                }, 500);
            } catch (error) {
                setLoadingGlobal(false)
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        buscaDados();
    }, [props.formRef])

    const buscarDadosPlanoList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        setLoadingPlanoList(true);
        const requestAsync = async () => {
            try {

                if (setCampo) {
                    props.formRef.current.setFieldsValue({ planoId: nome })
                }

                let result = await axios.get(urlContratosPlano,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosPlanoList.current = result.data;
                setDadosPlanoList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceNewConfigs) => {
                        if (val.id === id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { planoId: nome }
                    )
                }

                setLoadingPlanoList(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        requestAsync();

    }, []);

    const selectContratoPorId = useCallback((id: number): IContratoList | null => {
        let contrato = null;
        refDadosContratosList.current.forEach(val => {
            if (val.id == id) {
                contrato = val
            }
        });
        return contrato;

    }, []);

    const buscarDadosContratoList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        setLoadingContratoList(true);
        const requestAsync = async () => {
            try {

                if (setCampo) {
                    props.formRef.current.setFieldsValue({ contratoId: nome })
                }

                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                setDadosContratoList(result.data)
                refDadosContratosList.current = result.data

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceNewConfigs) => {
                        if (val.id === id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { contratoId: nome }
                    )
                }

                setLoadingContratoList(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingContratoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        requestAsync();

    }, []);

    //setando dados das cidades no selected
    const buscaEstadosAndCityESetSelected = useCallback(() => {
        setLoadingEstadosAndCitySelected(true);
        const buscaEstados = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });
                setDadosEstadosAndCityList(resultCidade.data);
                setLoadingEstadosAndCitySelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingEstadosAndCitySelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        buscaEstados();

    }, []);

    //executa ao inicio e chama todos esses metodos de requisição
    useEffect((): void => {

        buscaEstadosAndCityESetSelected();

        buscarDadosPlanoList();
        buscarDadosContratoList();
    }, []);


    const onClickAddTodosEstadosGov = useCallback(() => {

        if (props.formRef.current.getFieldValue('estados')?.length != 27) {
            let arrayIds: any = [];
            dadosEstadosAndCityList.forEach(val => {
                arrayIds.push(val.id);
            });
            props.formRef.current.setFieldsValue(
                { estados: arrayIds }
            );

            refBotaoModoEstado.current?.blur();
            refFormModoEstadoSelect.current?.blur();
        }
    }, [dadosEstadosAndCityList]);

    //setando dados das cidades no selected
    const buscaCityAndSetSelected = useCallback((idEstado: number, loading = true) => {
        const buscaCiaddes = async () => {
            try {

                if (loading) setLoadingCidadesSelected(true);

                let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });

                if (refDadosTotaisCidades.current.length == 0) {
                    //para poder adicionar no array
                    refDadosCidades.current = resultCidade.data;
                    setDadosCidades(resultCidade.data);

                    refDadosTotaisCidades.current = resultCidade.data;
                    setDadosTotaisCidades(resultCidade.data);

                    if (loading) setLoadingCidadesSelected(false);

                } else {
                    //adicionar no array com outras cidades

                    let arrayCidades: any = [];

                    refDadosCidades.current.map((valor: any) => {
                        arrayCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayCidades.push(valor);
                    });

                    refDadosCidades.current = arrayCidades;
                    setDadosCidades(arrayCidades);

                    let arrayToddasCidades: any = [];

                    refDadosTotaisCidades.current.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });

                    refDadosTotaisCidades.current = arrayToddasCidades;
                    setDadosTotaisCidades(arrayToddasCidades);

                    if (loading) setLoadingCidadesSelected(false);
                }

                return true

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
                return false
            }
        }

        return buscaCiaddes();

    }, []);

    const onClickAddTodosEstadosCidades = useCallback(() => {
        const pegarEstadosESetCidades = async () => {
            try {
                let valInputEstados = props.formRef.current.getFieldValue('estadosCity');

                if (props.formRef.current.getFieldValue('estadosCity')?.length != 27) {

                    //verifica se inputs se ja foi preenchido
                    if (valInputEstados != undefined && valInputEstados.length != 0) {

                        //verificando qual esta selecionado qual nao foi selecionado
                        let array: Array<any> = [];
                        let arrayIdsTodos: any = [];
                        dadosEstadosAndCityList.forEach((dadosEstadoCity) => {
                            arrayIdsTodos.push(dadosEstadoCity.id);
                            let bool = false;
                            valInputEstados.forEach((idInInput: number) => {

                                if (dadosEstadoCity.id == idInInput) {
                                    bool = true
                                }
                            });

                            if (!bool) {
                                array.push(dadosEstadoCity);
                            }
                        });

                        array.forEach(async (estadosFiltro: any, index: number) => {

                            //loading cidades
                            if (index === 0) setLoadingCidadesSelected(true)

                            await buscaCityAndSetSelected(estadosFiltro.id, false);

                            if (array.length == (index + 1)) setLoadingCidadesSelected(false)
                        });


                        props.formRef.current.setFieldsValue(
                            { estadosCity: arrayIdsTodos }
                        );

                    } else {

                        //se nao foi preenchido
                        let arrayIds: any = [];
                        dadosEstadosAndCityList.forEach(async (estadosFiltro: any, index: number) => {
                            //loading cidades
                            if (index === 0) setLoadingCidadesSelected(true)

                            arrayIds.push(estadosFiltro.id);
                            await buscaCityAndSetSelected(estadosFiltro.id, false);

                            if (dadosEstadosAndCityList.length == (index + 1)) setLoadingCidadesSelected(false)
                        });


                        props.formRef.current.setFieldsValue(
                            { estadosCity: arrayIds }
                        );
                        setIsHiddenCidades(true);
                    }

                    refBotaoEstadoCity.current?.blur();
                    refFormEstadoCitySelect.current?.blur();
                }
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        pegarEstadosESetCidades();
    }, [dadosEstadosAndCityList]);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { vigencia_acesso: selectedDateVigenciaDeAcesso, }
        );

    }, [selectedDateVigenciaDeAcesso]);

    // const onChangeDataValidadeDeTeste = useCallback((e: any) => {
    //     const originalValue = unMask(e.target.value);
    //     const maskedValue = masker(originalValue, [
    //         "99/99/9999"
    //     ]);

    //     props.formRef.current.setFieldsValue(
    //         { validade_teste: maskedValue }
    //     );

    // }, []);

    //===============================================================================================================================================
    //===============================================================================================================================================
    //===============================================================================================================================================
    //===============================================================================================================================================
    //===============================================================================================================================================
    //===============================================================================================================================================
    //===============================================================================================================================================

    const onSelectEstadosAndCity = useCallback((e: number) => {
        setIsHiddenCidades(true);
        buscaCityAndSetSelected(e, true);
    }, []);

    const onDeselectEstadosAndCity = useCallback((id: number) => {
        if (dadosTabelaState.length != 0) {

            let filter = dadosTabelaState.filter((val) => {
                return val.estadoId != id
            })

            setDadosTabelaState(filter);

        }
        let filter = dadosCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosCidades.current = filter;
        setDadosCidades(filter);

        let filterTotais = dadosTotaisCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosTotaisCidades.current = filterTotais;
        setDadosTotaisCidades(filterTotais);

        if (props.formRef.current.getFieldValue('estadosCity').length == 0) {
            setIsHiddenCidades(false);
        }

    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades, dadosEstadosAndCityList]);


    const onchangeTipoAssocModal = useCallback((e: any, obj) => {

        const buscaAssocFederal = async () => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocFederal,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        switch (obj.children) {
            case "Regional":
                setDisabledEstadosAssocModal(false);
                setDadosSelectAssocList([]);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
            case "Estadual":
                setDisabledEstadosAssocModal(false);
                setDadosSelectAssocList([]);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
            case "Federal":
                setDisabledEstadosAssocModal(true);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                buscaAssocFederal();
                break;

            default:
                setDisabledEstadosAssocModal(false);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
        }

    }, []);

    const buscaAssociacao = useCallback((tipo: string, idEstado: number) => {

        setLoadingDadosSelectAssocList(true);
        const buscaAssocRegional = async (idEstadosfun: number) => {
            try {
                let result = await axios.get(urlAssocRegional + "/" + idEstadosfun,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocEstadual = async (idEstadosfun: number) => {
            try {
                let result = await axios.get(urlAssocEstadual + "/" + idEstadosfun,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        switch (tipo) {
            case "Regional":
                buscaAssocRegional(idEstado);
                break;
            case "Estadual":
                buscaAssocEstadual(idEstado)
                break;

            default:
                setLoadingDadosSelectAssocList(false);
                break;
        }
    }, []);

    const onChangeFiltroEstadosAssoc = useCallback((e: any, obj) => {
        let valueTipo = modalFiltroAssocFormRef.current.getFieldValue('tipoAssociacao');

        if (valueTipo) {
            buscaAssociacao(valueTipo, obj.value);
            modalFiltroAssocFormRef.current.setFieldsValue(
                {
                    associacoesFiltro: null,
                }
            );
        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio selecionar o campo tipo!',
            });
            modalFiltroAssocFormRef.current.setFieldsValue(
                {
                    estadosAssoc: null,
                    associacoesFiltro: null,
                }
            );
        }

    }, [buscaAssociacao]);

    const onClickAtivarModalFiltros = useCallback((): void => {
        setModalFiltrosVisible(true);
    }, []);

    const clickSubmitModalFiltros = useCallback((): void => {
        setLoadingSpinModal(true);

        const buscaAssocRegional = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocRegionalCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);

                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);

                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocEstadual = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocEstadualCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);

                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false);
                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocFederal = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocFederalCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);
                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);

                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        modalFiltroAssocFormRef?.current?.validateFields()
            .then((values: any) => {

                switch (values.tipoAssociacao) {
                    case "Regional":
                        buscaAssocRegional(values.associacoesFiltro);
                        break;
                    case "Estadual":
                        buscaAssocEstadual(values.associacoesFiltro);
                        break;
                    case "Federal":
                        buscaAssocFederal(values.associacoesFiltro);
                        break;

                    default:
                        break;
                }

            })
            .catch((errorInfo: any) => {
                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dadosTabelaState, buscaCityAndSetTabelaParaFiltroAssoc]);

    const clickCancelModalFiltros = useCallback((): void => {
        setModalFiltrosVisible(false);
    }, []);

    const onClickRemoverTodasAsCidadesContratoTable = useCallback((): void => {

        setDadosTabelaState([]);

        refDadosCidades.current = [];
        setDadosCidades([]);

        refDadosTotaisCidades.current = [];
        setDadosTotaisCidades([]);

        props.formRef.current.setFieldsValue(
            { estadosCity: [] }
        );
        setIsHiddenCidades(false);

    }, [dadosTotaisCidades]);

    //ao selecionar um item das rotas remove do select e add na tabela
    const onChangeSelectCidade = useCallback((e: number): void => {

        if (dadosTabelaState.length == 0) {

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    setDadosTabelaState([item]);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != item.id
                    })
                    refDadosCidades.current = filter
                    setDadosCidades(filter)
                }
            })
        } else {
            // let arrayAddTodos: any = [];

            // dadosTotaisCidades.map((item) => {
            //     if (item.id == e) {
            //         const obj: any = {};

            //         //Alimentado var obj
            //         obj["id"] = item.id
            //         obj["nome"] = item.nome
            //         obj["estadoId"] = item.estadoId
            //         obj["uf"] = item.uf
            //         obj["uuid"] = item.uuid

            //         arrayAddTodos.push(obj);

            //         let filter = dadosCidades.filter((val) => {
            //             return val.id != e
            //         })

            //         refDadosCidades.current = filter
            //         setDadosCidades(filter);
            //     }
            // })

            // dadosTabelaState.forEach((valor: dadosDaTabela | interfaceDadosCidades) => {
            //     arrayAddTodos.push(valor);
            // });

            // setDadosTabelaState(arrayAddTodos);


            let arrayAddTodos: any[] = [...dadosTabelaState];

            const cidadeSelecionada = dadosTotaisCidades.find(item => item.id === e);

            if (cidadeSelecionada) {
                const { id, nome, estadoId, uf, uuid } = cidadeSelecionada;
                arrayAddTodos.push({ id, nome, estadoId, uf, uuid });

                const filtroCidades = dadosCidades.filter(val => val.id !== e);
                refDadosCidades.current = filtroCidades;
                setDadosCidades(filtroCidades);
            }

            setDadosTabelaState(arrayAddTodos);
        }
        props.formRef.current.setFieldsValue({ "cidadesSelect": null });

        refFormCidadesSelect.current?.blur();
    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasCidadesInTable = useCallback(() => {

        if (dadosCidades.length != 0) {

            let arrayAddTodos: any = [];
            let tabelaISVazia = false;

            if (dadosTabelaState.length != 0) {
                tabelaISVazia = true;
            }

            //SALVAR DADOS
            dadosCidades.forEach((valor) => {

                const obj: any = {};

                //Alimentado var obj
                obj["id"] = valor.id
                obj["nome"] = valor.nome
                obj["estadoId"] = valor.estadoId
                obj["uf"] = valor.uf
                obj["uuid"] = valor.uuid

                arrayAddTodos.push(obj);
            });

            //se a tabela não é vazia
            if (tabelaISVazia) {
                dadosTabelaState.forEach((valor: interfaceDadosCidades) => {
                    arrayAddTodos.push(valor);
                });
            }

            setDadosTabelaState(arrayAddTodos);

            refDadosCidades.current = [];
            setDadosCidades([]);

            //Setando null em cidade
            props.formRef.current.setFieldsValue({ "cidadesSelect": null });
        }

    }, [dadosCidades, dadosTabelaState]);


    const excluirDaTabela = useCallback((recorde: any): void => {

        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val != recorde
        })

        setDadosTabelaState(filter);

        //incluirndo o valor removido no select
        let arrayTabela = dadosCidades.concat(recorde);

        refDadosCidades.current = arrayTabela;
        setDadosCidades(arrayTabela);
    }, [dadosTabelaState, dadosCidades]);

    useEffect((): any => {
        props.funcSetValorTabela(dadosTabelaState)
    }, [dadosTabelaState]);

    const columnSearchPropsNome = useGetColumnSearchProps('nome', refFuncaoLimparFiltrosStringCidade);
    const columnSearchPropsEstado = useGetColumnSearchProps('uf', refFuncaoLimparFiltrosStringEstado);

    //tabela luisgaem estado
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...columnSearchPropsNome,
        },
        {
            title: 'Estado',
            dataIndex: 'uf',
            ...columnSearchPropsEstado,
            render: (text: any, record: any) => {
                if (text) {
                    return text
                } else {
                    return record.uf
                }
            }
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            width: '37%',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            if (estaEditandoUsuario) {
                                Modal.confirm({
                                    className: 'classmodalDadosContatoInatendimento',
                                    title: 'Atenção',
                                    icon: <ExclamationCircleOutlined />,
                                    content: `Todos usuários vinculados a está cidade deste contrato \n
                                 irão perder o acesso, deseja realmente excluir?`
                                    ,
                                    okText: 'Sim',
                                    okType: 'danger',
                                    cancelText: 'Cancelar',
                                    onOk() {
                                        excluirDaTabela(record)
                                    },
                                    onCancel() {
                                    },
                                });
                            } else {
                                excluirDaTabela(record)
                            }
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                ) : null,
        },
    ];


    const onChangePlanoSelect = useCallback((value: any): void => {

        setValuePlano(value)

    }, []);

    const onChangeContratoSelect = useCallback((value: number): void => {
        const contrato = selectContratoPorId(value);

        const fieldsToUpdate: any = {};
        let mensagem: string[] = [];

        if (contrato?.plano?.id) {
            fieldsToUpdate.planoId = contrato.plano.id;
            setValuePlano(contrato.plano.id);
            onChangePlanoSelect(contrato.plano.id);
            mensagem.push('plano');
        }

        if (contrato?.vigencia_atual) {
            const data = moment(contrato.vigencia_atual);
            setSelectedDateVigenciaDeAcesso(data);
            fieldsToUpdate.vigencia_atual = data;
            mensagem.push('vigência');
        }

        if (Object.keys(fieldsToUpdate).length > 0) {
            props.formRef.current?.setFieldsValue(fieldsToUpdate);
        }

        if (mensagem.length > 0) {
            const texto = mensagem.join(' e ');
            message.info(`Campo${mensagem.length > 1 ? 's' : ''} de ${texto} preenchido${mensagem.length > 1 ? 's' : ''} automaticamente.`);
        }
    }, []);

    return {
        loadingGlobal,
        loadingPlanoList,
        setValuePlano,
        dadosPlanoList,
        openModalDadosPlano,
        valuePlano,
        loadingContratoList,
        dadosContratoList,
        selectedDateVigenciaDeAcesso,
        setSelectedDateVigenciaDeAcesso,
        refFormModoEstadoSelect,
        loadingEstadosAndCitySelected,
        estaEditandoUsuario,
        refBotaoModoEstado,
        onClickAddTodosEstadosGov,
        dadosEstadosAndCityList,
        isFiltroAssoc,
        onClickAtivarModalFiltros,
        modalFiltrosVisible,
        clickSubmitModalFiltros,
        clickCancelModalFiltros,
        loadingSpinModal,
        modalFiltroAssocFormRef,
        onchangeTipoAssocModal,
        disabledEstadosAssocModal,
        onChangeFiltroEstadosAssoc,
        loadingDadosSelectAssocList,
        dadosSelectAssocList,
        refFormEstadoCitySelect,
        onDeselectEstadosAndCity,
        onSelectEstadosAndCity,
        refBotaoEstadoCity,
        onClickAddTodosEstadosCidades,
        isHiddenCidades,
        onChangeSelectCidade,
        refFormCidadesSelect,
        loadingCidadesSelected,
        onClickAddTodasCidadesInTable,
        dadosCidades,
        onClickRemoverTodasAsCidadesContratoTable,
        dadosTabelaState,
        loadingTableCidades,
        columns,
        allDataAcessoFindById,
        isModalDadosDoPlanoOpen,
        setIsModalDadosDoPlanoOpen,
        refVIEWDadosPlanoSelect,
        onChangePlanoSelect,
        onChangeContratoSelect,
    }
}
