import { useState, useRef, useCallback, useEffect } from 'react';
import {
    notification
} from 'antd';

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import AcessosService from '../../../services/http/services/Acessos/AcessosService';
import moment from 'moment';

interface dadosDaTabelaAssoc {
    id: string;
    nome: string;
    estado: string;
    isAssoc: boolean;
};

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
    isAssoc?: boolean;
};

interface dadosDaTabelaCityMicro {
    id: number;
    nome: string;
    microregiao: Array<any>;
    isAssoc?: boolean;
};


interface IProps {
    // alterOpcaoScreen: (opcao: IOpcaoScreen, id?: string) => void;
}
export const useGerenciar = () => {


    const { idParam }: any = useParams();

    const refForm = useRef<any>(null);
    const history = useHistory();

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [propsDataTableState, setPropsDataTableState] = useState<Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>>([]);

    const [estaEditando, setEstaEditando] = useState(false);

    const refDadosModulosDegustacaoSel = useRef<any>([]);
    const refDadosRecursosDegustacaoSel = useRef<any>([]);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    useEffect((): void => {

        if (typeof idParam === 'string') {
            if (idParam === 'adicionar') {
                setEstaEditando(false)
            } else {
                setEstaEditando(true)
            }
        }

    }, []);

    const handleBtnVoltar = useCallback((): void => {
        history.push("/acessos");
    }, []);

    const onClickBotaoSalvar = useCallback((): void => {

        const salvarDadosModal = async (valores: any) => {
            setLoadingFormAdd(true);
            try {

                let objIDEstados: any = [];

                if (valores.estados && valores.estados.length != 0) {
                    valores.estados.forEach((val: any, i: any) => {
                        objIDEstados.push(val)
                    });
                }
                valores.estadosIbgeIds = objIDEstados

                //PARA PODER FAZER A REQUISIÇAO APENAS COM ID
                let cityEditID: any = []
                valores.cidades.forEach((vende: any) => {
                    cityEditID.push(vende.id)
                });

                valores.municipiosIbgeIds = cityEditID

                //remover itens que nao precisao ser enviados
                delete valores.estados
                delete valores.estadosCity
                delete valores.cidadesSelect
                delete valores.cidades

                //===============================teste
                valores.contratoAcessoId = null

                await AcessosService.postAcesso(valores, signal.token)
                // let resultCidade = await axios.post(urlContrato, valores,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoadingFormAdd(false);

                history.push("/acessos");

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        refForm.current.validateFields()
            .then((values: any) => {

                if (
                    !refForm.current.getFieldValue('estados') ||
                    refForm.current.getFieldValue('estados')?.length === 0
                ) {
                    if (propsDataTableState.length === 0) {
                        notification.error({
                            message: 'Erro',
                            description: 'Selecione uma cidade ou modo estado!',
                        });
                        return;
                    }
                }

                // if (
                //     !refDadosModulosDegustacaoSel.current ||
                //     refDadosModulosDegustacaoSel.current?.length === 0
                // ) {
                //     notification.error({
                //         message: 'Erro',
                //         description: 'Selecione um módulo degustação.',
                //     });
                //     return;
                // }

                // if (
                //     !refDadosRecursosDegustacaoSel.current ||
                //     refDadosRecursosDegustacaoSel.current?.length === 0
                // ) {
                //     notification.error({
                //         message: 'Erro',
                //         description: 'Selecione um recurso degustação.',
                //     });
                //     return;
                // }



                values.cidades = propsDataTableState

                // let vigenciaAcesso = convertDataBrParaUS(values.vigencia_acesso);
                let vigenciaAcesso = moment(values.vigencia_acesso, 'DD/MM/YYYY', true)
                    .startOf('day')
                    .toDate()
                // .format('YYYY-MM-DDTHH:mm:ss');

                values.vigencia_acesso = vigenciaAcesso ? vigenciaAcesso : null;

                values.modulos_degustacao = refDadosModulosDegustacaoSel.current;
                values.recursos_degustacao = refDadosRecursosDegustacaoSel.current;

                salvarDadosModal(values);

            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [propsDataTableState]);

    const onClickBotaoEditar = useCallback((): void => {

        const EditarDados = async (valores: any) => {
            setLoadingFormAdd(true);

            try {

                let objIDEstados: any = [];

                if (valores.estados && valores.estados.length != 0) {
                    valores.estados.forEach((val: any, i: any) => {
                        objIDEstados.push(val)
                    });
                }
                valores.estadosIbgeIds = objIDEstados

                //PARA PODER FAZER A REQUISIÇAO APENAS COM ID
                let cityEditID: any = []
                valores.cidades.forEach((vende: any) => {
                    cityEditID.push(vende.id)
                });

                valores.municipiosIbgeIds = cityEditID

                //remover itens que nao precisao ser enviados
                delete valores.estados
                delete valores.estadosCity
                delete valores.cidadesSelect
                delete valores.cidades

                //===============================teste
                valores.contratoAcessoId = null


                await AcessosService.putAcesso(valores.id, valores, signal.token)

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });
                setLoadingFormAdd(false);

                history.push("/acessos");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        refForm.current.validateFields()
            .then((values: any) => {

                if (
                    !refForm.current.getFieldValue('estados') ||
                    refForm.current.getFieldValue('estados')?.length === 0
                ) {
                    if (propsDataTableState.length === 0) {
                        notification.error({
                            message: 'Erro',
                            description: 'Selecione uma cidade ou modo estado!',
                        });
                        return;
                    }
                }

                // if (
                //     !refDadosModulosDegustacaoSel.current ||
                //     refDadosModulosDegustacaoSel.current?.length === 0
                // ) {
                //     notification.error({
                //         message: 'Erro',
                //         description: 'Selecione um módulo degustação.',
                //     });
                //     return;
                // }

                // if (
                //     !refDadosRecursosDegustacaoSel.current ||
                //     refDadosRecursosDegustacaoSel.current?.length === 0
                // ) {
                //     notification.error({
                //         message: 'Erro',
                //         description: 'Selecione um recurso degustação.',
                //     });
                //     return;
                // }


                values.cidades = propsDataTableState

                let vigenciaAcesso = moment(values.vigencia_acesso, 'DD/MM/YYYY', true)
                    .startOf('day')
                    .toDate()
                // .format('YYYY-MM-DDTHH:mm:ss');


                values.vigencia_acesso = vigenciaAcesso ? vigenciaAcesso : null;

                values.modulos_degustacao = refDadosModulosDegustacaoSel.current;
                values.recursos_degustacao = refDadosRecursosDegustacaoSel.current;

                EditarDados(values)

            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [propsDataTableState]);

    //funcao injetada no formulario para poder pegar o valor da tabela dos itens
    const funcSetValorTabela = useCallback((valor: Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>): void => {
        setPropsDataTableState(valor)
    }, []);

    const funcSetValorModulosDegustacao = useCallback((val: Array<any>) => {
        refDadosModulosDegustacaoSel.current = val
    }, [])

    const funcSetValorRecursosDegustacao = useCallback((val: Array<any>) => {
        refDadosRecursosDegustacaoSel.current = val
    }, [])

    return {
        loadingFormAdd,
        estaEditando,
        handleBtnVoltar,
        refForm,
        funcSetValorTabela,
        funcSetValorModulosDegustacao,
        funcSetValorRecursosDegustacao,
        setLoadingFormAdd,
        onClickBotaoEditar,
        onClickBotaoSalvar,
    }
}
