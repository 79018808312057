import { useCallback, useEffect, useRef, useState } from "react";
import { Button, notification, Popconfirm, Tooltip, Typography } from "antd";
import { useGetColumnSearchProps } from "../../../../../hooks/FilterTable/stringCLearFiltros";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import OthersService from "../../../../../services/http/services/Acessos/OthersService";
import { useGetColumnSearchPropsData } from "../../../../../hooks/FilterTable/date";
import moment from "moment";
import { AcessoRecurso, IAcessoById } from "../../interface";
import { IReqRecursos } from ".";

const { Text } = Typography;

const opcaoTodosRecursos = {
    "id": 0,
    "uuid": "string",
    "createdAt": "string",
    "updatedAt": "string",
    "nome": "Todos",
    "descricao": "Todos",
    "path": "Todos",
    "modoEstado": false,
    "modoMunicipio": false,
    "restritoAlgunsEstados": false,
    // "estados": null|não sei o que vem
}

interface IDadosTabela {
    id: number;
    descricao: string;
    validade_degustacao: string | null;
}


interface IProps {
    funcSetValorRecursosDegustacao: any;
    dataAcessosEdit: IAcessoById | undefined;
}

export const useRecursosDegustacao = (props: IProps) => {


    const refFuncaoLimparFiltrosString = useRef<any>(null);


    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IDadosTabela>>([]);
    const refDadosTabela = useRef<Array<any>>([]);

    const [loadingTable, setloadingTable] = useState(false);
    const [loadingModal, setloadingModal] = useState(false);

    const refFormModal = useRef<any>(null);
    const [openModal, setOpenModal] = useState(false);

    const [loadingModalRecursos, setloadingModalRecursos] = useState(false);
    const [reqDadosRecursos, setReqDadosRecursos] = useState<Array<IReqRecursos>>([]);
    const refAllReqDadosRecursos = useRef<Array<IReqRecursos>>([]);

    const [isEdit, setIsEdit] = useState(false);

    const [selectedDateValidade, setSelectedDateValidade] = useState<any>(null);

    const columnSearchPropsNome = useGetColumnSearchProps('descricao', refFuncaoLimparFiltrosString);
    const columnSearchPropsDataDegustacao = useGetColumnSearchPropsData('vigencia_acesso');

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    const getRecursosDegustacao = useCallback(() => {

        const requestAsync = async () => {
            setloadingModalRecursos(true);
            try {

                let data = await OthersService.getRecursos(signal.token)

                const opcaoTodos: IReqRecursos = opcaoTodosRecursos

                refAllReqDadosRecursos.current = data

                // Adiciona "Todos" apenas se ainda não estiver na lista
                setReqDadosRecursos((prevState) => {
                    const novaLista = [opcaoTodos, ...data]; // Garante que "Todos" sempre esteja na frente
                    return novaLista;
                });

                setloadingModalRecursos(false);

            } catch (error) {
                setloadingModalRecursos(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        requestAsync();

    }, []);


    useEffect((): any => {

        refFormModal.current?.setFieldsValue(
            { validade_degustacao: selectedDateValidade, }
        );

    }, [selectedDateValidade]);

    useEffect((): any => {

        props.funcSetValorRecursosDegustacao(dadosTabelaState)

    }, [dadosTabelaState]);

    useEffect((): any => {

        getRecursosDegustacao();

    }, []);

    const transformEditForTable = useCallback((dataAllModulos: AcessoRecurso[]): IDadosTabela[] => {
        return dataAllModulos.map(item => ({
            id: item.id,
            descricao: item.descricao,
            validade_degustacao: item.validade_degustacao
        }));
    }, []);

    const filtrarRecursosNaoNaTabela = useCallback((
        dadosTabela: IDadosTabela[],
        todosRecursos: IReqRecursos[]
    ): IReqRecursos[] => {
        return todosRecursos.filter(modulo =>
            !dadosTabela.some(dado => dado.id === modulo.id)
        );
    }, []);

    useEffect((): any => {

        if (props.dataAcessosEdit) {
            if (refDadosTabela.current.length === 0) {

                const tabela = transformEditForTable(props.dataAcessosEdit.recursos_degustacao)

                refDadosTabela.current = tabela

                setDadosTabelaState(tabela);

                const recursosForaDaTabela = filtrarRecursosNaoNaTabela(
                    refDadosTabela.current,
                    refAllReqDadosRecursos.current
                );

                const opcaoTodos: IReqRecursos = opcaoTodosRecursos
                setReqDadosRecursos((prevState) => {
                    const novaLista = [opcaoTodos, ...recursosForaDaTabela]; // Garante que "Todos" sempre esteja na frente
                    return novaLista;
                });
            }
        }

    }, [props.dataAcessosEdit]);

    const transformArraySaveAll = useCallback((dataAllRecursos: IReqRecursos[], data: string | null): IDadosTabela[] => {
        return dataAllRecursos.map(item => ({
            id: item.id,
            descricao: item.descricao,
            validade_degustacao: data
        }));
    }, []);

    const selectModuloPorID = useCallback((id): IReqRecursos | null => {
        let modulo = null;
        refAllReqDadosRecursos.current.forEach(val => {
            if (val.id == id) {
                modulo = val
            }
        });
        return modulo;
    }, []);

    const clickSubmitModal = useCallback((saveOnClose = true): void => {

        const requestAsync = async (values: IDadosTabela) => {
            setloadingModal(true);
            try {
                if (values.id === 0) {
                    // Se "Todos" for selecionado, adiciona TODOS os itens da referência no estado
                    const newObj = transformArraySaveAll(refAllReqDadosRecursos.current, values.validade_degustacao);

                    setReqDadosRecursos([opcaoTodosRecursos])
                    setDadosTabelaState(newObj)
                    refDadosTabela.current = newObj
                } else {

                    // // Remove o item do estado `reqDadosRecursos` caso já exista um com o mesmo ID
                    setReqDadosRecursos((prevState) => prevState.filter(item => item.id !== values.id));

                    setDadosTabelaState((prevState) => {
                        const index = prevState.findIndex(item => item.id === values.id);

                        if (index !== -1) {
                            // Se já existe, substitui o item na posição correta
                            const novoEstado = [...prevState];
                            novoEstado[index] = values;
                            return novoEstado;
                        } else {
                            // Se não existe, adiciona ao array
                            return [...prevState, values];
                        }
                    });

                    // Atualiza a ref também
                    const indexRef = refDadosTabela.current.findIndex(item => item.id === values.id);
                    if (indexRef !== -1) {
                        refDadosTabela.current[indexRef] = values;
                    } else {
                        refDadosTabela.current = [...refDadosTabela.current, values];
                    }
                }


                if (saveOnClose) {
                    clickCancelModalFiltros();
                } else {
                    refFormModal.current.setFieldsValue(
                        {
                            id: null,
                            descricao: null,
                        }
                    );
                }

                setloadingModal(false);

            } catch (error) {
                setloadingModal(false);

                setloadingModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        refFormModal?.current?.validateFields()
            .then((values: any) => {
                // let validade = convertDataBrParaUS(values.validade_degustacao);
                let validade = moment(values.validade_degustacao, 'DD/MM/YYYY', true)
                    .startOf('day')
                    .toDate();
                values.validade_degustacao = validade ? validade : null;

                values.descricao = selectModuloPorID(values.id)?.descricao

                requestAsync(values);

            })
            .catch((errorInfo: any) => {
                setloadingModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dadosTabelaState]);

    const clickCancelModalFiltros = useCallback((): void => {

        setSelectedDateValidade(null);
        setOpenModal(false);
        setIsEdit(false);
    }, []);

    const clickOpenModalFiltros = useCallback((): void => {
        setOpenModal(true);
    }, []);

    const clickRemoveOneRecursoDegustacao = useCallback((modulo: IDadosTabela): void => {

        refDadosTabela.current = refDadosTabela.current.filter(item => item.id !== modulo.id);

        setDadosTabelaState((prevState) => prevState.filter(item => item.id !== modulo.id));

        const recursosForaDaTabela = filtrarRecursosNaoNaTabela(
            refDadosTabela.current,
            refAllReqDadosRecursos.current
        );

        const opcaoTodos: IReqRecursos = opcaoTodosRecursos
        setReqDadosRecursos((prevState) => {
            const novaLista = [opcaoTodos, ...recursosForaDaTabela]; // Garante que "Todos" sempre esteja na frente
            return novaLista;
        });

    }, []);

    const clickEDITAROneRecursoDegustacao = useCallback((modulo: IDadosTabela): void => {

        //acrecentar obj no select
        setReqDadosRecursos((prevState) => {
            const index = refAllReqDadosRecursos.current.findIndex(item => item.id === modulo.id);

            if (index !== -1) {
                return [...prevState, refAllReqDadosRecursos.current[index]];
            } else {
                return prevState
            }
        });

        setSelectedDateValidade(moment(modulo.validade_degustacao));
        setIsEdit(true);

        clickOpenModalFiltros();

        setTimeout(() => {
            refFormModal.current?.setFieldsValue(
                {
                    id: modulo.id,
                    descricao: modulo.descricao,
                }
            );
        }, 500);

    }, []);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'descricao',
            ...columnSearchPropsNome,
        },
        {
            title: 'Validade',
            dataIndex: 'validade_degustacao',
            ...columnSearchPropsDataDegustacao,
            // width: '5%',
            showSorterTooltip: false,
            sorter: (a: any, b: any): any => {

                let aMonMen: number = 0
                let bMonMen: number = 0
                if (a?.validade_degustacao) {
                    aMonMen = moment(a.validade_degustacao).unix()
                } else {
                    aMonMen = 0
                }
                if (b?.validade_degustacao) {
                    bMonMen = moment(b.validade_degustacao).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (validade_degustacao: any): any => {
                let data: any = null;
                if (validade_degustacao) {
                    data = moment(validade_degustacao).format('DD/MM/YYYY')
                }
                return (
                    data
                )
            },
        },
        {
            title: 'Ações',
            dataIndex: 'excluir',
            width: '120px',
            render: (text: any, record: any) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Tooltip title="Editar" color="gold" key="editargold">
                        <Button
                            type="primary"
                            style={{
                                marginRight: 5
                            }}
                            className="btn_warning_Acessos_fewuybcoqbfvcqow"
                            onClick={() => clickEDITAROneRecursoDegustacao(record)}
                        >
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                    {
                        dadosTabelaState.length >= 1 ? (
                            <Popconfirm
                                title="Deseja realmente excluir?"
                                onConfirm={() => clickRemoveOneRecursoDegustacao(record)}
                                onCancel={() => { }}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <Tooltip title="Deletar" color="red" key="excluirRed">
                                    <Button
                                        type="primary"
                                        danger
                                    // onClick={() => {
                                    //     if (estaEditandoUsuario) {
                                    //         Modal.confirm({
                                    //             className: 'classmodalDadosContatoInatendimento',
                                    //             title: 'Atenção',
                                    //             icon: <ExclamationCircleOutlined />,
                                    //             content: `Todos usuários vinculados a está cidade deste contrato \n
                                    //          irão perder o acesso, deseja realmente excluir?`
                                    //             ,
                                    //             okText: 'Sim',
                                    //             okType: 'danger',
                                    //             cancelText: 'Cancelar',
                                    //             onOk() {
                                    //                 excluirDaTabela(record)
                                    //             },
                                    //             onCancel() {
                                    //             },
                                    //         });
                                    //     } else {
                                    //         excluirDaTabela(record)
                                    //     }
                                    // }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        ) : null
                    }
                </div>
            )
        },
    ];

    const clickRemoveAllRecursosDegustacao = useCallback((): void => {
        const opcaoTodos: IReqRecursos = opcaoTodosRecursos
        setReqDadosRecursos((prevState) => {
            const novaLista = [opcaoTodos, ...refAllReqDadosRecursos.current]; // Garante que "Todos" sempre esteja na frente
            return novaLista;
        });
        setDadosTabelaState([])
        refDadosTabela.current = []
    }, []);


    return {
        clickOpenModalFiltros,
        clickRemoveAllRecursosDegustacao,
        dadosTabelaState,
        loadingTable,
        columns,
        openModal,
        clickSubmitModal,
        clickCancelModalFiltros,
        loadingModal,
        isEdit,
        refFormModal,
        loadingModalRecursos,
        reqDadosRecursos,
        selectedDateValidade,
        setSelectedDateValidade,
    }
}
