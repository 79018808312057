import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
    Col, Radio, Row, Form, Input, Select, notification, Divider, Tree,
    Table, Button, Space, Typography, Spin
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import {
    receberViaEmailAdd,
    receberViaCelularAdd,
    receberViaAppAdd,
    intervaloNotificacoesUserAddnew,
    horarioNotificacaoAddnew,
    cidadeDosContratoSelect,
    modulosNotificacaoArvoreTree,
    tabelaListagemCidadesSalvas,
    botaoCancelarModulos,
    botaoSalvarModulos,
    espacoModulosEBotoes
} from './GridStyle';
import { useDispatch } from 'react-redux';
import * as UsuariosActions from '../../../../store/modules/usuario/actions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { getToken } from './../../../../services/token';

import {
    urlNotificacao, urlBuscaNotificacaoTreeArvore, urlBuscaNotificacaoHorario,
    urlBuscaNotificacaoIntervalo, urlUsers, urlCidadesContrato,
} from '../../../../services/request/urls';
import { removeAcento } from '../../../../utils/RemoveAcentos';

const { Text } = Typography;

interface InterfaceCidadesMicro {
    id: number;
    nome: string;
    estadoSigla: string;
    microregiao: Array<any>;
};

interface InterfaceDadosTabela {
    cidadeId: number,
    cidade_nome: string | null,
    estadoSigla: string | null,
    selecao: []
}

interface interfaceDadosCidadesBuscaContrato {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string
};

const todaArvoreChecada = [
    "TODOS",
];

interface interfaceDadosIntervalo {
    id: number,
    dia: string,
    uuid: string
}

interface interfaceDadosHorario {
    id: number,
    horario: string,
    uuid: string
}

const Notificacao: React.FC = () => {
    const formRef = useRef<any>(null);

    const refDadosSelectCidadeContrato = useRef<Array<any>>([]);
    const refIdEditrarNotificacao = useRef<any>(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const [value, setValue] = useState("app");
    // const [loadingForm, setloadingForm] = useState(false);
    const [initialValueViaEmailAndCell, setInitialValueViaEmailAndCell] = useState(false);
    const [isObrigatorioCampos, setIsObrigatorioCampos] = useState(false);
    const [disableCidadesDoContrato, setDisableCidadesDoContrato] = useState(false);
    const [isEditando, setIsEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState<InterfaceDadosTabela | any>([]);

    const [loadingIntervalo, setLoadingIntervalo] = useState(false);
    const [listIntervalo, setListIntervalo] = useState<Array<interfaceDadosIntervalo>>([]);
    const [listHorario, setListHorario] = useState<Array<interfaceDadosHorario>>([]);
    const [loadingHorario, setLoadingHorario] = useState(false);

    const [treeArvoreDataState, setTreeArvoreDataState] = useState<any>([]);
    const [loadingArvore, setLoadingArvore] = useState<boolean>(false);

    const [requireCidadesDoContrato, setRequireCidadesDoContrato] = useState<boolean>(false);

    //Select Cidades do contrato
    const [dadosSelectCidadeContrato, setDadosSelectCidadeContrato] = useState<Array<any | InterfaceCidadesMicro>>([]);

    const signal1 = useRef(axios.CancelToken.source());

    //Search da tabela de listagem
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);
    //DADOS DA TABELA
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<any | InterfaceDadosTabela>>([]);

    const [hiddenInViaApp, setHiddenInViaApp] = useState(false);

    // Para receber notificações é necessario selecionar pelomenos uma cidade
    // useEffect(() => {

    //     const buscaCiaddes = async () => {
    //         try {
    //             let resultCidade = await axios.get(todasAsCidadesPR,
    //                 {
    //                     headers: { 'Authorization': 'Bearer ' + getToken() }
    //                 });

    //             const user = localStorage.getItem('@GovFacil:userData');
    //             if (user) {
    //                 let localUserData = JSON.parse(user);

    //                 //se existir os dados no local storage filtarr
    //                 if (localUserData.permissao_notificacao) {

    //                     //remover as cidades ja selecionadas do select
    //                     let VaalforEach = await localUserData.permissao_notificacao.forEach((valorLStorage: InterfaceDadosTabela) => {
    //                         resultCidade.data = resultCidade.data.filter((val: InterfaceCidadesMicro) => {
    //                             return val.id != valorLStorage.cidadeId
    //                         })
    //                     });

    //                     setDadosSelectCidadeContrato(resultCidade.data);
    //                     refDadosSelectCidadeContrato.current = resultCidade.data;
    //                 } else {
    //                     setDadosSelectCidadeContrato(resultCidade.data);
    //                     refDadosSelectCidadeContrato.current = resultCidade.data;
    //                 }
    //             } else {
    //                 setDadosSelectCidadeContrato(resultCidade.data);
    //                 refDadosSelectCidadeContrato.current = resultCidade.data;
    //             }

    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    //     buscaCiaddes();selecao

    // }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal1.current.cancel("Requisicao cancelada!")
    }, []);

    const buscarCidadesContratoSetCampo = useCallback(() => {

        const buscaCiaddes = async () => {
            setLoadingCidadesSelected(true);
            dispatch(UsuariosActions.setSpinnerStep(true));
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
            }
            try {
                let resultCidade = await axios.get(urlCidadesContrato + "/" + localUserData?.step2?.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });

                setDadosSelectCidadeContrato(resultCidade.data);
                refDadosSelectCidadeContrato.current = resultCidade.data;

                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));
                if (msgErro?.message != 'Requisicao cancelada!') {
                    console.error(error);
                }
            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaCidadesDoContratoEReturnData = useCallback((): any => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;

        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscacidadesdoContrato = async () => {
            try {
                let resultCidade = await axios.get(urlCidadesContrato + "/" + localUserData.step2.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });

                return resultCidade.data;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                    return new Promise<[]>((resolve, reject) => {
                        resolve([])
                    });
                }
            }
        }

        if (localUserData?.step2) {

            return buscacidadesdoContrato();

        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio Selecionar contrato!',
            });
            return new Promise<[]>((resolve, reject) => {
                resolve([])
            });
        }

    }, []);

    const buscarDadosEditarByStep4 = useCallback(() => {

        const buscadadosNotificacao = async () => {
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
            }
            setLoadingCidadesSelected(true);
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                //NESSA ROTA AQUI http://192.168.3.8:3333/notificacao/39/6 PRECISAMOS DA SIGLA
                let result = await axios.get(urlNotificacao + "/" + localUserData?.idUser + "/" + localUserData?.step2?.contrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });

                // setabdo valores do banco de dados

                refIdEditrarNotificacao.current = result.data?.id

                let permissaoNotificacao: any = []
                let idHorario: any = [];
                let idIntervalo: any = [];

                if (result.data.length != 0) {
                    result.data?.permissao_notificacao.forEach((val: any, posicao: any): void => {
                        permissaoNotificacao.push(val);
                        permissaoNotificacao[posicao].cidade_nome = val.cidade.nome
                        permissaoNotificacao[posicao].estadoSigla = val?.cidade?.microrregiao?.mesorregiao?.estado?.sigla
                    })

                    result.data?.horario.forEach((valTable: { id: number, nome: string, sigla: string }): void => {
                        idHorario.push(valTable.id)
                    })

                    result.data?.intervalo.forEach((valTable: { id: number, nome: string, sigla: string }): void => {
                        idIntervalo.push(valTable.id)
                    })

                    formRef.current.setFieldsValue(
                        {
                            horario: idHorario,
                            intervalo: idIntervalo,
                            via_celular: result.data?.via_celular,
                            via_email: result.data?.via_email,
                            via_app: result.data?.via_app,
                        }
                    );
                }

                await buscaCidadesDoContratoEReturnData().then((valorPromessa: Array<interfaceDadosCidadesBuscaContrato>) => {

                    let isEqualsCity = false;
                    let copyCidadesSelected = [...valorPromessa];

                    for (let index = 0; index < valorPromessa.length; index++) {
                        const objTOTALcity = valorPromessa[index];

                        permissaoNotificacao.forEach((element: any, posicao: any) => {
                            if (objTOTALcity.id.toString() == element.cidadeId.toString()) {
                                isEqualsCity = true;
                            }
                        });

                        if (isEqualsCity) {
                            copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                return valores.id != objTOTALcity.id
                            })
                            isEqualsCity = false
                        }

                    }

                    setDadosSelectCidadeContrato(copyCidadesSelected);
                    refDadosSelectCidadeContrato.current = valorPromessa;

                    setDadosTabelaState(permissaoNotificacao);

                    localUserData = {
                        ...localUserData,
                        "permissao_notificacao": permissaoNotificacao
                    }
                    localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                }).catch((error: any) => {

                    notification.error({
                        message: 'Erro',
                        description: 'Não foi possivel buscar os dados!',
                    });
                })

                formRef?.current?.validateFields(['horario', 'intervalo']);

                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));

                if (msgErro?.message != 'Requisicao cancelada!') {
                    console.error(error);
                }
            }
        }

        buscadadosNotificacao();

    }, []);

    //Tree arvore
    const [expandedKeys, setExpandedKeys] = useState(["TODOS"]);
    const [checkedKeys, setCheckedKeys] = useState<any>([]);

    //Example exemplo de iniciar state in localstorage
    // const [checkedKeys, setCheckedKeys] = useState<any>(
    //     () => {
    //         const user = localStorage.getItem('@GovFacil:userData');
    //         if (user) {
    //             let localUserData = JSON.parse(user);
    //             if (localUserData.valueTree) {
    //                 return localUserData.valueTree;
    //             }
    //         }
    //         return [];
    //     }
    // );
    const [autoExpandParent, setAutoExpandParent] = useState(true);


    const validaCamposEmailECelularParaSelect = useCallback((): any => {
        let campoForEmail = formRef.current.getFieldValue('via_email')
        let campoFormCelular = formRef.current.getFieldValue('via_celular')
        let campoFormViaApp = formRef.current.getFieldValue('via_app')

        //funcao valida de foi selecionado modo estado ou cidade
        if ((campoForEmail || campoFormCelular || campoFormViaApp) && dadosTabelaState.length === 0) {
            //caso for modo estado setar false pois modo estado nao tem cidades
            if (refDadosSelectCidadeContrato.current.length === 0) {
                setRequireCidadesDoContrato(false);
            } else {
                setRequireCidadesDoContrato(true);
            }

        } else {
            setRequireCidadesDoContrato(false);
        }
    }, [dadosTabelaState]);

    //comonentDidUpdate
    useEffect(() => {
        setTimeout(() => {
            formRef?.current?.validateFields(['cidadesDoContrato']);
        }, 1050);

    }, [requireCidadesDoContrato]);

    //comonentDidUpdate
    useEffect(() => {
        let campoForEmail = formRef.current.getFieldValue('via_email')
        let campoFormCelular = formRef.current.getFieldValue('via_celular')
        let campoFormViaApp = formRef.current.getFieldValue('via_app')

        //funcao valida de foi selecionado modo estado ou cidade
        if ((campoForEmail || campoFormCelular || campoFormViaApp) && dadosTabelaState.length === 0) {

            //caso for modo estado setar false pois modo estado nao tem cidades
            if (refDadosSelectCidadeContrato.current.length === 0) {
                setRequireCidadesDoContrato(false);
            } else {
                setRequireCidadesDoContrato(true);
            }

        } else {
            setRequireCidadesDoContrato(false);
        }

    }, [dadosTabelaState]);

    const onchangeViaEmail = useCallback((eValue): any => {
        let campoFormCelular = formRef.current.getFieldValue('via_celular')
        let campoFormApp = formRef.current.getFieldValue('via_app')

        if ((eValue.target.value || campoFormCelular || campoFormApp) &&
            dadosTabelaState.length === 0) {
            //caso for modo estado setar false pois modo estado nao tem cidades
            if (refDadosSelectCidadeContrato.current.length === 0) {
                setRequireCidadesDoContrato(false);
            } else {
                setRequireCidadesDoContrato(true);
            }
        } else {
            setRequireCidadesDoContrato(false);
        }
    }, [dadosTabelaState]);

    const onchangeViaCelular = useCallback((eValue): any => {
        let campoFormEmail = formRef.current.getFieldValue('via_email')
        let campoFormApp = formRef.current.getFieldValue('via_app')

        if ((eValue.target.value || campoFormEmail || campoFormApp) &&
            dadosTabelaState.length === 0) {
            //caso for modo estado setar false pois modo estado nao tem cidades
            if (refDadosSelectCidadeContrato.current.length === 0) {
                setRequireCidadesDoContrato(false);
            } else {
                setRequireCidadesDoContrato(true);
            }
        } else {
            setRequireCidadesDoContrato(false);
        }
    }, [dadosTabelaState]);

    const onchangeViaApp = useCallback((eValue): any => {
        let campoFormEmail = formRef.current.getFieldValue('via_email')
        let campoFormCelular = formRef.current.getFieldValue('via_celular')

        if ((eValue.target.value || campoFormEmail || campoFormCelular) &&
            dadosTabelaState.length === 0) {
            //caso for modo estado setar false pois modo estado nao tem cidades
            if (refDadosSelectCidadeContrato.current.length === 0) {
                setRequireCidadesDoContrato(false);
            } else {
                setRequireCidadesDoContrato(true);
            }
        } else {
            setRequireCidadesDoContrato(false);
        }
    }, [dadosTabelaState]);

    const buscaHorarioEIntervaloSetInCampo = useCallback((): any => {

        const buscaDeDados = async () => {
            try {
                setLoadingIntervalo(true);
                setLoadingHorario(true);
                let resultIntervalo = await axios.get(urlBuscaNotificacaoIntervalo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setListIntervalo(resultIntervalo.data)

                let resultHorario = await axios.get(urlBuscaNotificacaoHorario,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setListHorario(resultHorario.data)
                setLoadingHorario(false);
                setLoadingIntervalo(false);
            } catch (error) {
                setLoadingIntervalo(false);
                setLoadingHorario(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const comonentDidMount = useEffect((): void => {
        buscaHorarioEIntervaloSetInCampo();
        //scroll sempre no topo
        window.scrollTo(0, 0);

        dispatch(UsuariosActions.formularioRefUsers(formRef));

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //verificacao para regras caso for notificacao
        if (localUserData?.step1) {
            if (localUserData.step1.sistema == 'notificacoes') {

                setHiddenInViaApp(true);

                setIsObrigatorioCampos(true);
                formRef.current.setFieldsValue({
                    via_celular: true,
                    via_email: true,
                    via_app: false
                });
            }
        }

        //autopreencher se estiver editando
        if (localUserData?.step4) {
            formRef.current.setFieldsValue(
                {
                    horario: localUserData?.step4.horario ? localUserData?.step4.horario : undefined,
                    intervalo: localUserData?.step4.intervalo ? localUserData?.step4.intervalo : undefined,
                    via_celular: localUserData?.step4?.via_celular,
                    via_email: localUserData?.step4?.via_email,
                    via_app: localUserData?.step4?.via_app,
                }
            );
        } else {
            if (localUserData?.isEdit) {
                // fazer o editar aqui
                buscarDadosEditarByStep4();
            }
        }

        if (localUserData?.permissao_notificacao) {
            setLoadingCidadesSelected(true);
            dispatch(UsuariosActions.setSpinnerStep(true));
            buscaCidadesDoContratoEReturnData().then((valorPromessa: Array<interfaceDadosCidadesBuscaContrato>) => {

                let isEqualsCity = false;
                let copyCidadesSelected = [...valorPromessa];

                for (let index = 0; index < valorPromessa.length; index++) {
                    const objTOTALcity = valorPromessa[index];

                    localUserData.permissao_notificacao.forEach((element: any, posicao: any) => {
                        if (objTOTALcity.id.toString() == element.cidadeId.toString()) {
                            isEqualsCity = true;
                        }
                    });

                    if (isEqualsCity) {
                        copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                            return valores.id != objTOTALcity.id
                        })
                        isEqualsCity = false
                    }

                }

                setDadosSelectCidadeContrato(copyCidadesSelected);
                refDadosSelectCidadeContrato.current = valorPromessa;

                setDadosTabelaState(localUserData.permissao_notificacao);
                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));
            }).catch((error: any) => {
                setLoadingCidadesSelected(false);
                dispatch(UsuariosActions.setSpinnerStep(false));

                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            })
        } else {
            if (localUserData?.isEdit) {

            } else {
                buscarCidadesContratoSetCampo();
            }
        }

        validaCamposEmailECelularParaSelect();

    }, []);

    //seact da tabela
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);


    // fim do search da tabela de listagem

    const onChange = useCallback((e): void => {
        setValue(e.target.value);
    }, []);

    const formarObjDaManeiraPraEditarNotificacaoEUsuario = useCallback((ObjFormatLsStorage: any): any => {

        let salvar: any = {}
        let copyNotify: any = {}
        let copyUser: any = {}

        copyNotify = {
            "permissao_app": ObjFormatLsStorage?.permissao_app ? ObjFormatLsStorage?.permissao_app : undefined,
            "permissao_notificacao": ObjFormatLsStorage?.notificacao?.permissao_notificacao ? ObjFormatLsStorage.notificacao.permissao_notificacao : undefined,
            "contratoId": ObjFormatLsStorage?.contrato?.id ? ObjFormatLsStorage.contrato.id : undefined
        }

        copyUser = {
            ...ObjFormatLsStorage,
        }
        delete copyUser.permissao_app
        delete copyUser.notificacao.permissao_notificacao

        salvar.usuarioSalvar = copyUser
        salvar.notificacaoSalvar = copyNotify

        return salvar
    }, []);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const formarObjDaManeiraPraSalvar = useCallback((LsStorage: any): any => {

        // ----------- Preparando obj--------------

        try {
            let objHorario: Array<object> = [];
            let objIntervalo: Array<object> = [];
            let objPermissaoNotificacao: Array<object> = [];

            // preparar horario para salvar
            if (LsStorage?.step4?.horario) {
                LsStorage?.step4?.horario.forEach((val: any, i: any) => {
                    objHorario.push({ id: val })
                });

                LsStorage.step4.horario = objHorario
            }

            // preparar intervalo para salvar
            if (LsStorage?.step4?.intervalo) {
                LsStorage?.step4?.intervalo.forEach((val: any, i: any) => {
                    objIntervalo.push({ id: val })
                });

                LsStorage.step4.intervalo = objIntervalo
            }

            if (LsStorage?.permissao_notificacao && LsStorage?.permissao_notificacao.length != 0) {
                LsStorage?.permissao_notificacao.forEach((val: any, i: any) => {
                    val.contratoId = LsStorage?.step2?.contrato;

                    objPermissaoNotificacao.push(val)
                });

                LsStorage.step4.permissao_notificacao = objPermissaoNotificacao

            } else {
                LsStorage.step4.permissao_notificacao = []
            }

            // ------------------------------------------------------------------------------

            // verifica se tem step3

            let step3: any = undefined;
            if (LsStorage?.step3?.salvar) {
                step3 = LsStorage?.step3?.salvar
            }

            let objSalvar: any = {
                ...LsStorage.step1,
                contrato: { "id": LsStorage.step2.contrato },
                permissao_app: step3 ? {
                    ...step3
                } : undefined,
                notificacao: {
                    ...LsStorage.step4
                }
            }

            return objSalvar
        } catch (error) {

        }


    }, []);

    const onFinish = useCallback((formValues): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData?.step4?.via_celular || localUserData?.step4?.via_email || localUserData?.step4?.via_app) {
            if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {



            } else {
                //para apenas se tiver cidades para selecionar
                if (refDadosSelectCidadeContrato.current.length > 0) {
                    notification.error({
                        message: 'Erro',
                        description: "É obrigatorio selecionar uma cidade!",
                    });
                    return
                }
            }
        }

        //navegar para usuarios e salvar
        const salvarDadosModoApp = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                let salvarrnewObj = formarObjDaManeiraPraSalvar(localUserData)
                //Salvando dados do usuario

                let resultDadosUser = await axios.post(
                    urlUsers,
                    salvarrnewObj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIM salvar usuario

                // //salvar permissao APP

                // localUserData.step3.salvar.usuarioId = resultDadosUser?.data?.id

                // await axios.post(
                //     urlBuscaModulosPermissaoApp,
                //     localUserData.step3.salvar,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // //FIM salvar permissao APP

                // //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // let resultNotifi = await axios.post(
                //     urlNotificacao,
                //     localUserData.step4,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIM NOTIFICAÇÃO

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {

                let msgErro: any = (error as Error);

                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }
        const editarModoApp = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                const editarrnewObjmodoappAntigo = formarObjDaManeiraPraSalvar(localUserData)
                const editarrnewObjmodoapp = formarObjDaManeiraPraEditarNotificacaoEUsuario(editarrnewObjmodoappAntigo)

                //Salvando dados do usuario
                let resultDadosUser = await axios.put(
                    urlUsers + "/" + localUserData?.idUser,
                    editarrnewObjmodoapp.usuarioSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario

                //Salvando dados do usuario permisso
                let resultDadosUserPermisso = await axios.put(
                    urlUsers + "/" + localUserData?.idUser + "/permissoes",
                    editarrnewObjmodoapp.notificacaoSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario permisso

                // //salvar permissao APP

                // //VERIFICAR SE Contrato é add ou editado
                // let dadosContarto: any = [];
                // let iscontratoAdicionadoNovo: boolean = false
                // //se existir dados no local storage nao precisa fazer a requisição
                // if (localUserData?.dadosTotaisContratosDoUsuario) {

                //     dadosContarto = localUserData?.dadosTotaisContratosDoUsuario

                //     //para poder setar true na lixeira de excluir o item
                //     dadosContarto.forEach((element: any) => {

                //         if (localUserData?.step2?.contrato == element.id) {

                //             if (!element?.eUsuario) {
                //                 iscontratoAdicionadoNovo = true
                //             }
                //         }
                //     });

                // }

                // //salvar permissao APP
                // localUserData.step3.salvar.usuarioId = resultDadosUser?.data?.id

                // if (iscontratoAdicionadoNovo) {
                //     //para salvar novo contrato ao usuario
                //     await axios.post(
                //         urlBuscaModulosPermissaoApp,
                //         localUserData.step3.salvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });

                // } else {

                //     // para alterar o contrato do usuario

                //     //verificar se ja existe mesmo o editar dos dados
                //     await axios.put(
                //         urlBuscaModulosPermissaoApp,
                //         localUserData.step3.salvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                // //FIM salvar permissao APP

                // //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // if (localUserData.step4?.id) {
                //     let resultNotifi = await axios.put(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // } else {
                //     let resultNotifi = await axios.post(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                // //FIM NOTIFICAÇÃO

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram alterados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {
                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }

        //MODO NOTIFICAÇÃOAAOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
        //navegar para usuarios e salvar
        const salvarDadosModoNotif = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                let salvarnewObjmodonotofi = formarObjDaManeiraPraSalvar(localUserData)

                //Salvando dados do usuario
                let resultDadosUser = await axios.post(
                    urlUsers,
                    salvarnewObjmodonotofi,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIM salvar usuario

                // //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // let resultNotifi = await axios.post(
                //     urlNotificacao,
                //     localUserData.step4,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                // //FIM NOTIFICAÇÃO

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {
                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }
        const editarModoNotifi = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                const editarnewObjmodonotofiAntigo = formarObjDaManeiraPraSalvar(localUserData)
                const editarnewObjmodonotofi = formarObjDaManeiraPraEditarNotificacaoEUsuario(editarnewObjmodonotofiAntigo)

                //Salvando dados do usuario
                let resultDadosUser = await axios.put(
                    urlUsers + "/" + localUserData?.idUser,
                    editarnewObjmodonotofi.usuarioSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario

                //Salvando dados do usuario permisso
                let resultDadosUserPermisso = await axios.put(
                    urlUsers + "/" + localUserData?.idUser + "/permissoes",
                    editarnewObjmodonotofi.notificacaoSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario permisso

                // //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // if (localUserData.step4?.id) {
                //     let resultNotifi = await axios.put(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // } else {
                //     let resultNotifi = await axios.post(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                // //FIM NOTIFICAÇÃO

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram alterados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {
                dispatch(UsuariosActions.setSpinnerStep(false));
                let msgErro: any = (error as Error);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }

        switch (localUserData.step1?.sistema) {
            case 'app':
                if (localUserData?.isEdit === true) {
                    editarModoApp();
                } else {
                    salvarDadosModoApp();
                }

                break;
            case 'notificacoes':
                if (localUserData?.isEdit === true) {
                    editarModoNotifi();
                } else {
                    salvarDadosModoNotif();
                }

                break;

            default:
                break;
        }

    }, []);

    const onDeselectIntervaloNotificacao = useCallback((e, v): void => {
        if (formRef.current.getFieldValue("intervalo")[0] == undefined) {
            formRef.current.setFieldsValue({ intervalo: undefined });
        }
    }, []);

    const onDeselectHorarioNotificacao = useCallback((e, v): void => {
        if (formRef.current.getFieldValue("horario")[0] == undefined) {
            formRef.current.setFieldsValue({ horario: undefined });
        }
    }, []);

    const onCheckTreeArvore = useCallback((checkedKeys: any, info: any) => {
        setCheckedKeys(checkedKeys);
    }, []);

    //Expandir a arvore
    const onExpandTreeArvore = useCallback((expandedKeys: any) => {
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    }, []);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasCidadesInTable = useCallback(() => {

        //pegando Valores do LocalStorage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //validando se os campos horario e intervalo foi preenchido
        if (localUserData?.step4?.horario != undefined) {
            if (localUserData?.step4?.intervalo != undefined) {

                if (dadosSelectCidadeContrato.length != 0) {

                    let arrayAddTodos: any = [];
                    let tabelaISVazia = false;

                    if (dadosTabelaState.length != 0) {
                        tabelaISVazia = true;
                    }

                    //SALVAR DADOS
                    dadosSelectCidadeContrato.forEach((valor: InterfaceCidadesMicro) => {

                        const obj = {
                            "cidadeId": -1,
                            "cidade_nome": '',
                            "estadoSigla": '',
                            "selecao": [""]
                        };

                        //Alimentado var obj
                        obj["cidadeId"] = valor.id
                        obj["cidade_nome"] = valor.nome
                        obj["estadoSigla"] = valor.estadoSigla
                        obj["selecao"] = todaArvoreChecada

                        //verifica se dados a tabela é vazio

                        arrayAddTodos.push(obj);
                    });

                    //se a tabela não é vazia
                    if (tabelaISVazia) {
                        dadosTabelaState.forEach((valor: InterfaceDadosTabela) => {
                            arrayAddTodos.push(valor);
                        });
                    }

                    setDadosTabelaState(arrayAddTodos);

                    localUserData = {
                        ...localUserData,
                        "permissao_notificacao": arrayAddTodos
                    }
                    localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                    setDadosSelectCidadeContrato([]);

                    //Setando null em cidadeContrato
                    formRef.current.setFieldsValue({ "cidadesDoContrato": null });

                    setCheckedKeys([]);

                    setDisableCidadesDoContrato(false);
                }

            } else {
                //deve preencher o intervalo e o horario
                notification.error({
                    message: 'Erro',
                    description:
                        'Por favor, preencha o campo intervalo!',
                });
            }
        } else {
            //deve preencher o intervalo e o horario
            notification.error({
                message: 'Erro',
                description:
                    'Por favor, preencha o campo horário!',
            });
        }
    }, [dadosSelectCidadeContrato, dadosTabelaState]);

    // async function loadNotifications() {
    //     const response = await api.get('notifications')

    //     const data = response.data.map(notification => ({
    //       ...notification,
    //       timeDistance: formatDistance(//calculando a distancia de tempo de agora para notification existente
    //         parseISO(notification.createdAt),
    //         new Date(),
    //         { addSuffix: true, locale: pt }
    //       )
    //     }))

    //     setNotifications(data)
    //   }

    const onFieldsChange = useCallback((changedFields, allFields): void => {
        let obj: any = new Object;
        allFields.forEach((element: any) => {
            let name = element.name[0];
            let value = element.value;
            obj[name] = value;
        });

        obj.id = refIdEditrarNotificacao.current

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
            localUserData = {
                ...localUserData,
                "step4": obj
            }
            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
        } else {
            localStorage.setItem('@GovFacil:userData', JSON.stringify({ "step4": obj }));
        }
    }, []);

    // fim do search da tabela de listagem
    const editarDaTabela = useCallback((recorde: any): void => {
        setLoadingArvore(true);
        setCheckedKeys([]);

        //Setando null em cidadeContrato
        formRef.current.setFieldsValue({ "cidadesDoContrato": recorde.cidade_nome + " - " + recorde.estadoSigla });
        setDisableCidadesDoContrato(true);
        setIsEditando(true);
        setDadosEditando(recorde);


        const buscaArvore = async () => {

            try {
                let resultCidade = await axios.get(urlBuscaNotificacaoTreeArvore + "/" + recorde.cidadeId,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });


                setTreeArvoreDataState(resultCidade.data);
                setCheckedKeys(recorde.selecao);
                setExpandedKeys(["TODOS"]);
                setLoadingArvore(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingArvore(false);
                    console.error(error);
                }
            }
        }

        buscaArvore();
    }, [dadosTabelaState]);

    // fim do search da tabela de listagem
    const excluirDaTabela = useCallback((recorde: any): void => {

        //pegando Valores do LocalStorage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val != recorde
        })
        setDadosTabelaState(filter);

        localUserData = {
            ...localUserData,
            "permissao_notificacao": filter
        }
        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

        const obj = {
            "id": recorde.cidadeId,
            "nome": recorde.cidade_nome,
            "estadoSigla": recorde.estadoSigla,
            "microrregiao": {}
        };

        //incluirndo o valor removido no select
        let arrayTabela = dadosSelectCidadeContrato.concat(obj);
        setDadosSelectCidadeContrato(arrayTabela);

        //Setando null em cidadeContrato
        formRef.current.setFieldsValue({ "cidadesDoContrato": null });

        setCheckedKeys([]);

        setDisableCidadesDoContrato(false);

        setIsEditando(false);
        setDadosEditando([]);

    }, [dadosTabelaState, dadosSelectCidadeContrato]);
    // }, [dadosTabelaState, dadosTabelaSelected, dadosTabelaSelectedAssociacao]);

    const selectNomeCidadePorId = useCallback((id): string | null => {
        let nomeCidade = null;

        refDadosSelectCidadeContrato.current.forEach(val => {
            if (val.id == id) {
                nomeCidade = val.nome
            }
        });
        return nomeCidade;

    }, []);

    const selectSiglaCidadePorId = useCallback((id): string | null => {
        let estadoSigla = null;

        refDadosSelectCidadeContrato.current.forEach(val => {
            if (val.id == id) {
                estadoSigla = val.estadoSigla
            }
        });
        return estadoSigla;

    }, []);

    const onClickCancelarCidadesContrato = useCallback((): void => {
        //Setando null em cidadeContrato
        formRef.current.setFieldsValue({ "cidadesDoContrato": null });

        setCheckedKeys([]);

        setDisableCidadesDoContrato(false);

        setIsEditando(false);
        setDadosEditando([]);

        setExpandedKeys([]);
    }, []);

    const onSelectCidadesDoContrato = useCallback((values, array): void => {
        setCheckedKeys([]);
        const buscaArvore = async () => {

            try {
                setLoadingArvore(true);
                let resultCidade = await axios.get(urlBuscaNotificacaoTreeArvore + "/" + values,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.current.token
                    });

                setTreeArvoreDataState(resultCidade.data);
                setExpandedKeys(['TODOS'])
                setLoadingArvore(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingArvore(false);
                    console.error(error);
                }
            }
        }

        buscaArvore();
    }, []);

    const onClickRemoverTodasAsCidadesContratoTable = useCallback((): void => {

        //pegando Valores do LocalStorage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        setDadosTabelaState([]);

        localUserData = {
            ...localUserData,
            "permissao_notificacao": []
        }
        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

        //Setando null em cidadeContrato
        formRef.current.setFieldsValue({ "cidadesDoContrato": null });

        setCheckedKeys([]);
        setExpandedKeys([])

        setDisableCidadesDoContrato(false);

        setIsEditando(false);
        setDadosEditando([]);

        //setando todas as cidades no campo
        buscarCidadesContratoSetCampo();
    }, []);

    // Click botao salvar tabela
    const onClickSetTableModulos = useCallback((): void => {

        //pegando Valores do LocalStorage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //validando se os campos horario e intervalo foi preenchido
        if (localUserData?.step4?.horario != undefined) {
            if (localUserData?.step4?.intervalo != undefined) {

                //verifica se esta editando
                if (!isEditando) {
                    //SALVAR DADOS

                    const valorCidadesContrato = formRef.current.getFieldValue('cidadesDoContrato');
                    const nomeCidade = selectNomeCidadePorId(valorCidadesContrato);
                    const siglaCidade = selectSiglaCidadePorId(valorCidadesContrato);

                    const obj: InterfaceDadosTabela = {
                        "cidadeId": -1,
                        "cidade_nome": '',
                        "estadoSigla": '',
                        "selecao": []
                    };

                    //verificando que cidade contrato foi selecionado
                    if (valorCidadesContrato) {

                        //checando se modulo foi chekado
                        if (checkedKeys.length != 0) {

                            //Alimentado var obj
                            obj["cidadeId"] = valorCidadesContrato
                            obj["cidade_nome"] = nomeCidade
                            obj["estadoSigla"] = siglaCidade
                            obj["selecao"] = checkedKeys

                            //verifica se dados a tabela é vazio
                            if (dadosTabelaState.length == 0) {
                                setDadosTabelaState([obj])

                                localUserData = {
                                    ...localUserData,
                                    "permissao_notificacao": [obj]
                                }
                                localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                            } else {

                                //para poder adicionar na primeira posição do array
                                let arrayPrimeiro: any = [];

                                arrayPrimeiro.push(obj);

                                dadosTabelaState.forEach((valor: InterfaceDadosTabela) => {
                                    arrayPrimeiro.push(valor);
                                });

                                //concatena array porem fk na ultima posição
                                // let arrayTabela = dadosTabelaState.concat(obj);
                                setDadosTabelaState(arrayPrimeiro);

                                localUserData = {
                                    ...localUserData,
                                    "permissao_notificacao": arrayPrimeiro
                                }
                                localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
                            }

                            //remove cidades contrato do array
                            let filter = dadosSelectCidadeContrato.filter((val) => {
                                return val.id != valorCidadesContrato
                            });
                            setDadosSelectCidadeContrato(filter);

                            //Setando null em cidadeContrato
                            formRef.current.setFieldsValue({ "cidadesDoContrato": null });

                            setCheckedKeys([]);

                            setDisableCidadesDoContrato(false);

                        } else {
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Por favor, selecione o modulo desejado!',
                            });
                        }
                    } else {
                        notification.error({
                            message: 'Erro',
                            description:
                                'Por favor, selecione a cidade desejada!',
                        });
                    }
                } else {
                    //EDITAR DADOS

                    //checando se modulo foi chekado
                    if (checkedKeys.length != 0) {
                        let indice = dadosTabelaState.indexOf(dadosEditando);

                        //se item foi encontrado dentro do array
                        if (indice != -1) {
                            //pegando Valores do LocalStorage
                            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
                            let localUserData: any = null;
                            if (typeof dadosLocalStorage == 'string') {
                                localUserData = JSON.parse(dadosLocalStorage);
                            }

                            dadosEditando.selecao = checkedKeys

                            dadosTabelaState[indice] = dadosEditando

                            setDadosTabelaState(dadosTabelaState);

                            localUserData = {
                                ...localUserData,
                                "permissao_notificacao": dadosTabelaState
                            }
                            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));

                            //Setando null em cidadeContrato
                            formRef.current.setFieldsValue({ "cidadesDoContrato": null });

                            setCheckedKeys([]);

                            setDisableCidadesDoContrato(false);

                            setIsEditando(false);
                            setDadosEditando([]);

                        } else {
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Objeto não encontrato!',
                            });
                        }
                    } else {
                        notification.error({
                            message: 'Erro',
                            description:
                                'Por favor, selecione o modulo desejado!',
                        });
                    }

                }

                //setando array vazio na arvore
                setExpandedKeys([]);

            } else {
                //deve preencher o intervalo e o horario
                notification.error({
                    message: 'Erro',
                    description:
                        'Por favor, preencha o campo intervalo!',
                });
            }
        } else {
            //deve preencher o intervalo e o horario
            notification.error({
                message: 'Erro',
                description:
                    'Por favor, preencha o campo horário!',
            });
        }

    }, [checkedKeys, dadosTabelaState, dadosSelectCidadeContrato, isEditando, dadosEditando]);

    //COLUNAS DA TABELA
    const columns = [
        {
            title: 'Nome Cidade',
            dataIndex: 'cidade_nome',
            width: '67%',
            ...getColumnSearchProps('cidade_nome'),
            render: (cidade: any, record: any) => (
                <>
                    <Text>
                        {cidade + ' - ' + record?.estadoSigla}
                    </Text>
                </>
            ),
        },
        {
            title: 'Editar',
            dataIndex: 'editar',
            width: '33%',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <>
                        <Button className="EditarCidadesContratoSalvas" type="primary" onClick={() => editarDaTabela(record)}>
                            <EditOutlined />
                        </Button>
                    </>
                ) : null,
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            width: '37%',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <>
                        <Button type="primary" danger onClick={() => excluirDaTabela(record)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {/* <Spin spinning={loadingForm}> */}
            <Form
                ref={formRef}
                name="notificacao"
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFieldsChange={onFieldsChange}
                onFinish={onFinish}
                onFinishFailed={() => { }}
            >
                <Row>
                    <Col {...receberViaEmailAdd} >
                        <Form.Item
                            name="via_email"
                            label="Receber Via Email"
                            rules={[
                            ]}
                            initialValue={initialValueViaEmailAndCell}
                        >
                            <Radio.Group onChange={onchangeViaEmail}>
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col {...receberViaCelularAdd} >
                        <Form.Item
                            name="via_celular"
                            label="Receber WhatsApp"
                            rules={[
                            ]}
                            initialValue={initialValueViaEmailAndCell}
                        >
                            <Radio.Group onChange={onchangeViaCelular}>
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col
                        {...receberViaAppAdd}
                        hidden={hiddenInViaApp}
                    >
                        <Form.Item
                            name="via_app"
                            label="Receber no App"
                            rules={[
                            ]}
                            initialValue={true}
                        >
                            <Radio.Group onChange={onchangeViaApp}>
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col {...intervaloNotificacoesUserAddnew}>
                        <Form.Item
                            name="intervalo"
                            label="Intervalo"
                            rules={[
                                {
                                    required: isObrigatorioCampos,
                                    message: 'Por favor selecione no min 1!',
                                },
                            ]}
                            initialValue={[2]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onDeselect={onDeselectIntervaloNotificacao}
                                loading={loadingIntervalo}
                                notFoundContent={loadingIntervalo ? <Spin size="small" /> : null}
                                mode="multiple"
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    listIntervalo.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.dia}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...horarioNotificacaoAddnew}>
                        <Form.Item
                            name="horario"
                            label="Horário"
                            rules={[
                                {
                                    required: isObrigatorioCampos,
                                    message: 'Por favor selecione no min 1!',
                                },
                            ]}
                            initialValue={[5]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                mode="multiple"
                                onDeselect={onDeselectHorarioNotificacao}
                                loading={loadingHorario}
                                notFoundContent={loadingHorario ? <Spin size="small" /> : null}
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    listHorario.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.horario}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider className="dividerPermisaoDoApp" />
                <Row>
                    <Col {...espacoModulosEBotoes}>
                        <Row>
                            <Col {...cidadeDosContratoSelect}>
                                <Form.Item
                                    name="cidadesDoContrato"
                                    label="Cidades do Contrato"
                                    rules={[
                                        {
                                            required: requireCidadesDoContrato,
                                            message: 'Por favor selecione no min 1!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        disabled={
                                            disableCidadesDoContrato
                                            || dadosTabelaState.length >= 10
                                        }
                                        loading={loadingCidadesSelected}
                                        notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        // dropdownRender={menu => (
                                        //     <div>

                                        //         <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        //             <Button block onClick={onClickAddTodasCidadesInTable} type="dashed">Todos</Button>
                                        //         </div>
                                        //         <Divider style={{ margin: '4px 0' }} />
                                        //         {menu}
                                        //     </div>
                                        // )}
                                        onSelect={onSelectCidadesDoContrato}
                                    >
                                        {
                                            dadosSelectCidadeContrato.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome + " - " + item.estadoSigla}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...modulosNotificacaoArvoreTree}>
                                {
                                    treeArvoreDataState.length == 0 ? "" :

                                        <Spin spinning={loadingArvore}>
                                            <Form.Item
                                                name="modulos_notificacao"
                                                label="Modulos"
                                            >
                                                <Tree
                                                    checkable
                                                    selectable={false}
                                                    expandedKeys={expandedKeys}
                                                    onExpand={onExpandTreeArvore}
                                                    autoExpandParent={autoExpandParent}
                                                    onCheck={onCheckTreeArvore}
                                                    checkedKeys={checkedKeys}
                                                    treeData={treeArvoreDataState}
                                                />
                                            </Form.Item>
                                        </Spin>
                                }
                            </Col>
                            <Col {...botaoCancelarModulos}>
                                <Button disabled={!isEditando} onClick={onClickCancelarCidadesContrato} style={{ marginBottom: 10, marginRight: 15 }}>Cancelar</Button>

                                <Button onClick={onClickSetTableModulos} style={{ marginBottom: 10 }} type="primary">Inserir</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...tabelaListagemCidadesSalvas}>
                        <Text>Cidades Salvas</Text><Button onClick={onClickRemoverTodasAsCidadesContratoTable} hidden={dadosTabelaState.length != 0 ? false : true} className="botaoRemoverCidadesContratoAll" danger type="primary">Remover Todos</Button>
                        <Table
                            style={{ marginTop: 4 }}
                            className="tabelaAssociacaoAdd"
                            rowKey={(record) => record.cidadeId.toString()}
                            loading={loadingCidadesSelected}
                            size="small"
                            columns={columns} dataSource={dadosTabelaState}
                            scroll={{ y: 200 }}
                            pagination={{ locale: { items_per_page: '' } }}
                        />
                    </Col>
                </Row>
            </Form>
            {/* </Spin> */}
        </>
    );
}

export default Notificacao;
