import React from 'react';
import {
    Button, Form, Typography, Spin, Row, Col, Breadcrumb, Divider
} from 'antd';
import {
    SolutionOutlined
} from '@ant-design/icons';
import { FormAcessos } from './components/Form';
import { useGerenciar } from './useGerenciar';

const { Title } = Typography;

export const GerenciarAcessos: React.FC = () => {

    const {
        loadingFormAdd,
        estaEditando,
        handleBtnVoltar,
        refForm,
        funcSetValorTabela,
        funcSetValorModulosDegustacao,
        funcSetValorRecursosDegustacao,
        setLoadingFormAdd,
        onClickBotaoEditar,
        onClickBotaoSalvar,
    } = useGerenciar();

    return (
        <>
            <Spin spinning={loadingFormAdd}>
                <Row>
                    <Col span={24}>
                        <Title level={3}><SolutionOutlined style={{ fontSize: 35, marginRight: 10 }} /> {estaEditando ? "Editar" : "Adicionar"} acessos</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item><a onClick={handleBtnVoltar}>Acessos</a></Breadcrumb.Item>
                            <Breadcrumb.Item>{estaEditando ? "Editar" : "Adicionar"} acessos</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={refForm}
                    name="formAcesso"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                >

                    <FormAcessos
                        formRef={refForm}
                        funcSetValorTabela={funcSetValorTabela}
                        funcSetValorModulosDegustacao={funcSetValorModulosDegustacao}
                        funcSetValorRecursosDegustacao={funcSetValorRecursosDegustacao}
                        setLoadingFormAdd={setLoadingFormAdd}
                    />
                    <Form.Item>
                        <Row>
                            <Divider />
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={handleBtnVoltar}
                                loading={loadingFormAdd}
                            >
                                Cancelar
                            </Button>
                            {estaEditando ?
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onClickBotaoEditar}
                                    loading={loadingFormAdd}
                                >
                                    Atualizar
                                </Button>
                                :
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onClickBotaoSalvar}
                                    loading={loadingFormAdd}
                                >
                                    Salvar
                                </Button>
                            }
                        </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    );
}
