import axios, { CancelToken } from 'axios';
import { getToken } from "../../../token";
import { urlHttpGov } from "../../../request/urls";
import { IAcessosList } from '../../../../pages/Acessos/interface';
import { IAcessoById } from '../../../../pages/Acessos/Gerenciar/interface';

class AcessosService {

    async getAcessos(signal?: CancelToken): Promise<Array<IAcessosList>> {

        const { data } = await axios.get(`${urlHttpGov}/acesso`, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            cancelToken: signal
        });

        return data;
    }

    async getAcessosById(id: number | string, signal?: CancelToken): Promise<IAcessoById> {

        const { data } = await axios.get(`${urlHttpGov}/acesso/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            cancelToken: signal
        });

        return data;
    }

    async postAcesso(obj: any, signal?: CancelToken) {

        const { data } = await axios.post(`${urlHttpGov}/acesso`,
            obj,
            {
                headers: {
                    'Authorization': 'Bearer ' + getToken()
                },
                cancelToken: signal
            });

        return data
    }

    async putAcesso(id: string | number, obj: any, signal?: CancelToken) {

        await axios.put(`${urlHttpGov}/acesso/${id}`,
            obj,
            {
                headers: {
                    'Authorization': 'Bearer ' + getToken()
                },
                cancelToken: signal
            });

        return
    }

    // async deleteCliente(id: string | number, signal?: CancelToken) {

    //     await axios.delete(`${urlHttpGov}/cliente/${id}`,
    //         {
    //             headers: {
    //                 'Authorization': 'Bearer ' + getToken()
    //             },
    //             cancelToken: signal
    //         });

    //     return
    // }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AcessosService();
