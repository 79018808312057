import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Input, Space, Table, Modal, Spin, Form,
    Typography, Tag, notification, Popconfirm, Radio, Divider,
    Select,
    RadioChangeEvent
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    modaisDeConfig,
    colLisTabelaConfigs,
    modalAddModuloInputs
} from './GridStyle';

import { FaCogs, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import axios from 'axios';
import { urlBuscaCidades, urlBuscaEstados, urlContratosPlanoModulo } from '../../../../../services/request/urls';

import { getToken } from '../../../../../services/token';
import { convertMoedaBRParaUS, convertMoedaUSParaBR } from '../../../../../services/Conversores';
import { removeAcento } from '../../../../../utils/RemoveAcentos';
import { SairDoSistema } from '../../../../../services/token/SairSistema';
import { EmitirErro } from '../../../../../services/EmitirErro';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const opcaoTodosEstado = {
    "id": 0,
    "uuid": 'string',
    "sigla": '',
    "nome": 'Todos',
    "populacao": 0,
    "regiao": {
        "id": 0,
        "uuid": '',
        "sigla": '',
        "nome": 'Todos'
    }
}

const opcaoTodosCidades = {
    "id": 0,
    "uuid": 'string',
    "ibge_id_antigo": 0,
    "nome": 'Todos',
    "uf": '',
    "latitude": 'string',
    "longitude": 'string',
    "populacao": 0,
    "microrregiao": {
        "id": 0,
        "uuid": 'string',
        "nome": 'Todos',
        "mesorregiao": {
            "id": 0,
            "uuid": 'string',
            "nome": 'Todos',
            "estado": {
                "id": 0,
                "uuid": 'string',
                "sigla": 'string',
                "nome": 'string',
                "populacao": 0
            }
        }
    },
    "estadoId": 0
}


interface IReqEstados {
    "id": number,
    "uuid": string,
    "sigla": string,
    "nome": string,
    "populacao": number,
    "regiao": {
        "id": number,
        "uuid": string,
        "sigla": string,
        "nome": string
    }
}

interface IReqCidades {
    "id": number,
    "uuid": string,
    "ibge_id_antigo": number,
    "nome": string,
    "uf": string,
    "latitude": string,
    "longitude": string,
    "populacao": number,
    "microrregiao": {
        "id": number,
        "uuid": string,
        "nome": string,
        "mesorregiao": {
            "id": number,
            "uuid": string,
            "nome": string,
            "estado": {
                "id": number,
                "uuid": string,
                "sigla": string,
                "nome": string,
                "populacao": number
            }
        }
    }
    "estadoId": number
}

const ListModulosPlano: React.FC = () => {

    const formRef = useRef<any>(null);

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);

    const [ativarOuInativarModulo, setAtivarOuInativarModulo] = useState(false);

    const refAllEstados = useRef<Array<IReqEstados>>([]);
    const [dadosEstadosSelect, setDadosEstadosSelect] = useState<Array<IReqEstados>>([]);
    const [loadingEstadosAndSetSelected, setLoadingEstadosAndSetSelected] = useState(false);

    const refAllCidades = useRef<Array<IReqCidades>>([]);
    const [dadosCidadesSelect, setDadosCidadesSelect] = useState<Array<IReqCidades>>([]);
    const [loadingCidadesAndSetSelected, setLoadingCidadesAndSetSelected] = useState(false);

    const [valuelRadioRestrito, setValuelRadioRestrito] = useState(false);


    //State de editar usuario
    const [initialValueEditId, setInitialValueEditId] = useState<undefined | number>(undefined);

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());

    //setando dados das cidades no selected
    const buscaEstadosAndSetSelected = useCallback(() => {
        const requestAsync = async () => {
            try {
                setLoadingEstadosAndSetSelected(true);

                let { data } = await axios.get(urlBuscaEstados,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });


                // const opcaoTodos: IReqEstados = opcaoTodosEstado

                // refAllEstados.current = data

                // // Adiciona "Todos" apenas se ainda não estiver na lista
                // setDadosEstadosSelect((prevState) => {
                //     const novaLista = [opcaoTodos, ...data]; // Garante que "Todos" sempre esteja na frente
                //     return novaLista;
                // });

                setDadosEstadosSelect(data)

                setLoadingEstadosAndSetSelected(false);
            } catch (error) {
                setLoadingEstadosAndSetSelected(false);
                let msgErro: any = (error as Error);
                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    history.push('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }
            }
        }

        requestAsync();

    }, []);

    //setando dados das cidades no selected
    const buscaCidadesAndSetSelected = useCallback(() => {
        const requestAsync = async () => {
            try {
                setLoadingCidadesAndSetSelected(true);

                let { data } = await axios.get(urlBuscaCidades,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });


                // const opcaoTodos: IReqCidades = opcaoTodosCidades

                // refAllCidades.current = data

                // // Adiciona "Todos" apenas se ainda não estiver na lista
                // setDadosCidadesSelect((prevState) => {
                //     const novaLista = [opcaoTodos, ...data]; // Garante que "Todos" sempre esteja na frente
                //     return novaLista;
                // });

                setDadosCidadesSelect(data)

                setLoadingCidadesAndSetSelected(false);
            } catch (error) {
                setLoadingCidadesAndSetSelected(false);
                let msgErro: any = (error as Error);
                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    history.push('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }
            }
        }

        requestAsync();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosPlanoModulo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();
        buscaEstadosAndSetSelected();
        buscaCidadesAndSetSelected();

    }, []);

    //executa apenas quando salvar
    const salvarModalRecarregaTable = useCallback(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosPlanoModulo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, []);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBoolean = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'sim'
                } else {
                    nome = 'não'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    const getColumnSearchPropsBooleanAtivo = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let nome = '';
                if (record[dataIndex]) {
                    nome = 'ativo'
                } else {
                    nome = 'inativo'
                }
                return (
                    nome.toString().toLowerCase().includes(value.toLowerCase())
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleReset, handleSearch]);

    // fim do search da tabela de listagem

    const clickBotaoDeletarDados = useCallback((): void => {
        const buscaDeDados = () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let obj: Array<any> = [];
                let resultforeach = dadosTabelaState.forEach(({ id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (id == val) {
                            obj.push({ id })
                        }
                    });

                })

                console.log(obj)
                const excluirDadosModal = async () => {
                    try {
                        let resultCidade = await axios.delete(urlContratosPlanoModulo + '/' + obj[0].id, {
                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                            data: obj[0],
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        })
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram excluídos com sucesso!',
                        });
                        setLoadingFormAdd(false);
                        salvarModalRecarregaTable();
                        setInitialValueEditId(undefined);
                    } catch (error) {

                        let msgErro: any = (error as Error);
                        console.log(msgErro)

                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel deletar, entre em contato com suporte!',
                        });
                    }
                }
                excluirDadosModal();
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState, formRef]);

    // const onChangeModalInputOrdem = useCallback((e: any) => {
    //     const originalValue = e.target.value;//+''
    //     const regex = /^[0-9]*$/

    //     if (regex.test(originalValue)) {
    //         setValorCampoModalInputOrdem(originalValue)
    //         formRef.current.setFieldsValue(
    //             { ordem: originalValue }
    //         );
    //     } else {
    //         formRef.current.setFieldsValue(
    //             { ordem: valorCampoModalInputOrdem }
    //         );
    //     }
    // }, [valorCampoModalInputOrdem]);

    const clickCancelModalAddConfig = useCallback(() => {
        setModalAddConfigVisible(false);
        formRef.current.resetFields();
    }, []);

    const clickSubmitModalAddConfig = useCallback((): void => {
        formRef.current?.validateFields()
            .then((values: any) => {
                setLoadingFormAdd(true);

                // values.valor = convertMoedaBRParaUS(values?.valor)

                //verifica se ele esta editando ou é um novo
                if (initialValueEditId) {
                    values.id = initialValueEditId;

                    const editarDadosModal = async () => {
                        try {
                            await axios.put(urlContratosPlanoModulo + '/' + initialValueEditId, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                            setInitialValueEditId(undefined);
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {
                    const salvarDadosModal = async () => {
                        try {
                            await axios.post(urlContratosPlanoModulo, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    salvarDadosModal();
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([])

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [initialValueEditId, clickCancelModalAddConfig, salvarModalRecarregaTable]);

    //abrir modal
    const clickBotaoAddEsfera = useCallback((): void => {
        setInitialValueEditId(undefined);
        setModalAddConfigVisible(true);
        setValuelRadioRestrito(false)
    }, []);

    //Editar dados
    const clickBotaoEditarEsfera = useCallback((): void => {

        const buscaDeDados = () => {

            if (selectedRowKeys.length === 1) {
                let obj: Array<any> = [];
                dadosTabelaState.forEach((objEditar: any): void => {
                    if (objEditar.id === selectedRowKeys[0]) {
                        obj.push(objEditar)
                    }
                })

                if (formRef.current) {
                    formRef.current.setFieldsValue(
                        {
                            nome: obj[0].nome,
                            descricao: obj[0].descricao,
                            path: obj[0].path,
                            // status: obj[0].status,
                            // valor: obj[0].valor,
                            modoEstado: obj[0].modoEstado,
                            modoMunicipio: obj[0].modoMunicipio,
                            restrito: obj[0].restrito,
                            estados: obj[0].estados,
                            cidades: obj[0].cidades,

                        }
                    );
                    setValuelRadioRestrito(obj[0].restrito)
                } else {
                    setTimeout(() => {
                        formRef.current?.setFieldsValue(
                            {
                                nome: obj[0].nome,
                                descricao: obj[0].descricao,
                                path: obj[0].path,
                                // status: obj[0].status,
                                // valor: obj[0].valor,
                                modoEstado: obj[0].modoEstado,
                                modoMunicipio: obj[0].modoMunicipio,
                                restrito: obj[0].restrito,
                                estados: obj[0].estados,
                                cidades: obj[0].cidades,
                            }
                        );
                        setValuelRadioRestrito(obj[0].restrito)
                    }, 100);
                }

                setInitialValueEditId(obj[0].id)
                setModalAddConfigVisible(true);

            }
        }

        buscaDeDados();

        setModalAddConfigVisible(true);
    }, [selectedRowKeys, dadosTabelaState, formRef]);

    //executa ao iniciar e did update para atualizar o botao de ativo ou inativo
    useEffect(() => {
        const buscaDeDados = async () => {
            try {
                if (selectedRowKeys.length === 1) {
                    let obj: Array<any> = [];
                    dadosTabelaState.forEach((objEditar: any): void => {
                        if (objEditar.id === selectedRowKeys[0]) {
                            obj.push(objEditar)
                        }
                    })

                    setAtivarOuInativarModulo(obj[0].status)

                }


            } catch (error) {

            }
        }

        buscaDeDados();

    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        //verifica se nome esta no array
        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val !== record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length === 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((rowKeys): void => {
        if (rowKeys.length > 1) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else if (rowKeys.length === 0) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(true);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys, setSelectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '5%'
    };

    const columns = [
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            ...getColumnSearchProps('descricao'),
            width: '20%',
        },
        // {
        //     title: 'Valor',
        //     dataIndex: 'valor',
        //     ...getColumnSearchProps('valor'),
        //     width: '20%',
        //     render: (val: any) => {
        //         return (
        //             convertMoedaUSParaBR(val)
        //         )
        //     },
        // },
        {
            title: 'Modo Estado',
            dataIndex: 'modoEstado',
            ...getColumnSearchPropsBoolean('modoEstado'),
            width: '10%',
            render: (val: any) => {
                let cor = '';
                let nome = '';
                switch (val) {
                    case true:
                        cor = 'green';
                        nome = 'SIM';
                        break;
                    case false:
                        cor = 'blue';
                        nome = 'NÃO';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {nome}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Modo Município',
            dataIndex: 'modoMunicipio',
            ...getColumnSearchPropsBoolean('modoMunicipio'),
            width: '10%',
            render: (val: any) => {
                let cor = '';
                let nome = '';
                switch (val) {
                    case true:
                        cor = 'green';
                        nome = 'SIM';
                        break;
                    case false:
                        cor = 'blue';
                        nome = 'NÃO';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {nome}
                        </Tag>
                    </span>
                )
            },
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     ...getColumnSearchPropsBooleanAtivo('status'),
        //     width: '5%',
        //     render: (val: any) => {
        //         let cor = '';
        //         let nome = '';
        //         switch (val) {
        //             case true:
        //                 cor = 'green';
        //                 nome = 'ATIVO';
        //                 break;
        //             case false:
        //                 cor = 'red';
        //                 nome = 'INATIVO';
        //                 break;

        //             default:
        //                 cor = ''
        //                 break;
        //         }
        //         return (
        //             <span>
        //                 <Tag color={cor} key={val?.id}>
        //                     {nome}
        //                 </Tag>
        //             </span>
        //         )
        //     },
        // },
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, [history]);

    // const onChangeMoney = useCallback((e: any) => {

    //     formRef.current.setFieldsValue(
    //         { valor: convertMoedaUSParaBR(e.target.value) }
    //     );

    // }, []);

    const onChangeRadioRestrito = useCallback((val: RadioChangeEvent) => {
        setValuelRadioRestrito(val.target.value)

        if (!val.target.value) {
            formRef.current?.setFieldsValue(
                {
                    estados: [],
                    cidades: [],
                }
            );
        }

    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a href='#' onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Módulos</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddEsfera} >
                        Adicionar
                    </Button>

                </Col>
                <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarEsfera} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                    <Popconfirm
                        title="Deseja excluir?"
                        onConfirm={clickBotaoDeletarDados}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button className="botaoDeleteAssociacao" type="primary" danger
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Row>
                <Col {...colLisTabelaConfigs}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Modal
                title="Adicionar Módulos"
                open={modalAddConfigVisible}
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                destroyOnClose
                width={800}
                style={{
                    top: 40
                }}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRef}
                        name="formModalAddAssociacao"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha o nome do módulo!",
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a descrição!",
                                        }
                                    ]}
                                    name="descricao"
                                    label="Descrição"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Descrição" />
                                </Form.Item>
                            </Col>

                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Preencha a Path!",
                                        }
                                    ]}
                                    name="path"
                                    label="Path"
                                    style={{ margin: 5 }}
                                >
                                    <Input placeholder="Path" />
                                </Form.Item>
                            </Col>

                            {/* <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    name="valor"
                                    label="Valor (R$)"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor digite a valor",
                                        },
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Input
                                        onChange={(e) => onChangeMoney(e)}
                                        placeholder="0,00"
                                        maxLength={21}
                                    />
                                </Form.Item>
                            </Col> */}

                            {/* <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={true}
                                    name="status"
                                    label="Status"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}

                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={false}
                                    name="modoEstado"
                                    label="Modo Estado"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={false}
                                    name="modoMunicipio"
                                    label="Modo Município"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs}>
                                <Form.Item
                                    initialValue={false}
                                    name="restrito"
                                    label="Restrito"
                                    rules={[
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Radio.Group onChange={onChangeRadioRestrito}>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs} hidden={!valuelRadioRestrito}>
                                <Form.Item
                                    name="estados"
                                    label="Estados"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor, selecione um estado.',
                                        },
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Select
                                        // ref={refFormModoEstadoSelect}
                                        loading={loadingEstadosAndSetSelected}
                                        notFoundContent={loadingEstadosAndSetSelected ? <Spin size="small" /> : null}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    // dropdownRender={menu => (
                                    //     <div>

                                    //         <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    //             <Button
                                    //                 ref={refBotaoModoEstado}
                                    //                 block
                                    //                 onClick={onClickAddTodosEstadosGov}
                                    //                 type="dashed"
                                    //             >Todos</Button>
                                    //         </div>
                                    //         <Divider style={{ margin: '4px 0' }} />
                                    //         {menu}
                                    //     </div>
                                    // )}
                                    >
                                        {
                                            dadosEstadosSelect.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...modalAddModuloInputs} hidden={!valuelRadioRestrito}>
                                <Form.Item
                                    name="cidades"
                                    label="Municípios"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor, selecione um município.',
                                        },
                                    ]}
                                    style={{ margin: 5 }}
                                >
                                    <Select
                                        // ref={refFormModoEstadoSelect}
                                        loading={loadingCidadesAndSetSelected}
                                        notFoundContent={loadingCidadesAndSetSelected ? <Spin size="small" /> : null}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    // dropdownRender={menu => (
                                    //     <div>

                                    //         <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    //             <Button
                                    //                 ref={refBotaoModoEstado}
                                    //                 block
                                    //                 onClick={onClickAddTodosEstadosGov}
                                    //                 type="dashed"
                                    //             >Todos</Button>
                                    //         </div>
                                    //         <Divider style={{ margin: '4px 0' }} />
                                    //         {menu}
                                    //     </div>
                                    // )}
                                    >
                                        {
                                            dadosCidadesSelect.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default ListModulosPlano;
