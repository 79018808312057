import React, { } from 'react';
import {
    Button, Input, Form, Select, Radio, Table, Tag, Typography, Row, Col, Divider, Spin,
    Modal,
    Tooltip
} from 'antd';
import Icon, { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import {
    col8GPers,
    cardLayout,
    inputSelectEstadosCity,
    inputSelectCidades,
    rowColNomeAndTipo,
    inputSelectTipoDaAssociacao,
    tipoAssocModal,
    estadosAssocModal,
    cidadesAssocModal,
    col12GTable
} from './GridStyle';
import './styles.css';
import {
    convertMoedaUSParaBR
} from './../../../../../services/Conversores';
import { removeAcento } from '../../../../../utils/RemoveAcentos';
import { FaSearch } from 'react-icons/fa';
import { ModulosDegustacao } from '../ModulosDegustacao';
import { RecursosDegustacao } from '../RecursosDegustacao';
import { useForm } from './useForm';

const { Text } = Typography;

interface PropsTypes {
    formRef: any;
    funcSetValorTabela: any;
    funcSetValorModulosDegustacao: any;
    funcSetValorRecursosDegustacao: any;
    setLoadingFormAdd?: (val: boolean) => void;
}

export const FormAcessos: React.FC<PropsTypes> = (props) => {

    const {
        loadingGlobal,
        loadingPlanoList,
        setValuePlano,
        dadosPlanoList,
        openModalDadosPlano,
        valuePlano,
        loadingContratoList,
        dadosContratoList,
        selectedDateVigenciaDeAcesso,
        setSelectedDateVigenciaDeAcesso,
        refFormModoEstadoSelect,
        loadingEstadosAndCitySelected,
        estaEditandoUsuario,
        refBotaoModoEstado,
        onClickAddTodosEstadosGov,
        dadosEstadosAndCityList,
        isFiltroAssoc,
        onClickAtivarModalFiltros,
        modalFiltrosVisible,
        clickSubmitModalFiltros,
        clickCancelModalFiltros,
        loadingSpinModal,
        modalFiltroAssocFormRef,
        onchangeTipoAssocModal,
        disabledEstadosAssocModal,
        onChangeFiltroEstadosAssoc,
        loadingDadosSelectAssocList,
        dadosSelectAssocList,
        refFormEstadoCitySelect,
        onDeselectEstadosAndCity,
        onSelectEstadosAndCity,
        refBotaoEstadoCity,
        onClickAddTodosEstadosCidades,
        isHiddenCidades,
        onChangeSelectCidade,
        refFormCidadesSelect,
        loadingCidadesSelected,
        onClickAddTodasCidadesInTable,
        dadosCidades,
        onClickRemoverTodasAsCidadesContratoTable,
        dadosTabelaState,
        loadingTableCidades,
        columns,
        allDataAcessoFindById,
        isModalDadosDoPlanoOpen,
        setIsModalDadosDoPlanoOpen,
        refVIEWDadosPlanoSelect,
        onChangePlanoSelect,
        onChangeContratoSelect,
    } = useForm(props);

    return (
        <>
            <Spin spinning={loadingGlobal}>
                <Row>

                    <Col {...col8GPers} hidden>
                        <Form.Item
                            name="id"
                            label="ID"
                            style={{
                                margin: 5
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Por favor, digite o nome do acesso.',
                                },
                            ]}
                        >
                            <Input placeholder="Nome" />
                        </Form.Item>
                    </Col>
                    <Col {...col8GPers}>
                        <Form.Item
                            name="nome"
                            label="Nome do acesso"
                            style={{
                                margin: 5
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, digite o nome do acesso.',
                                },
                            ]}
                        >
                            <Input placeholder="Nome" />
                        </Form.Item>
                    </Col>

                    <Col {...col8GPers}>
                        <Form.Item
                            name="contratoId"
                            label="Selecione o Contrato"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, selecione um contrato.",
                                }
                            ]}
                            style={{
                                flex: 1,
                                marginTop: 5,
                                marginBottom: 5,
                                marginLeft: 5,
                            }}
                        >
                            <Select
                                showSearch
                                loading={loadingContratoList}
                                notFoundContent={loadingContratoList ? <Spin size="small" /> : null}
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                                onChange={onChangeContratoSelect}
                            >
                                {
                                    dadosContratoList.map((item) => {
                                        return (

                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {
                                                    item?.poder?.nome ?
                                                        item.nome + ' - ' + item?.poder?.nome
                                                        :
                                                        item.nome
                                                }
                                            </Select.Option>

                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col {...col8GPers}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end',
                                width: '100%',
                                justifyContent: 'end',

                            }}
                        >
                            <Form.Item
                                name="planoId"
                                label="Selecione o Plano"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, selecione um plano.",
                                    }
                                ]}
                                style={{
                                    flex: 1,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    marginLeft: 5,
                                }}
                            >
                                <Select
                                    showSearch
                                    loading={loadingPlanoList}
                                    notFoundContent={loadingPlanoList ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                    onChange={onChangePlanoSelect}
                                >
                                    {
                                        dadosPlanoList.map((item) => {
                                            return (

                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >

                                                    {item.nome}

                                                </Select.Option>

                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Tooltip title="Ver Descrição" color="blue" key="verdescricaoPlano">
                                <Button
                                    onClick={() => {
                                        openModalDadosPlano()
                                    }}
                                    // className="botaoRemoverCidadesContratoAll"
                                    // danger
                                    size='middle'
                                    type="primary"
                                    style={{
                                        marginBottom: 5,
                                        marginRight: 5,
                                    }}
                                    disabled={!(typeof valuePlano === 'number')}
                                >
                                    <SearchOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    </Col>



                    <Col {...col8GPers} hidden>
                        <Form.Item
                            name="contratoAcessoId"
                            label="Contrato acesso"

                            style={{
                                margin: 5
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Por favor, digite o contrato.',
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col {...col8GPers}>
                        <Form.Item
                            name="vigencia_acesso"
                            label="Vigência de acesso"

                            style={{
                                margin: 5
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, selecione a data.",
                                },
                            ]}
                        >
                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                <DatePicker
                                    value={selectedDateVigenciaDeAcesso}
                                    onChange={setSelectedDateVigenciaDeAcesso}
                                    required={false}
                                    style={{
                                        width: '100%',
                                        marginTop: -1
                                    }}
                                    okLabel="Ok"
                                    clearLabel="Limpar"
                                    cancelLabel="Cancelar"
                                    clearable
                                    format="dd/MM/yyyy"
                                    className="datePickerCOlors"

                                />
                            </MuiPickersUtilsProvider>
                        </Form.Item>
                    </Col>

                    <Col {...col8GPers}>
                        <Form.Item
                            name="teste"
                            label="Teste"
                            style={{
                                margin: 5
                            }}
                            initialValue={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, selecione.',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Divider className="dividerPermisaoDoApp" />
                    <Row>
                        <Col {...rowColNomeAndTipo}>
                            <Row>
                                <Col {...inputSelectTipoDaAssociacao}>
                                    <Form.Item
                                        name="estados"
                                        label="Modo Estado (App)"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Por favor, selecione um estado.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            ref={refFormModoEstadoSelect}
                                            loading={loadingEstadosAndCitySelected}
                                            notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            onDeselect={(id: any, obj: any) => {
                                                if (estaEditandoUsuario) {
                                                    Modal.confirm({
                                                        className: 'classmodalDadosContatoInatendimento',
                                                        title: 'Atenção',
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: `Todos usuários vinculados a este estado deste contrato
                                                 irão perder o acesso, deseja realmente excluir?`
                                                        ,
                                                        okText: 'Sim',
                                                        okType: 'danger',
                                                        cancelText: 'Cancelar',
                                                        onOk() {
                                                            // ja exclui altumatico
                                                        },
                                                        onCancel() {
                                                            let todos = [...props.formRef.current.getFieldValue('estados')]

                                                            todos?.push(id);

                                                            props.formRef.current.setFieldsValue(
                                                                {
                                                                    estados: todos,
                                                                }
                                                            );

                                                        },
                                                    });
                                                }
                                            }}
                                            mode="multiple"
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            dropdownRender={menu => (
                                                <div>

                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button
                                                            ref={refBotaoModoEstado}
                                                            block
                                                            onClick={onClickAddTodosEstadosGov}
                                                            type="dashed"
                                                        >Todos</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dadosEstadosAndCityList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {isFiltroAssoc ?
                                    <Col {...inputSelectTipoDaAssociacao}>
                                        <Button
                                            onClick={onClickAtivarModalFiltros}
                                            type="primary"
                                            className="botaoFiltrosAssoc"
                                            style={{ marginBottom: 10 }}
                                        >
                                            Seleção de Cidades por Associação
                                        </Button>
                                    </Col>
                                    : ""}
                            </Row>
                            <Modal
                                title="Seleção de Cidades por Associação"
                                className="modalFiltros"
                                open={modalFiltrosVisible}
                                onOk={clickSubmitModalFiltros}
                                onCancel={clickCancelModalFiltros}
                                footer={[
                                    <Button key="back" loading={loadingSpinModal} onClick={clickCancelModalFiltros}>
                                        Cancelar
                                    </Button>,
                                    <Button key="submit" loading={loadingSpinModal} type="primary" onClick={clickSubmitModalFiltros}>
                                        Inserir
                                    </Button>,
                                ]}
                            >
                                <Spin spinning={loadingSpinModal}>
                                    <Form
                                        name="formModalFiltros"
                                        layout="vertical"
                                        ref={modalFiltroAssocFormRef}
                                        initialValues={{
                                            remember: true,
                                        }}
                                    >
                                        <Row>
                                            <Col {...tipoAssocModal}>
                                                <Form.Item
                                                    name="tipoAssociacao"
                                                    label="Tipo"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Por favor, selecione o tipo associação.',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        onChange={onchangeTipoAssocModal}
                                                        placeholder="Selecione..."
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                    >
                                                        <Select.Option
                                                            value="Regional"
                                                            key="1"
                                                        >
                                                            Regional
                                                        </Select.Option>
                                                        <Select.Option
                                                            value="Estadual"
                                                            key="2"
                                                        >
                                                            Estadual
                                                        </Select.Option>
                                                        <Select.Option
                                                            value="Federal"
                                                            key="3"
                                                        >
                                                            Federal
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col {...estadosAssocModal}>
                                                <Form.Item
                                                    name="estadosAssoc"
                                                    label="Estados (Filtro)"
                                                    rules={[
                                                        {
                                                            required: !disabledEstadosAssocModal,
                                                            message: 'Por favor, selecione o estado.',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={disabledEstadosAssocModal}
                                                        loading={loadingEstadosAndCitySelected}
                                                        notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                                        placeholder="Selecione..."
                                                        onChange={onChangeFiltroEstadosAssoc}
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                    >
                                                        {
                                                            dadosEstadosAndCityList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >
                                                                        {item.nome}
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col {...cidadesAssocModal}>
                                                <Form.Item
                                                    name="associacoesFiltro"
                                                    label="Associação (Filtro)"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Por favor, selecione a associação.',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        loading={loadingDadosSelectAssocList}
                                                        notFoundContent={loadingDadosSelectAssocList ? <Spin size="small" /> : null}
                                                        placeholder="Selecione..."
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                    >
                                                        {
                                                            dadosSelectAssocList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >
                                                                        {item.nome}
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Spin>
                            </Modal>
                            <Row>
                                <Col {...inputSelectEstadosCity}>
                                    <Form.Item
                                        name="estadosCity"
                                        label="Estados"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Por favor, selecione um estado.",
                                            },
                                        ]}
                                    >
                                        <Select
                                            ref={refFormEstadoCitySelect}
                                            showSearch
                                            loading={loadingEstadosAndCitySelected}
                                            notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                            placeholder="Selecione..."
                                            mode="multiple"
                                            onDeselect={onDeselectEstadosAndCity}
                                            onSelect={onSelectEstadosAndCity}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            dropdownRender={menu => (
                                                <div>

                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button
                                                            ref={refBotaoEstadoCity}
                                                            block onClick={onClickAddTodosEstadosCidades}
                                                            type="dashed"
                                                        >Todos</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dadosEstadosAndCityList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {isHiddenCidades ?
                                    <Col {...inputSelectCidades}>
                                        <Form.Item
                                            name="cidadesSelect"
                                            // label="Modo Cidade (App)"
                                            label="Municípios"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Por favor, selecione uma cidade.",
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={onChangeSelectCidade}
                                                ref={refFormCidadesSelect}
                                                showSearch
                                                loading={loadingCidadesSelected}
                                                notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                                placeholder="Selecione..."
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) => {
                                                    let textDigit = removeAcento(input)
                                                    let listCidade = removeAcento(option?.children);
                                                    return listCidade.indexOf(textDigit) >= 0
                                                }}
                                                dropdownRender={menu => (
                                                    <div>

                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Button loading={loadingCidadesSelected} block onClick={onClickAddTodasCidadesInTable} type="dashed">Todos</Button>
                                                        </div>
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        {menu}
                                                    </div>
                                                )}
                                            >
                                                {
                                                    dadosCidades.map((item) => {
                                                        return (
                                                            <Select.Option
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.nome + " - " + item.uf}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    : ""}
                            </Row>
                        </Col>
                        <Col {...cardLayout}>
                            <Text>Municípios</Text>
                            <Button
                                onClick={() => {
                                    if (estaEditandoUsuario) {
                                        Modal.confirm({
                                            className: 'classmodalDadosContatoInatendimento',
                                            title: 'Atenção',
                                            icon: <ExclamationCircleOutlined />,
                                            content: `Todos usuários vinculados as cidade deste contrato \n
                                     irão perder o acesso, deseja realmente excluir?`
                                            ,
                                            okText: 'Sim',
                                            okType: 'danger',
                                            cancelText: 'Cancelar',
                                            onOk() {
                                                onClickRemoverTodasAsCidadesContratoTable()
                                            },
                                            onCancel() {
                                            },
                                        });
                                    } else {
                                        onClickRemoverTodasAsCidadesContratoTable()
                                    }
                                }}
                                hidden={dadosTabelaState.length != 0 ? false : true}
                                className="botaoRemoverCidadesContratoAll"
                                danger
                                type="primary">
                                Remover Todos
                            </Button>
                            <Table
                                rowKey={(record) => record.uuid}
                                loading={loadingTableCidades}
                                style={{ marginTop: 6 }}
                                className="tabelaAssociacaoAdd"
                                size="small"
                                columns={columns} dataSource={dadosTabelaState}
                                scroll={{ y: 200 }}
                                pagination={{ locale: { items_per_page: '' } }}
                            />
                        </Col>
                    </Row>

                    <Divider className="dividerPermisaoDoApp" />

                    <Col {...col12GTable} style={{ padding: 5 }}>
                        <ModulosDegustacao
                            funcSetValorModulosDegustacao={props.funcSetValorModulosDegustacao}
                            dataAcessosEdit={allDataAcessoFindById}
                            idPlano={valuePlano}
                        />
                    </Col>
                    <Col {...col12GTable} style={{ padding: 5 }}>
                        <RecursosDegustacao
                            funcSetValorRecursosDegustacao={props.funcSetValorRecursosDegustacao}
                            dataAcessosEdit={allDataAcessoFindById}
                        />
                    </Col>

                </Row>




                <Divider className="dividerPermisaoDoApp" />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="observacao"
                            label="Observações"
                            rules={[
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Spin >
            <Modal
                title="Dados do Plano"
                open={isModalDadosDoPlanoOpen}
                onOk={() => { setIsModalDadosDoPlanoOpen(false) }}
                footer={[
                    <></>
                    ,
                    <Button
                        type="primary"
                        onClick={() => { setIsModalDadosDoPlanoOpen(false) }}
                    >
                        OK
                    </Button>,
                ]}

            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Nome:</p>
                    <p>{refVIEWDadosPlanoSelect.current?.nome}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Valor:</p>
                    <p><strong>{'R$ '}{convertMoedaUSParaBR(refVIEWDadosPlanoSelect.current?.valor)}</strong></p>
                </div>
                <Divider className="dividerPermisaoDoApp" style={{ margin: '5px 0px' }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p style={{ marginRight: 5 }}>Modulos:</p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.modulos.map((value, index) => {
                            return (
                                <span key={index + 'tag1'} style={{ marginBottom: 4 }}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>

                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10
                    }}
                >
                    <p style={{ marginRight: 5 }}>Recursos:</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.recursos.map((value, index) => {
                            return (
                                <span key={index + 'tag'} style={{ marginBottom: 4 }}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>
                </div>

            </Modal>
        </>
    );
}
