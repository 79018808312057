import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Button, Input, Form, Select, Radio, Table, Space, Tag, Typography, Row, Col, Divider, Spin,
    notification, Modal, Popconfirm,
    Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { SearchOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import {
    selectedNomeOrgaoAdd,
    selectedPoderOrgaoAdd,
    selectedEsferaOrgaoAdd,
    selectedStatusOrgaoAdd,
    selectedVendedorAddContrato,
    selectedTipoContrato,
    inputObservaçoesInAddContrato,
    cardLayout,
    selectedSituacaoAddContrato,
    selectedModalidadeAddContratoComValidadeDeTeste,
    selectedModalidadeAddContrato,
    selectedDataValidadeDeTeste,
    selectedDataEnvioDaProposta,
    selectedVigenciaAddContrato,
    inputValorContratadoEstaEditando,
    inputValorContratado,
    inputSelectEstadosCity,
    inputSelectCidades,
    rowColNomeAndTipo,
    inputSelectTipoDaAssociacao,
    selectedSituacaoAddContratoTable,
    selectedModalidadeAddContratoTable,
    selectedDataEnvioDaPropostaTable,
    selectedVigenciaAddContratoTable,
    inputValorContratadoTable,
    salvarAditivoProrrogacaoInTable,
    colAditivoProrrogacaoInputs,
    tipoAssocModal,
    estadosAssocModal,
    cidadesAssocModal,
    selectedVigencialAtual,
    selectedInputPlanoContrato
} from './GridStyle';
import { mask as masker, unMask } from './../../utils/MascaraDeCampos';
import {
    urlEsfera, urlPoder, urlTiposContrato, urlSituacaoContrato,
    urlBuscaEstados, urlBuscaCidadesPorIdEstado, urlModalidade,
    urlContratoVendedores, urlContratoAditivos, urlContratoAditivo,
    urlAssocFederal, urlAssocFederalCidades, urlAssocEstadual, urlAssocEstadualCidades,
    urlAssocRegionalCidades, urlAssocRegional, urlTiposAditivos,
    urlContratosPlanoRecurso,
    urlContratosPlano
} from './../../services/request/urls';
import { getToken } from './../../services/token';
import './styles.css';
import moment from 'moment';
import {
    convertMoedaUSParaBR,
    convertDataUSParaBR,
    convertMoedaUSParaBRInicio,
    convertMoedaBRParaUS,
    convertDataBrParaUS
} from './../../services/Conversores';
import { removeAcento } from '../../utils/RemoveAcentos';
import { FaSearch } from 'react-icons/fa';
import { useGetColumnSearchProps } from '../../hooks/FilterTable/stringCLearFiltros';

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const { Text } = Typography;

interface interfaceCidadeLStorage {
    "id": number,
    "estadoId": number,
    "nome": string,
    "uf": string
}
interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
};

interface interfaceAssocData {
    id: number;
    nome: string;
    uuid: string;
};

interface interfaceCidadeEstados {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string
};

interface interfaceDadosDaTabelaAditivosProrrogacao {
    id: number,
    uuid: string,
    envio_proposta: string,
    vigencia: string,
    valor_contratado: string,
    observacao: string,
    created_at: string,
    updated_at: string,
    tipo_aditivo: {
        id: number,
        nome: string
    },
    situacao_contrato: {
        id: number,
        nome: string
    }
};

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    nome: string,
    uuid: string,
    estadoId: number,
    uf: string,
};

interface PropsTypes {
    formRef: any;
    funcSetValorTabela: any;
    setLoadingFormAdd?: (val: boolean) => void;
}

interface interfaceConfigs {
    nome: string,
    id: number
}

interface interfaceNewConfigs {
    nome: string,
    id: number
    uuid: string
}

interface IDadosPlanoSelecionadoModal {
    "uuid": string,
    "nome": string,
    // "descricao": string,
    "valor": string,
    "modulos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "path": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "recursos": Array<
        {
            "uuid": string,
            "nome": string,
            "descricao": string,
            "modoEstado": boolean,
            "modoMunicipio": boolean,
            "createdAt": string,
            "updatedAt": string
        }
    >,
    "createdAt": string,
    "updatedAt": string
}

const FormCriarContrato: React.FC<PropsTypes> = (props) => {

    const refFuncaoLimparFiltrosStringCidade = useRef<any>(null);
    const refFuncaoLimparFiltrosStringEstado = useRef<any>(null);

    const modalFiltroAssocFormRef = useRef<any>(null);
    const refFormCidadesSelect = useRef<any>(null);

    const refBotaoModoEstado = useRef<any>(null);
    const refFormModoEstadoSelect = useRef<any>(null);
    const refBotaoEstadoCity = useRef<any>(null);
    const refFormEstadoCitySelect = useRef<any>(null);

    const refDadosCidades = useRef<any>([]);
    const refDadosTotaisCidades = useRef<any>([]);

    const refDadosEsferaState = useRef<Array<interfaceConfigs>>([]);
    const refDadosPoderState = useRef<Array<interfaceConfigs>>([]);
    const refDadosTipoDeContratoState = useRef<Array<interfaceConfigs>>([]);
    const refDadosSituacaoState = useRef<Array<interfaceConfigs>>([]);
    const refDadosModalidadeState = useRef<Array<interfaceConfigs>>([]);
    const refDadosVendedoresState = useRef<Array<interfaceConfigs>>([]);

    //Search da tabela de listagem
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const [searchText2, setSearchText2] = useState('');
    const [searchedColumn2, setSearchedColumn2] = useState('');

    const [disabledSelectedSituacao, setDisabledSelectedSituacao] = useState(true);
    const [disabledSelectedModalidade, setDisabledSelectedModalidade] = useState(true);
    const [disabledSelectedEnvioDaProposta, setDisabledSelectedEnvioDaProposta] = useState(true);
    const [disabledSelectedVigencia, setDisabledSelectedVigencia] = useState(true);
    const [disabledSelectedValorContratado, setDisabledSelectedValorContratado] = useState(true);

    const [requiredInSelectedEnvioDaProposta, setRequiredInSelectedEnvioDaProposta] = useState(false);

    const [requiredSelectedVigencia, setRequiredSelectedVigencia] = useState(false);
    const [requiredInSelectedValidadeDeTeste, setRequiredInSelectedValidadeDeTeste] = useState(false);

    const [dadosCidades, setDadosCidades] = useState<Array<interfaceDadosCidades>>([]);
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);

    //Lista de estados antes da cidad
    const [dadosEstadosAndCityList, setDadosEstadosAndCityList] = useState<Array<interfaceDadosEstados>>([]);
    const [loadingEstadosAndCitySelected, setLoadingEstadosAndCitySelected] = useState(false);

    //Associacao listagem modal
    const [dadosSelectAssocList, setDadosSelectAssocList] = useState<Array<interfaceAssocData>>([]);
    const [loadingDadosSelectAssocList, setLoadingDadosSelectAssocList] = useState(false);

    const [loadingSpinModal, setLoadingSpinModal] = useState(false);

    const [isHiddenCidades, setIsHiddenCidades] = useState(false);

    const [dadosTabelaStateAditivoProrrogacao, setDadosTabelaStateAditivoProrrogacao] = useState<Array<interfaceDadosDaTabelaAditivosProrrogacao>>([]);
    const [isEditando, setIsEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState<interfaceDadosDaTabelaAditivosProrrogacao | any>([]);

    const [isAddInputsAditivoProrrogacao, setIsAddInputsAditivoProrrogacao] = useState(false);
    const [loadingAditivoProrrogacaoTable, setLoadingsAditivoProrrogacaoTable] = useState(false);

    const [loadingTableCidades, setloadingTableCidades] = useState(false);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceDadosCidades>>([]);
    const [dadosTotaisCidades, setDadosTotaisCidades] = useState<Array<interfaceDadosCidades>>([]);

    const [dadosEsferaList, setDadosEsferaList] = useState<Array<interfaceConfigs>>([]);
    const [loadingEsferaSelected, setLoadingEsferaSelected] = useState(false);
    const [dadosPoderList, setDadosPoderList] = useState<Array<interfaceConfigs>>([]);
    const [loadingPoderSelected, setLoadingPoderSelected] = useState(false);
    const [dadosTipoDeContratoList, setDadosTipoDeContratoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingTipoDeContratoSelected, setLoadingTipoDeContratoSelected] = useState(false);
    const [dadosSituacaoList, setDadosSituacaoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingSituacaoSelected, setLoadingSituacaoSelected] = useState(false);
    const [dadosModalidadeList, setDadosModalidadeList] = useState<Array<interfaceConfigs>>([]);
    const [loadingModalidadeSelected, setLoadingModalidadeSelected] = useState(false);
    const [dadosVendedoresList, setDadosVendedoresList] = useState<Array<interfaceConfigs>>([]);
    const [loadingVendedoresSelected, setLoadingVendedoresSelected] = useState(false);

    const [estaEditandoUsuario, setEstaEditandoUsuario] = useState(false);

    const [isHiddenBotaoAddProrrogacao, setIsHiddenBotaoAddProrrogacao] = useState(false);
    const [modalFiltrosVisible, setModalFiltrosVisible] = useState(false);

    const [isFiltroAssoc, setIsFiltroAssoc] = useState(false);

    const [dadosTipoAditivoList, setDadosTipoAditivoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingTipoAditivoSelected, setLoadingTipoAditivoSelected] = useState(false);

    const [loadingGlobal, setLoadingGlobal] = useState(false);
    const [disabledEstadosAssocModal, setDisabledEstadosAssocModal] = useState(false);

    const [valueTipoDeContrato, setValueTipoDeContrato] = useState('');

    const [selectedDateValidadeDeTeste, setSelectedDateValidadeDeTeste] = useState<any>(null);
    const [selectedDateEnvioDaProposta, setSelectedDateEnvioDaProposta] = useState<any>(null);
    const [selectedDateVigencia, setSelectedDateVigencia] = useState<any>(null);
    const [selectedDateEnvioPropostaTable, setSelectedDateEnvioPropostaTable] = useState<any>(null);
    const [selectedDateVigenciaTable, setSelectedDateVigenciaTable] = useState<any>(null);
    const [loadingAditivo, setLoadingAditivo] = useState(false);

    const refVIEWDadosPlanoSelect = useRef<IDadosPlanoSelecionadoModal>();
    const refDadosPlanoList = useRef<Array<interfaceNewConfigs>>([]);
    const [dadosPlanoList, setDadosPlanoList] = useState<Array<interfaceNewConfigs>>([]);
    const [valuePlano, setValuePlano] = useState<any>();
    const [loadingPlanoList, setLoadingPlanoList] = useState(false);
    const [isModalDadosDoPlanoOpen, setIsModalDadosDoPlanoOpen] = useState(false);

    //para poder cancelar a requisição
    const signal = useRef(axios.CancelToken.source());
    const signal1 = useRef(axios.CancelToken.source());
    const signal2 = useRef(axios.CancelToken.source());
    const signal3 = useRef(axios.CancelToken.source());
    const signal4 = useRef(axios.CancelToken.source());

    //funcao de setar cidades na tabela no select e estado no select quando for para editar contrato
    const buscaCityAndSetTabelaParaEditar = useCallback((arrayidEstado: Array<number> | [], arrayidCidade: Array<interfaceCidadeLStorage> | []) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                setloadingTableCidades(true);

                //verifica se o arau de estado enviado e diferented e 0
                if (arrayidEstado.length !== 0) {

                    //ja seta os estados no campo estado
                    props.formRef.current.setFieldsValue(
                        {
                            estadosCity: arrayidEstado,
                        }
                    );

                    //variaveis que receber todos as cidades selecionadas
                    let citySelecTable: any = [];
                    //todos cidades nao selecionada dos estados que foram selecionados
                    let cityNaoSelecTable: any = [];

                    //e total das cidades
                    let resultAxiosData: any = [];

                    //percorre o arrai de stado para fazer requisicao
                    for (let index = 0; index < arrayidEstado.length; index++) {
                        const idEstado = arrayidEstado[index];


                        let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                cancelToken: signal.current.token
                            });


                        let cidadesNSelecionadas: any = resultCidade.data;
                        let cidadesSelecionadasTable: any = [];

                        //percorremos o arrai de cidades selecionadas
                        for (let iCidade = 0; iCidade < arrayidCidade.length; iCidade++) {
                            const cidadeObj = arrayidCidade[iCidade];

                            //compara se a cidade que foi selecionada e do estado que fez a busca
                            if (cidadeObj.estadoId === idEstado) {

                                //retorna todas as cidades nao selecionadas desse estado
                                cidadesNSelecionadas = cidadesNSelecionadas.filter((val: any) => {
                                    return val.id !== cidadeObj.id
                                })

                                //seta todas as cidades que foi selecionada desse estado
                                resultCidade.data.forEach((element: any) => {
                                    if (element.id === cidadeObj.id) {
                                        cidadesSelecionadasTable.push(element);
                                    }
                                });


                            }
                        }

                        //da push adicionando nas variaveis fora do for para dar setState de uma unica vez
                        cidadesSelecionadasTable.forEach((element: any) => {
                            citySelecTable.push(element);
                        });
                        cidadesNSelecionadas.forEach((element: any) => {
                            cityNaoSelecTable.push(element);
                        });
                        resultCidade.data.forEach((element: any) => {
                            resultAxiosData.push(element);
                        });

                    }

                    //desbloqueia o campo cidade
                    setIsHiddenCidades(true);

                    //setando no select de cidade as cidade que nao estado na tabela
                    refDadosCidades.current = cityNaoSelecTable;
                    setDadosCidades(cityNaoSelecTable);

                    //setando o total de tudo
                    refDadosTotaisCidades.current = resultAxiosData;
                    setDadosTotaisCidades(resultAxiosData);

                    //setando os dados da tabela
                    setDadosTabelaState(citySelecTable);

                    setloadingTableCidades(false)
                    setLoadingCidadesSelected(false);

                } else {
                    setloadingTableCidades(false)
                    setLoadingCidadesSelected(false);

                }


            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    setloadingTableCidades(false)
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    const buscaCityAndSetTabelaParaFiltroAssoc = useCallback((arrayidEstado: any, arrayidCidade: Array<interfaceCidadeEstados> | [], tabelaDados: Array<interfaceDadosCidades>) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {

                //SETtotal cidades
                let totalCIDADE: any = [];

                //verifica se o arau de estado enviado e diferented e 0
                if (arrayidEstado.length !== 0) {

                    //variaveis que receber todos as cidades selecionadas
                    let citySelecTable: any = [];
                    //todos cidades nao selecionada dos estados que foram selecionados
                    let cityNaoSelecTable: any = [];

                    //e total das cidades
                    let resultAxiosData: any = [];

                    //percorre o array de stado para fazer requisicao
                    for (let index = 0; index < arrayidEstado.length; index++) {
                        const idEstado = arrayidEstado[index];


                        let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                cancelToken: signal.current.token
                            });


                        let cidadesNSelecionadas: any = resultCidade.data;
                        let cidadesSelecionadasTable: any = [];

                        //percorremos o arrai de cidades selecionadas
                        for (let iCidade = 0; iCidade < arrayidCidade.length; iCidade++) {
                            const cidadeObj = arrayidCidade[iCidade];

                            //compara se a cidade que foi selecionada e do estado que fez a busca
                            if (cidadeObj.estadoId === idEstado) {

                                //retorna todas as cidades nao selecionadas desse estado
                                cidadesNSelecionadas = cidadesNSelecionadas.filter((val: any) => {
                                    return val.id !== cidadeObj.id
                                })

                                //seta todas as cidades que foi selecionada desse estado
                                resultCidade.data.forEach((element: any) => {
                                    if (element.id === cidadeObj.id) {
                                        cidadesSelecionadasTable.push(element);
                                    }
                                });


                            }
                        }

                        //da push adicionando nas variaveis fora do for para dar setState de uma unica vez
                        cidadesSelecionadasTable.forEach((element: any) => {
                            citySelecTable.push(element);
                        });
                        cidadesNSelecionadas.forEach((element: any) => {
                            cityNaoSelecTable.push(element);
                        });
                        resultCidade.data.forEach((element: any) => {
                            resultAxiosData.push(element);
                        });

                    }

                    //ja seta os estados no campo estado
                    let valorCampoEstado: any = props.formRef.current.getFieldValue('estadosCity');

                    if (valorCampoEstado && valorCampoEstado?.length != 0) {

                        let isEquals = false;
                        let copyTabelaDados: any = [...valorCampoEstado];

                        for (let index = 0; index < valorCampoEstado.length; index++) {
                            const objCampoEstado: number = valorCampoEstado[index];

                            // for (let pos = 0; pos < arrayidEstado.length; pos++) {

                            arrayidEstado.forEach((element: any, posicao: any) => {
                                if (objCampoEstado == element) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyTabelaDados = copyTabelaDados.filter((valores: any) => {
                                    return valores != objCampoEstado
                                })
                                isEquals = false
                            }
                        }

                        copyTabelaDados.forEach((element: any) => {
                            arrayidEstado.push(element);
                        });

                        //seta em campo estado o array de idEstado
                        props.formRef.current.setFieldsValue(
                            {
                                estadosCity: arrayidEstado,
                            }
                        );

                        //para setar cidades totais faz requisicao das cidades e seta
                        for (let index = 0; index < arrayidEstado.length; index++) {
                            const idEstado = arrayidEstado[index];

                            let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + getToken()
                                    },
                                    cancelToken: signal.current.token
                                });

                            resultCidade.data.forEach((element: any) => {
                                totalCIDADE.push(element);
                            });
                        }
                        refDadosTotaisCidades.current = totalCIDADE;
                        setDadosTotaisCidades(totalCIDADE);

                    } else {
                        //seta em campo estado o array de idEstado
                        props.formRef.current.setFieldsValue(
                            {
                                estadosCity: arrayidEstado,
                            }
                        );

                        //para setar cidades totais faz requisicao das cidades e seta
                        for (let index = 0; index < arrayidEstado.length; index++) {
                            const idEstado = arrayidEstado[index];

                            let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + getToken()
                                    },
                                    cancelToken: signal.current.token
                                });

                            resultCidade.data.forEach((element: any) => {
                                totalCIDADE.push(element);
                            });
                        }

                        refDadosTotaisCidades.current = totalCIDADE;
                        setDadosTotaisCidades(totalCIDADE);

                    }

                    //desbloqueia o campo cidade
                    setIsHiddenCidades(true);

                    //percorrer ja selecionados da tabela
                    if (tabelaDados.length != 0) {

                        let isEquals = false;
                        let copyTabelaDados = [...tabelaDados];

                        for (let index = 0; index < tabelaDados.length; index++) {
                            const objDadosTabela = tabelaDados[index];

                            //percorre as ceidades selecionadas
                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objDadosTabela.uuid == element.uuid) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyTabelaDados = copyTabelaDados.filter((valores) => {
                                    return valores.uuid != objDadosTabela.uuid
                                })
                                isEquals = false
                            }

                        }

                        //percorre a copia da tabela removida os iguais e push nas cidades selecionadas
                        copyTabelaDados.forEach((element: any) => {
                            citySelecTable.push(element);
                        });

                        setDadosTabelaState(citySelecTable);

                        //SET CIDADES nao selecionadas no select
                        let isEqualsCity = false;
                        let copyCidadesSelected = [...totalCIDADE];

                        for (let index = 0; index < totalCIDADE.length; index++) {
                            const objTOTALcity = totalCIDADE[index];

                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objTOTALcity.uuid == element.uuid) {
                                    isEqualsCity = true;
                                }
                            });

                            if (isEqualsCity) {
                                copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                    return valores.uuid != objTOTALcity.uuid
                                })
                                isEqualsCity = false
                            }

                        }

                        refDadosCidades.current = copyCidadesSelected;
                        setDadosCidades(copyCidadesSelected);
                    } else {
                        setDadosTabelaState(citySelecTable);

                        //SET CIDADES nao selecionadas no select
                        let isEquals = false;
                        let copyCidadesSelected = [...totalCIDADE];

                        for (let index = 0; index < totalCIDADE.length; index++) {
                            const objTOTALcity = totalCIDADE[index];

                            citySelecTable.forEach((element: any, posicao: any) => {
                                if (objTOTALcity.uuid == element.uuid) {
                                    isEquals = true;
                                }
                            });

                            if (isEquals) {
                                copyCidadesSelected = copyCidadesSelected.filter((valores: any) => {
                                    return valores.uuid != objTOTALcity.uuid
                                })
                                isEquals = false
                            }

                        }

                        refDadosCidades.current = copyCidadesSelected;
                        setDadosCidades(copyCidadesSelected);

                    }

                }

                setLoadingCidadesSelected(false);
                setLoadingSpinModal(false);
                setModalFiltrosVisible(false);

                setDisabledEstadosAssocModal(false);
                modalFiltroAssocFormRef.current.resetFields();

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSpinModal(false);
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        buscaCiaddes();

    }, []);

    //executa apenas ao iniciar o component
    const listarDadosAditivoProrrogacaoTable = useCallback((idContrato, setTrue?): void => {
        setLoadingsAditivoProrrogacaoTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratoAditivos + "/" + idContrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                if (setTrue) {
                    if (result.data.length > 0) {
                        setIsAddInputsAditivoProrrogacao(true);
                    }
                }
                setDadosTabelaStateAditivoProrrogacao(result.data);
                setLoadingsAditivoProrrogacaoTable(false)

            } catch (error) {
                setLoadingsAditivoProrrogacaoTable(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    // const executaAoIniciar
    useEffect((): void => {
        // setLoadingGlobal(true);
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {

            setIsHiddenBotaoAddProrrogacao(true);

            setEstaEditandoUsuario(true);

            if (localUserData.tipo_contrato) {

                setValueTipoDeContrato(localUserData.tipo_contrato.nome);
                switch (localUserData.tipo_contrato.nome) {
                    case "Período de Teste":
                        setDisabledSelectedSituacao(false);
                        setDisabledSelectedModalidade(true);
                        setDisabledSelectedEnvioDaProposta(false);

                        setDisabledSelectedVigencia(true);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(true);

                        setRequiredInSelectedValidadeDeTeste(true);
                        break;
                    case "Cliente":
                        setDisabledSelectedSituacao(false);
                        setDisabledSelectedModalidade(false);
                        setDisabledSelectedEnvioDaProposta(false);

                        setDisabledSelectedVigencia(false);
                        setRequiredSelectedVigencia(true);

                        setDisabledSelectedValorContratado(false);


                        setRequiredInSelectedValidadeDeTeste(false);
                        break;
                    case "Associação":
                        setDisabledSelectedSituacao(false);
                        setDisabledSelectedModalidade(false);
                        setDisabledSelectedEnvioDaProposta(false);

                        setDisabledSelectedVigencia(false);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(false);
                        setIsFiltroAssoc(true);

                        setRequiredInSelectedValidadeDeTeste(false);
                        break;
                    case "Govfácil":
                        setDisabledSelectedSituacao(true);
                        setDisabledSelectedModalidade(true);
                        setDisabledSelectedEnvioDaProposta(true);

                        setDisabledSelectedVigencia(true);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(true);

                        setRequiredInSelectedValidadeDeTeste(false);
                        break;
                    case "Parceiros":
                        setDisabledSelectedSituacao(true);
                        setDisabledSelectedModalidade(true);
                        setDisabledSelectedEnvioDaProposta(true);

                        setDisabledSelectedVigencia(true);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(true);

                        setRequiredInSelectedValidadeDeTeste(false);
                        break;

                    case "Automático":
                        setDisabledSelectedSituacao(true);
                        setDisabledSelectedModalidade(true);
                        setDisabledSelectedEnvioDaProposta(true);

                        setDisabledSelectedVigencia(true);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(true);
                        setIsFiltroAssoc(false);
                        setRequiredInSelectedValidadeDeTeste(true);
                        break;

                    default:
                        setDisabledSelectedSituacao(true);
                        setDisabledSelectedModalidade(true);
                        setDisabledSelectedEnvioDaProposta(true);

                        setDisabledSelectedVigencia(true);
                        setRequiredSelectedVigencia(false);

                        setDisabledSelectedValorContratado(true);
                        setIsFiltroAssoc(false);
                        setRequiredInSelectedValidadeDeTeste(false);
                        break;
                }
            }

            //valores e regras iniciais para situação
            if (localUserData.situacao_contrato !== undefined && localUserData.situacao_contrato !== null) {

                switch (localUserData.situacao_contrato.nome) {
                    case "Aguardando Emissão NF":
                        setRequiredInSelectedEnvioDaProposta(true);
                        break;
                    case "Aguardando Pagamento":
                        setRequiredInSelectedEnvioDaProposta(true);
                        break;
                    case "Encerrado":
                        setRequiredInSelectedEnvioDaProposta(false);
                        break;
                    case "Cancelado":
                        setRequiredInSelectedEnvioDaProposta(false);
                        break;
                    case "Pago":
                        setRequiredInSelectedEnvioDaProposta(true);
                        break;
                    case "Proposta Enviada":
                        setRequiredInSelectedEnvioDaProposta(true);
                        break;
                    case "Período de Teste":
                        setRequiredInSelectedEnvioDaProposta(false);
                        break;
                    case "Processo em Andamento":
                        setRequiredInSelectedEnvioDaProposta(true);
                        break;

                    default:
                        break;

                }
            }

            if (localUserData?.cidades && localUserData?.cidades.length !== 0) {

                let estadosID: any = []
                localUserData?.cidades.forEach((vende: any) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaEditar(estadosID, localUserData.cidades)

            }
            if (localUserData?.id) {
                listarDadosAditivoProrrogacaoTable(localUserData.id, true);
            }

            if (localUserData?.validade_teste) {
                setSelectedDateValidadeDeTeste(moment(localUserData?.validade_teste));
            }

            if (localUserData?.envio_proposta) {
                setSelectedDateEnvioDaProposta(moment(localUserData?.envio_proposta));
            }

            if (localUserData?.vigencia) {
                setSelectedDateVigencia(moment(localUserData?.vigencia));
            }

            if (localUserData?.plano) {
                setValuePlano(localUserData?.plano?.id);
            }

        }
        // setTimeout(() => {
        //     setLoadingGlobal(false);
        // }, 320);
    }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:contrato');
        }

        //remover as requisiçoes quando não terminou
        signal.current.cancel("Requisicao cancelada!")
        signal1.current.cancel("Requisicao cancelada!")
        signal2.current.cancel("Requisicao cancelada!")
        signal3.current.cancel("Requisicao cancelada!")
        signal4.current.cancel("Requisicao cancelada!")
    }, []);

    const openModalDadosPlano = useCallback(() => {

        const buscaDados = async () => {
            try {
                setLoadingGlobal(true)
                const idPlanoSelecionado = props.formRef.current.getFieldValue('planoId');

                const { data } = await axios.get(urlContratosPlano + '/' + idPlanoSelecionado,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    }
                );

                refVIEWDadosPlanoSelect.current = data

                setTimeout(() => {
                    setIsModalDadosDoPlanoOpen(true)
                    setLoadingGlobal(false)
                }, 500);
            } catch (error) {
                setLoadingGlobal(false)
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        buscaDados();
    }, [props.formRef])

    const buscarDadosPlanoList = useCallback(() => {

        setLoadingPlanoList(true);
        const buscaDados = async () => {
            try {

                let result = await axios.get(urlContratosPlano,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosPlanoList.current = result.data;
                setDadosPlanoList(result.data)

                setLoadingPlanoList(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingPlanoList(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosEsferaList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ esfera: nome })
        }
        setLoadingEsferaSelected(true);
        const buscaDadosEsfera = async () => {
            try {
                let result = await axios.get(urlEsfera,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal4.current.token
                    });

                refDadosEsferaState.current = result.data;
                setDadosEsferaList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceConfigs) => {
                        if (val.id === id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { esfera: nome }
                    )
                }
                setLoadingEsferaSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingEsferaSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Esfera!',
                    });
                }
            }
        }

        buscaDadosEsfera();

    }, [props.formRef]);

    // const selectEsferaPorName = useCallback((nome): number | null => {
    //     let idEsfera = null;
    //     refDadosEsferaState.current.forEach(val => {
    //         if (val.nome == nome) {
    //             idEsfera = val.id
    //         }
    //     });
    //     return idEsfera;

    // }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosPoderList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ poder: nome })
        }
        setLoadingPoderSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlPoder,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosPoderState.current = result.data;
                setDadosPoderList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceConfigs) => {
                        if (val.id === id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { Poder: nome }
                    )
                }

                setLoadingPoderSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingPoderSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Poder!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, [props.formRef]);

    // const selectPoderPorName = useCallback((nome): number | null => {
    //     let idPoder = null;
    //     refDadosPoderState.current.forEach(val => {
    //         if (val.nome == nome) {
    //             idPoder = val.id
    //         }
    //     });
    //     return idPoder;

    // }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosTipoDeContratoList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ tipo_contrato: nome })
        }

        setLoadingTipoDeContratoSelected(true);
        const buscaDadosTipoDeContrato = async () => {
            try {
                let result = await axios.get(urlTiposContrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosTipoDeContratoState.current = result.data;
                setDadosTipoDeContratoList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceConfigs) => {
                        if (val.id === id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { tipo_contrato: nome }
                    )
                }
                setLoadingTipoDeContratoSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message !== 'Requisicao cancelada!') {
                    setLoadingTipoDeContratoSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Tipo de contrato!',
                    });
                }
            }
        }

        buscaDadosTipoDeContrato();

    }, [props.formRef]);

    // const selectTipoDeContratoPorName = useCallback((nome): number | null => {
    //     let idTipoDeContrato = null;
    //     refDadosTipoDeContratoState.current.forEach(val => {
    //         if (val.nome == nome) {
    //             idTipoDeContrato = val.id
    //         }
    //     });
    //     return idTipoDeContrato;

    // }, []);

    const selectSituacaoPorName = useCallback((nome): number | null => {
        let idSituacao = null;
        refDadosSituacaoState.current.forEach(val => {
            if (val.nome == nome) {
                idSituacao = val.id
            }
        });
        return idSituacao;

    }, []);

    const selectSituacaoPorID = useCallback((id): string | null => {
        let idSituacao = null;
        refDadosSituacaoState.current.forEach(val => {
            if (val.id == id) {
                idSituacao = val.nome
            }
        });
        return idSituacao;

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosSituacaoList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ situacao_contrato: nome })
        }

        setLoadingSituacaoSelected(true);
        const buscaDadosSituacao = async () => {
            try {
                let result = await axios.get(urlSituacaoContrato,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosSituacaoState.current = result.data;
                setDadosSituacaoList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceConfigs) => {
                        if (val.id == id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { situacao_contrato: nome }
                    )
                }

                setLoadingSituacaoSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSituacaoSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar situação!',
                    });
                }
            }
        }

        buscaDadosSituacao();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosModalidadeList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ esfera: nome })
        }
        setLoadingModalidadeSelected(true);
        const buscaDadosModalidade = async () => {
            try {
                let result = await axios.get(urlModalidade,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                refDadosModalidadeState.current = result.data;
                setDadosModalidadeList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceConfigs) => {
                        if (val.id == id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { modalidade: nome }
                    )
                }
                setLoadingModalidadeSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingModalidadeSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Modalidade!',
                    });
                }
            }
        }

        buscaDadosModalidade();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosTipoAditivoList = useCallback(() => {

        setLoadingTipoAditivoSelected(true);
        const buscaDadosTipoAditivo = async () => {
            try {
                let result = await axios.get(urlTiposAditivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.current.token
                    });

                setDadosTipoAditivoList(result.data)

                setLoadingTipoAditivoSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTipoAditivoSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar TipoAditivo!',
                    });
                }
            }
        }

        buscaDadosTipoAditivo();

    }, []);

    // const selectModalidadePorName = useCallback((nome): number | null => {
    //     let idModalidade = null;
    //     refDadosModalidadeState.current.forEach(val => {
    //         if (val.nome == nome) {
    //             idModalidade = val.id
    //         }
    //     });
    //     return idModalidade;

    // }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosVendedoresList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            props.formRef.current.setFieldsValue({ esfera: nome })
        }
        setLoadingVendedoresSelected(true);
        const buscaDadosVendedores = async () => {
            try {
                let result = await axios.get(urlContratoVendedores,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal3.current.token
                    });

                let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }

                let copyReqData = [...result.data];

                if (localUserData) {
                    if (localUserData?.vendedores && localUserData?.vendedores.length !== 0) {

                        localUserData?.vendedores.forEach((vendeLS: any) => {

                            let vendedorDiferente = false
                            result.data.forEach((vendedReq: any) => {
                                if (vendedReq.id == vendeLS.id) {
                                    vendedorDiferente = true
                                }
                            });

                            if (!vendedorDiferente) {
                                copyReqData.push(vendeLS);
                            }

                        });

                    }
                }

                refDadosVendedoresState.current = copyReqData;
                setDadosVendedoresList(copyReqData);

                if (selectIdAndSet) {
                    let nome = null;
                    await copyReqData.forEach((val: interfaceConfigs) => {
                        if (val.id == id) {
                            nome = val.nome
                        }
                    });
                    props.formRef.current.setFieldsValue(
                        { vendedores: nome }
                    )
                }
                setLoadingVendedoresSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingVendedoresSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Vendedores!',
                    });
                }
            }
        }

        buscaDadosVendedores();

    }, []);

    const selectVendedoresPorName = useCallback((nome): number | null => {
        let idVendedores = null;
        refDadosVendedoresState.current.forEach(val => {
            if (val.nome == nome) {
                idVendedores = val.id
            }
        });
        return idVendedores;

    }, []);

    //setando dados das cidades no selected
    const buscaEstadosAndCityESetSelected = useCallback(() => {
        setLoadingEstadosAndCitySelected(true);
        const buscaEstados = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal2.current.token
                    });
                setDadosEstadosAndCityList(resultCidade.data);
                setLoadingEstadosAndCitySelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingEstadosAndCitySelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        buscaEstados();

    }, []);

    //executa ao inicio e chama todos esses metodos de requisição
    useEffect((): void => {
        buscarDadosEsferaList();
        buscarDadosPoderList();
        buscarDadosVendedoresList();
        buscarDadosTipoDeContratoList();
        buscarDadosSituacaoList();
        buscarDadosModalidadeList();
        buscaEstadosAndCityESetSelected();
        buscarDadosTipoAditivoList();
        buscarDadosPlanoList();
    }, []);

    //ADITIVO PRORROGACAO

    // Click botao salvar tabela
    const onClickSalvarAditivoProrrogacaoTable = useCallback((): void => {
        const situacaoContratoTable = props.formRef.current.getFieldValue('situacao_contrato_table');
        const tipoAditivoTable = props.formRef.current.getFieldValue('tipo_aditivo');
        const envioPropostaTable = props.formRef.current.getFieldValue('envio_proposta_table');
        const vigenciaTable = props.formRef.current.getFieldValue('vigencia_table');
        const vigenciaTableParaSetarEmVigenciaAtual = props.formRef.current.getFieldValue('vigencia_table');
        const valorContratadoTable = props.formRef.current.getFieldValue('valor_contratado_table');
        const ObservacaoTable = props.formRef.current.getFieldValue('observacao_table');

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const inserirDadosAditivoProrrogacao = async (obj: any) => {
            try {
                setLoadingAditivo(true)

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(true);
                }

                let resultCidade = await axios.post(urlContratoAditivo, obj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Aditivo foi inserido com sucesso!',
                });

                listarDadosAditivoProrrogacaoTable(obj.contratoId);

                //Setando null em cidadeContrato
                props.formRef.current.setFieldsValue({
                    "situacao_contrato_table": undefined,
                    "tipo_aditivo": undefined,
                    "envio_proposta_table": undefined,
                    "vigencia_table": undefined,
                    "valor_contratado_table": undefined,
                    "observacao_table": undefined,
                });

                //setando null na variavel do calendario material ui
                setSelectedDateEnvioPropostaTable(null)
                setSelectedDateVigenciaTable(null)

                props.formRef.current.setFieldsValue(
                    { vigencia_atual: moment(vigenciaTableParaSetarEmVigenciaAtual).format('DD/MM/YYYY') }
                );

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(false);
                }

                setLoadingAditivo(false);
            } catch (error) {
                setLoadingAditivo(false);

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(false);
                }

                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel inserir aditivo!',
                });
            }
        }

        const editarDadosAditivoProrrogacao = async (obj: any) => {
            try {
                setLoadingAditivo(true)

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(true);
                }

                let resultCidade = await axios.put(urlContratoAditivos + "/" + obj.id, obj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Aditivo foi alterado com sucesso!',
                });

                listarDadosAditivoProrrogacaoTable(obj.contratoId);

                //Setando null em cidadeContrato
                props.formRef.current.setFieldsValue({
                    "situacao_contrato_table": undefined,
                    "tipo_aditivo": undefined,
                    "envio_proposta_table": undefined,
                    "vigencia_table": undefined,
                    "valor_contratado_table": undefined,
                    "observacao_table": undefined,
                });

                //setando null na variavel do calendario material ui
                setSelectedDateEnvioPropostaTable(null)
                setSelectedDateVigenciaTable(null)

                setIsEditando(false);
                setDadosEditando([]);

                props.formRef.current.setFieldsValue(
                    { vigencia_atual: moment(vigenciaTableParaSetarEmVigenciaAtual).format('DD/MM/YYYY') }
                );

                setLoadingAditivo(false);

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(false);
                }

            } catch (error) {
                setLoadingAditivo(false);

                if (typeof props?.setLoadingFormAdd === 'function') {
                    props?.setLoadingFormAdd(false);
                }

                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel alterar aditivo!',
                });
            }
        }

        if (situacaoContratoTable && tipoAditivoTable && envioPropostaTable &&
            vigenciaTable && valorContratadoTable) {

            //verifica se esta editando
            if (!isEditando) {
                //SALVAR DADOS

                const obj: any = {
                    "situacao_contrato": -1,
                    "tipo_aditivo": -1,
                    "envio_proposta": '',
                    "vigencia": '',
                    "valor_contratado": '',
                    "observacao": '',
                    "contratoId": -1
                };

                //Alimentado var obj
                obj["situacao_contrato"] = situacaoContratoTable
                obj["tipo_aditivo"] = tipoAditivoTable
                obj["envio_proposta"] = convertDataBrParaUS(envioPropostaTable)
                obj["vigencia"] = convertDataBrParaUS(vigenciaTable)
                obj["valor_contratado"] = convertMoedaBRParaUS(valorContratadoTable)
                obj["observacao"] = ObservacaoTable
                if (localUserData) {
                    obj["contratoId"] = localUserData.id
                }

                //SALVAR DADOS REQUISICAO E SALVAR ADITIVO
                inserirDadosAditivoProrrogacao(obj);

            } else {
                //EDITAR DADOS

                dadosEditando.situacao_contrato = situacaoContratoTable
                dadosEditando.tipo_aditivo = tipoAditivoTable
                dadosEditando.envio_proposta = convertDataBrParaUS(envioPropostaTable)
                dadosEditando.vigencia = convertDataBrParaUS(vigenciaTable)
                dadosEditando.valor_contratado = convertMoedaBRParaUS(valorContratadoTable)
                dadosEditando.observacao = ObservacaoTable
                dadosEditando.contratoId = localUserData.id

                editarDadosAditivoProrrogacao(dadosEditando);

            }// fim else editar

        } else {
            //se não exitir alguns dos campos preenchidos
            notification.error({
                message: 'Erro',
                description:
                    'Preencha todos os campos para adicionar na tabela!',
            });
        }


    }, [dadosTabelaStateAditivoProrrogacao, isEditando, dadosEditando, props]);

    // fim do search da tabela de listagem
    const editarDaTabelaAditivoProrrogacao = useCallback((recorde: interfaceDadosDaTabelaAditivosProrrogacao): void => {

        //Setando null em cidadeContrato

        //setando data na variavel do calendario material ui
        setSelectedDateEnvioPropostaTable(moment(recorde.envio_proposta))
        setSelectedDateVigenciaTable(moment(recorde.vigencia))


        props.formRef.current.setFieldsValue({
            "situacao_contrato_table": recorde.situacao_contrato.id,
            "tipo_aditivo": recorde.tipo_aditivo.id,
            "envio_proposta_table": moment(recorde.envio_proposta),
            "vigencia_table": moment(recorde.vigencia),
            "valor_contratado_table": convertMoedaUSParaBRInicio(recorde.valor_contratado),
            "observacao_table": recorde.observacao,
        });

        setIsEditando(true);
        setDadosEditando(recorde);

    }, [dadosTabelaStateAditivoProrrogacao]);

    const onClickCancelarCidadesContrato = useCallback((): void => {

        //setando null na variavel do calendario material ui
        setSelectedDateEnvioPropostaTable(null)
        setSelectedDateVigenciaTable(null)

        //Setando null em cidadeContrato
        props.formRef.current.setFieldsValue({
            "situacao_contrato_table": undefined,
            "tipo_aditivo": undefined,
            "envio_proposta_table": undefined,
            "vigencia_table": undefined,
            "valor_contratado_table": undefined,
            "observacao_table": undefined,
        });

        setIsEditando(false);
        setDadosEditando([]);
    }, []);

    const onClickAditivoProrrogacaoAdicionar = useCallback((): void => {
        setIsAddInputsAditivoProrrogacao(true);
    }, []);

    //FIM ADITIVO PRORROGACAO

    const onClickAddTodosEstadosGov = useCallback(() => {

        if (props.formRef.current.getFieldValue('estados')?.length != 27) {
            let arrayIds: any = [];
            dadosEstadosAndCityList.forEach(val => {
                arrayIds.push(val.id);
            });
            props.formRef.current.setFieldsValue(
                { estados: arrayIds }
            );

            refBotaoModoEstado.current?.blur();
            refFormModoEstadoSelect.current?.blur();
        }
    }, [dadosEstadosAndCityList]);

    //setando dados das cidades no selected
    const buscaCityAndSetSelected = useCallback((idEstado: number) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        cancelToken: signal.current.token
                    });

                if (refDadosTotaisCidades.current.length == 0) {
                    //para poder adicionar no array
                    refDadosCidades.current = resultCidade.data;
                    setDadosCidades(resultCidade.data);

                    refDadosTotaisCidades.current = resultCidade.data;
                    setDadosTotaisCidades(resultCidade.data);
                    setLoadingCidadesSelected(false);
                } else {
                    //adicionar no array com outras cidades

                    let arrayCidades: any = [];

                    refDadosCidades.current.map((valor: any) => {
                        arrayCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayCidades.push(valor);
                    });

                    refDadosCidades.current = arrayCidades;
                    setDadosCidades(arrayCidades);

                    let arrayToddasCidades: any = [];

                    refDadosTotaisCidades.current.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });
                    resultCidade.data.map((valor: any) => {
                        arrayToddasCidades.push(valor);
                    });

                    refDadosTotaisCidades.current = arrayToddasCidades;
                    setDadosTotaisCidades(arrayToddasCidades);

                    setLoadingCidadesSelected(false);
                }

                return true

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
                return false
            }
        }

        return buscaCiaddes();

    }, []);

    const onClickAddTodosEstadosCidades = useCallback(() => {
        const pegarEstadosESetCidades = async () => {
            try {
                let valInputEstados = props.formRef.current.getFieldValue('estadosCity');

                if (props.formRef.current.getFieldValue('estadosCity')?.length != 27) {

                    //verifica se inputs se ja foi preenchido
                    if (valInputEstados != undefined && valInputEstados.length != 0) {

                        //verificando qual esta selecionado qual nao foi selecionado
                        let array: any = [];
                        let arrayIdsTodos: any = [];
                        dadosEstadosAndCityList.forEach((dadosEstadoCity) => {
                            arrayIdsTodos.push(dadosEstadoCity.id);
                            let bool = false;
                            valInputEstados.forEach((idInInput: number) => {

                                if (dadosEstadoCity.id == idInInput) {
                                    bool = true
                                }
                            });

                            if (!bool) {
                                array.push(dadosEstadoCity);
                            }
                        });

                        array.forEach(async (estadosFiltro: any) => {
                            await buscaCityAndSetSelected(estadosFiltro.id);
                        });

                        props.formRef.current.setFieldsValue(
                            { estadosCity: arrayIdsTodos }
                        );

                    } else {
                        //se nao foi preenchido
                        let arrayIds: any = [];
                        dadosEstadosAndCityList.forEach(async (estadosFiltro: any) => {

                            arrayIds.push(estadosFiltro.id);
                            await buscaCityAndSetSelected(estadosFiltro.id);

                        });

                        props.formRef.current.setFieldsValue(
                            { estadosCity: arrayIds }
                        );
                        setIsHiddenCidades(true);
                    }

                    refBotaoEstadoCity.current?.blur();
                    refFormEstadoCitySelect.current?.blur();
                }
            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCidadesSelected(false);
                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        pegarEstadosESetCidades();
    }, [dadosEstadosAndCityList]);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { validade_teste: selectedDateValidadeDeTeste, }
        );

    }, [selectedDateValidadeDeTeste]);

    const onChangeDataValidadeDeTeste = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = masker(originalValue, [
            "99/99/9999"
        ]);

        props.formRef.current.setFieldsValue(
            { validade_teste: maskedValue }
        );

    }, []);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { envio_proposta: selectedDateEnvioDaProposta, }
        );

    }, [selectedDateEnvioDaProposta]);

    const onChangeDataEnvioProposta = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = masker(originalValue, [
            "99/99/9999"
        ]);

        props.formRef.current.setFieldsValue(
            { envio_proposta: maskedValue }
        );

    }, []);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { envio_proposta_table: selectedDateEnvioPropostaTable, }
        );

    }, [selectedDateEnvioPropostaTable]);

    const onChangeDataEnvioPropostaTable = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = masker(originalValue, [
            "99/99/9999"
        ]);

        props.formRef.current.setFieldsValue(
            { envio_proposta_table: maskedValue }
        );

    }, []);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { vigencia: selectedDateVigencia, }
        );

    }, [selectedDateVigencia]);

    const onChangeDataVigencia = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = masker(originalValue, [
            "99/99/9999"
        ]);

        props.formRef.current.setFieldsValue(
            { vigencia: maskedValue }
        );

    }, []);

    useEffect((): any => {

        props.formRef.current.setFieldsValue(
            { vigencia_table: selectedDateVigenciaTable, }
        );

    }, [selectedDateVigenciaTable]);

    const onChangeDataVigenciaTable = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = masker(originalValue, [
            "99/99/9999"
        ]);

        props.formRef.current.setFieldsValue(
            { vigencia_table: maskedValue }
        );

    }, []);

    const onChangeMoney = useCallback((e: any) => {

        props.formRef.current.setFieldsValue(
            { valor_contratado: convertMoedaUSParaBR(e.target.value) }
        );

    }, []);

    const onChangeMoneyTable = useCallback((e: any) => {

        props.formRef.current.setFieldsValue(
            { valor_contratado_table: convertMoedaUSParaBR(e.target.value) }
        );

    }, []);

    const onSelectEstadosAndCity = useCallback((e: number) => {
        setIsHiddenCidades(true);
        buscaCityAndSetSelected(e);
    }, []);

    const onDeselectEstadosAndCity = useCallback((id: number) => {
        if (dadosTabelaState.length != 0) {

            let filter = dadosTabelaState.filter((val) => {
                return val.estadoId != id
            })

            setDadosTabelaState(filter);

        }
        let filter = dadosCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosCidades.current = filter;
        setDadosCidades(filter);

        let filterTotais = dadosTotaisCidades.filter((val) => {
            return val.estadoId != id
        })

        refDadosTotaisCidades.current = filterTotais;
        setDadosTotaisCidades(filterTotais);

        if (props.formRef.current.getFieldValue('estadosCity').length == 0) {
            setIsHiddenCidades(false);
        }

    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades, dadosEstadosAndCityList]);

    const onChangeTipoDeContrato = useCallback((e: any, obj) => {

        setValueTipoDeContrato(obj.children);
        switch (obj.children) {
            case "Período de Teste":
                setDisabledSelectedSituacao(false);
                setDisabledSelectedModalidade(true);
                setDisabledSelectedEnvioDaProposta(false);

                setDisabledSelectedVigencia(true);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(true);
                setIsFiltroAssoc(false);

                setRequiredInSelectedValidadeDeTeste(true);

                //procurar id de proposta enviada
                let idSituacao = selectSituacaoPorName("Período de Teste");

                props.formRef.current.setFieldsValue(
                    {
                        situacao_contrato: idSituacao,
                        vigencia: null,
                        valor_contratado: null,
                        modalidade: null,
                    }
                );

                setRequiredInSelectedEnvioDaProposta(false)
                break;
            case "Cliente":
                setDisabledSelectedSituacao(false);
                setDisabledSelectedModalidade(false);
                setDisabledSelectedEnvioDaProposta(false);

                setDisabledSelectedVigencia(false);
                setRequiredSelectedVigencia(true);

                setDisabledSelectedValorContratado(false);
                setIsFiltroAssoc(false);

                setRequiredInSelectedValidadeDeTeste(false);
                break;
            case "Associação":
                setDisabledSelectedSituacao(false);
                setDisabledSelectedModalidade(false);
                setDisabledSelectedEnvioDaProposta(false);

                setDisabledSelectedVigencia(false);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(false);
                setIsFiltroAssoc(true);

                setRequiredInSelectedValidadeDeTeste(true);
                break;
            case "Govfácil":
                setDisabledSelectedSituacao(true);
                setDisabledSelectedModalidade(true);
                setDisabledSelectedEnvioDaProposta(true);

                setDisabledSelectedVigencia(true);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(true);
                setIsFiltroAssoc(false);

                setRequiredInSelectedValidadeDeTeste(false);
                props.formRef.current.setFieldsValue(
                    {
                        situacao_contrato: null,
                        modalidade: null,
                        envio_proposta: null,
                        vigencia: null,
                        valor_contratado: null,
                    }
                );

                setRequiredInSelectedEnvioDaProposta(false)
                break;
            case "Parceiros":
                setDisabledSelectedSituacao(true);
                setDisabledSelectedModalidade(true);
                setDisabledSelectedEnvioDaProposta(true);

                setDisabledSelectedVigencia(true);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(true);
                setIsFiltroAssoc(false);

                setRequiredInSelectedValidadeDeTeste(false);
                props.formRef.current.setFieldsValue(
                    {
                        situacao_contrato: null,
                        modalidade: null,
                        envio_proposta: null,
                        vigencia: null,
                        valor_contratado: null,
                    }
                );

                setRequiredInSelectedEnvioDaProposta(false)
                break;
            case "Automático":
                setDisabledSelectedSituacao(true);
                setDisabledSelectedModalidade(true);
                setDisabledSelectedEnvioDaProposta(true);

                setDisabledSelectedVigencia(true);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(true);
                setIsFiltroAssoc(false);

                setRequiredInSelectedValidadeDeTeste(true);
                props.formRef.current.setFieldsValue(
                    {
                        situacao_contrato: null,
                        modalidade: null,
                        envio_proposta: null,
                        vigencia: null,
                        valor_contratado: null,
                    }
                );

                setRequiredInSelectedEnvioDaProposta(false)
                break;

            default:
                setDisabledSelectedSituacao(true);
                setDisabledSelectedModalidade(true);
                setDisabledSelectedEnvioDaProposta(true);

                setDisabledSelectedVigencia(true);
                setRequiredSelectedVigencia(false);

                setDisabledSelectedValorContratado(true);
                setIsFiltroAssoc(false);
                setRequiredInSelectedValidadeDeTeste(false);

                break;
        }

    }, []);

    const onChangeSituacaoContrato = useCallback((e: any, obj) => {

        switch (obj.children) {
            case "Aguardando Emissão NF":
                setRequiredInSelectedEnvioDaProposta(true);
                break;
            case "Aguardando Pagamento":
                setRequiredInSelectedEnvioDaProposta(true);
                break;
            case "Encerrado":
                setRequiredInSelectedEnvioDaProposta(false);
                break;
            case "Cancelado":
                setRequiredInSelectedEnvioDaProposta(false);
                break;
            case "Pago":
                setRequiredInSelectedEnvioDaProposta(true);
                break;
            case "Proposta Enviada":
                setRequiredInSelectedEnvioDaProposta(true);
                break;
            case "Período de Teste":
                setRequiredInSelectedEnvioDaProposta(false);
                break;
            case "Processo em Andamento":
                setRequiredInSelectedEnvioDaProposta(true);
                break;

            default:
                break;
        }

    }, []);

    const onchangeTipoAssocModal = useCallback((e: any, obj) => {

        const buscaAssocFederal = async () => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocFederal,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        switch (obj.children) {
            case "Regional":
                setDisabledEstadosAssocModal(false);
                setDadosSelectAssocList([]);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
            case "Estadual":
                setDisabledEstadosAssocModal(false);
                setDadosSelectAssocList([]);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
            case "Federal":
                setDisabledEstadosAssocModal(true);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                buscaAssocFederal();
                break;

            default:
                setDisabledEstadosAssocModal(false);
                modalFiltroAssocFormRef.current.setFieldsValue(
                    {
                        estadosAssoc: null,
                        associacoesFiltro: null,
                    }
                );
                break;
        }

    }, []);

    const buscaAssociacao = useCallback((tipo: string, idEstado: number) => {

        setLoadingDadosSelectAssocList(true);
        const buscaAssocRegional = async (idEstadosfun: number) => {
            try {
                let result = await axios.get(urlAssocRegional + "/" + idEstadosfun,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocEstadual = async (idEstadosfun: number) => {
            try {
                let result = await axios.get(urlAssocEstadual + "/" + idEstadosfun,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDadosSelectAssocList(result.data);
                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        switch (tipo) {
            case "Regional":
                buscaAssocRegional(idEstado);
                break;
            case "Estadual":
                buscaAssocEstadual(idEstado)
                break;

            default:
                setLoadingDadosSelectAssocList(false);
                break;
        }
    }, []);

    const onChangeFiltroEstadosAssoc = useCallback((e: any, obj) => {
        let valueTipo = modalFiltroAssocFormRef.current.getFieldValue('tipoAssociacao');

        if (valueTipo) {
            buscaAssociacao(valueTipo, obj.value);
            modalFiltroAssocFormRef.current.setFieldsValue(
                {
                    associacoesFiltro: null,
                }
            );
        } else {
            notification.error({
                message: 'Erro',
                description:
                    'Obrigatorio selecionar o campo tipo!',
            });
            modalFiltroAssocFormRef.current.setFieldsValue(
                {
                    estadosAssoc: null,
                    associacoesFiltro: null,
                }
            );
        }

    }, [buscaAssociacao]);

    const onClickAtivarModalFiltros = useCallback((): void => {
        setModalFiltrosVisible(true);
    }, []);

    const clickSubmitModalFiltros = useCallback((): void => {
        setLoadingSpinModal(true);

        const buscaAssocRegional = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocRegionalCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);

                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);

                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocEstadual = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocEstadualCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);

                setLoadingDadosSelectAssocList(false)

            } catch (error) {
                setLoadingDadosSelectAssocList(false);
                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        const buscaAssocFederal = async (idAssoc: number) => {
            setLoadingDadosSelectAssocList(true);
            try {
                let result = await axios.get(urlAssocFederalCidades + "/" + idAssoc,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                let estadosID: any = []
                result.data.forEach((vende: interfaceCidadeEstados) => {
                    if (estadosID.length === 0) {
                        estadosID.push(vende.estadoId)
                    } else {
                        let indice = estadosID.indexOf(vende.estadoId);
                        if (indice === -1) {
                            estadosID.push(vende.estadoId)
                        }
                    }
                });

                buscaCityAndSetTabelaParaFiltroAssoc(estadosID, result.data, dadosTabelaState);
                setLoadingDadosSelectAssocList(false);

            } catch (error) {
                setLoadingDadosSelectAssocList(false);

                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        modalFiltroAssocFormRef?.current?.validateFields()
            .then((values: any) => {

                switch (values.tipoAssociacao) {
                    case "Regional":
                        buscaAssocRegional(values.associacoesFiltro);
                        break;
                    case "Estadual":
                        buscaAssocEstadual(values.associacoesFiltro);
                        break;
                    case "Federal":
                        buscaAssocFederal(values.associacoesFiltro);
                        break;

                    default:
                        break;
                }

            })
            .catch((errorInfo: any) => {
                setLoadingSpinModal(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [dadosTabelaState, buscaCityAndSetTabelaParaFiltroAssoc]);

    const clickCancelModalFiltros = useCallback((): void => {
        setModalFiltrosVisible(false);
    }, []);

    const onClickRemoverTodasAsCidadesContratoTable = useCallback((): void => {

        setDadosTabelaState([]);

        refDadosCidades.current = [];
        setDadosCidades([]);

        refDadosTotaisCidades.current = [];
        setDadosTotaisCidades([]);

        props.formRef.current.setFieldsValue(
            { estadosCity: [] }
        );
        setIsHiddenCidades(false);

    }, [dadosTotaisCidades]);

    //ao selecionar um item das rotas remove do select e add na tabela
    const onChangeSelectCidade = useCallback((e: number): void => {

        if (dadosTabelaState.length == 0) {

            dadosTotaisCidades.map((item) => {
                if (item.id == e) {
                    setDadosTabelaState([item]);

                    let filter = dadosCidades.filter((val) => {
                        return val.id != item.id
                    })
                    refDadosCidades.current = filter
                    setDadosCidades(filter)
                }
            })
        } else {
            // let arrayAddTodos: any = [];

            // dadosTotaisCidades.map((item) => {
            //     if (item.id == e) {
            //         const obj: any = {};

            //         //Alimentado var obj
            //         obj["id"] = item.id
            //         obj["nome"] = item.nome
            //         obj["estadoId"] = item.estadoId
            //         obj["uf"] = item.uf
            //         obj["uuid"] = item.uuid

            //         arrayAddTodos.push(obj);

            //         let filter = dadosCidades.filter((val) => {
            //             return val.id != e
            //         })

            //         refDadosCidades.current = filter
            //         setDadosCidades(filter);
            //     }
            // })

            // dadosTabelaState.forEach((valor: dadosDaTabela | interfaceDadosCidades) => {
            //     arrayAddTodos.push(valor);
            // });

            // setDadosTabelaState(arrayAddTodos);

            let arrayAddTodos: any[] = [...dadosTabelaState];

            const cidadeSelecionada = dadosTotaisCidades.find(item => item.id === e);

            if (cidadeSelecionada) {
                const { id, nome, estadoId, uf, uuid } = cidadeSelecionada;
                arrayAddTodos.push({ id, nome, estadoId, uf, uuid });

                const filtroCidades = dadosCidades.filter(val => val.id !== e);
                refDadosCidades.current = filtroCidades;
                setDadosCidades(filtroCidades);
            }

            setDadosTabelaState(arrayAddTodos);
        }
        props.formRef.current.setFieldsValue({ "cidadesSelect": null });

        refFormCidadesSelect.current?.blur();
    }, [dadosTabelaState, dadosCidades, dadosTotaisCidades]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasCidadesInTable = useCallback(() => {

        if (dadosCidades.length != 0) {

            let arrayAddTodos: any = [];
            let tabelaISVazia = false;

            if (dadosTabelaState.length != 0) {
                tabelaISVazia = true;
            }

            //SALVAR DADOS
            dadosCidades.forEach((valor) => {

                const obj: any = {};

                //Alimentado var obj
                obj["id"] = valor.id
                obj["nome"] = valor.nome
                obj["estadoId"] = valor.estadoId
                obj["uf"] = valor.uf
                obj["uuid"] = valor.uuid

                arrayAddTodos.push(obj);
            });

            //se a tabela não é vazia
            if (tabelaISVazia) {
                dadosTabelaState.forEach((valor: interfaceDadosCidades) => {
                    arrayAddTodos.push(valor);
                });
            }

            setDadosTabelaState(arrayAddTodos);

            refDadosCidades.current = [];
            setDadosCidades([]);

            //Setando null em cidade
            props.formRef.current.setFieldsValue({ "cidadesSelect": null });
        }

    }, [dadosCidades, dadosTabelaState]);

    //seact da tabela
    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //seact da tabela
    const handleSearch2 = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText2(selectedKeys[0]);
        setSearchedColumn2(dataIndex);
    }, []);

    const handleReset2 = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText2('');
    }, []);

    const getColumnSearchProps2 = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset2(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex]?.nome ? record[dataIndex].nome.toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn2 === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText2]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText2, searchedColumn2]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ""
                if (record[dataIndex]) {
                    texto = convertDataUSParaBR(record[dataIndex])
                }
                let testeConvertido: any = texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : ''
                return (
                    testeConvertido
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn2 === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText2]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText2, searchedColumn2]);
    // fim do search da tabela de listagem

    const excluirDaTabela = useCallback((recorde: any): void => {

        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val != recorde
        })

        setDadosTabelaState(filter);

        //incluirndo o valor removido no select
        let arrayTabela = dadosCidades.concat(recorde);

        refDadosCidades.current = arrayTabela;
        setDadosCidades(arrayTabela);
    }, [dadosTabelaState, dadosCidades]);

    useEffect((): any => {
        props.funcSetValorTabela(dadosTabelaState)
    }, [dadosTabelaState]);

    //COLUNAS DA TABELA Aditivo prorrogacao
    const columnsAditivoProrrogacao = [
        {
            title: 'Vigencia',
            dataIndex: 'vigencia',
            ...getColumnSearchPropsData('vigencia'),
            width: '40%',
            render: (vigencia: string) => {
                let data: string | undefined = "";
                if (vigencia) {
                    data = convertDataUSParaBR(vigencia);
                }
                return (
                    data
                )
            },
        },
        {
            title: 'Situação',
            dataIndex: 'situacao_contrato',
            ...getColumnSearchProps2('situacao_contrato'),
            width: '67%',
            render: (val: any) => {
                let cor = '';
                switch (val?.nome) {
                    case 'Pago':
                        cor = 'green';
                        break;
                    case "Proposta Enviada":
                        cor = 'gold';
                        break;
                    case "Parceiros":
                        cor = 'red';
                        break;
                    case "Aguardando Emissão NF":
                        cor = 'blue';
                        break;
                    case "Aguardando Pagamento":
                        cor = 'purple';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {val?.nome}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Editar',
            dataIndex: 'editar',
            width: '33%',
            render: (text: any, record: any) =>
                dadosTabelaStateAditivoProrrogacao.length >= 1 ? (
                    <>
                        <Button className="EditarCidadesContratoSalvas" type="primary" onClick={() => editarDaTabelaAditivoProrrogacao(record)}>
                            <EditOutlined />
                        </Button>
                    </>
                ) : null,
        },
    ];


    //tabela luisgaem estado
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome', refFuncaoLimparFiltrosStringCidade),
        },
        {
            title: 'Estado',
            dataIndex: 'uf',
            ...useGetColumnSearchProps('uf', refFuncaoLimparFiltrosStringEstado),
            render: (text: any, record: any) => {
                if (text) {
                    return text
                } else {
                    return record.uf
                }
            }
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            width: '37%',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            if (estaEditandoUsuario) {
                                Modal.confirm({
                                    className: 'classmodalDadosContatoInatendimento',
                                    title: 'Atenção',
                                    icon: <ExclamationCircleOutlined />,
                                    content: `Todos usuários vinculados a está cidade deste contrato \n
                                 irão perder o acesso, deseja realmente excluir?`
                                    ,
                                    okText: 'Sim',
                                    okType: 'danger',
                                    cancelText: 'Cancelar',
                                    onOk() {
                                        excluirDaTabela(record)
                                    },
                                    onCancel() {
                                    },
                                });
                            } else {
                                excluirDaTabela(record)
                            }
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                ) : null,
        },
    ];

    return (
        <>
            <Spin spinning={loadingGlobal}>
                <Row>
                    {/* <Col {...selectedStatusOrgaoAdd}>
                        <Form.Item
                            name="status"
                            label="Status"
                            initialValue={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o status!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio.Button value={true}>Ativo</Radio.Button>
                                <Radio.Button value={false}>Inativo</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col> */}
                    <Col {...selectedStatusOrgaoAdd}>
                        <Row>
                            <Col {...selectedEsferaOrgaoAdd}>
                                <Form.Item
                                    name="status"
                                    label="Status"
                                    initialValue={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecione o status!',
                                        },
                                    ]}
                                >
                                    <Radio.Group disabled>
                                        <Radio.Button value={true}>Ativo</Radio.Button>
                                        <Radio.Button value={false}>Inativo</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col hidden={!estaEditandoUsuario} {...selectedPoderOrgaoAdd}>
                                <Form.Item
                                    name="data_criado"
                                    label="Contrato criado em"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor selecione o status!',
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col {...selectedInputPlanoContrato}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <Form.Item
                                        name="planoId"
                                        label="Selecione o Plano"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Obrigatório selecionar!",
                                            }
                                        ]}
                                        style={{
                                            flex: 1
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            loading={loadingPlanoList}
                                            notFoundContent={loadingPlanoList ? <Spin size="small" /> : null}
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            onChange={(value) => { setValuePlano(value) }}
                                        >
                                            {
                                                dadosPlanoList.map((item) => {
                                                    return (

                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >

                                                            {item.nome}

                                                        </Select.Option>

                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Tooltip title="Ver Descrição" color="blue" key="verdescricaoPlano">
                                        <Button
                                            onClick={() => {
                                                openModalDadosPlano()
                                            }}
                                            // className="botaoRemoverCidadesContratoAll"
                                            // danger
                                            size='middle'
                                            type="primary"
                                            style={{
                                                marginTop: 6
                                            }}
                                            disabled={typeof valuePlano !== 'number'}
                                        >
                                            <SearchOutlined />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...selectedEsferaOrgaoAdd}>
                        <Form.Item
                            name="esfera"
                            label="Esfera"
                            initialValue={7}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione a esfera!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                loading={loadingEsferaSelected}
                                notFoundContent={loadingEsferaSelected ? <Spin size="small" /> : null}
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    dadosEsferaList.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...selectedPoderOrgaoAdd}>
                        <Form.Item
                            name="poder"
                            label="Poder"
                            initialValue={2}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o poder!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                loading={loadingPoderSelected}
                                notFoundContent={loadingPoderSelected ? <Spin size="small" /> : null}
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {dadosPoderList.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.id}
                                            key={item.id}
                                        >
                                            {item.nome}
                                        </Select.Option>
                                    )
                                })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...selectedNomeOrgaoAdd}>
                        <Form.Item
                            name="nome"
                            label="Nome do contrato"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor digite o nome!',
                                },
                            ]}
                        >
                            <Input placeholder="Nome" />
                        </Form.Item>
                    </Col>
                    <Col {...selectedVendedorAddContrato}>
                        <Form.Item
                            name="vendedores"
                            label="Vendedor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o vendedor!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                mode="multiple"
                                loading={loadingVendedoresSelected}
                                notFoundContent={loadingVendedoresSelected ? <Spin size="small" /> : null}
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    dadosVendedoresList.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col {...selectedTipoContrato}>
                        <Form.Item
                            name="tipo_contrato"
                            label="Tipo de Contrato"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o tipo do contrato!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                loading={loadingTipoDeContratoSelected}
                                notFoundContent={loadingTipoDeContratoSelected ? <Spin size="small" /> : null}
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                onChange={onChangeTipoDeContrato}
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    dadosTipoDeContratoList.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...selectedSituacaoAddContrato}>
                        <Form.Item
                            name="situacao_contrato"
                            label="Situação"
                            rules={[
                                {
                                    required: !disabledSelectedSituacao,
                                    message: 'Por favor selecione uma situação!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                loading={loadingSituacaoSelected}
                                notFoundContent={loadingSituacaoSelected ? <Spin size="small" /> : null}
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                disabled={disabledSelectedSituacao}
                                onChange={onChangeSituacaoContrato}
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    dadosSituacaoList.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* Caso tenha validade de teste, modalidade com uma coluna, se não modalidade com outro tipo de coluna */}
                    {requiredInSelectedValidadeDeTeste ?
                        <Col {...selectedModalidadeAddContrato}>
                            <Form.Item
                                name="modalidade"
                                label="Modalidade"
                                rules={[
                                    {
                                        required: !disabledSelectedModalidade,
                                        message: 'Por favor selecione a modalidade!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecione..."
                                    showSearch
                                    optionFilterProp="children"
                                    disabled={disabledSelectedModalidade}
                                    loading={loadingModalidadeSelected}
                                    notFoundContent={loadingModalidadeSelected ? <Spin size="small" /> : null}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosModalidadeList.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        :
                        <Col {...selectedModalidadeAddContratoComValidadeDeTeste}>
                            <Form.Item
                                name="modalidade"
                                label="Modalidade"
                                rules={[
                                    {
                                        required: !disabledSelectedModalidade,
                                        message: 'Por favor selecione a modalidade!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecione..."
                                    showSearch
                                    optionFilterProp="children"
                                    disabled={disabledSelectedModalidade}
                                    loading={loadingModalidadeSelected}
                                    notFoundContent={loadingModalidadeSelected ? <Spin size="small" /> : null}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosModalidadeList.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }

                    <Col hidden={!requiredInSelectedValidadeDeTeste} {...selectedDataValidadeDeTeste}>
                        <Form.Item
                            name="validade_teste"
                            label="Validade de Teste"
                            rules={[
                                {
                                    required: requiredInSelectedValidadeDeTeste,
                                    // required: false,
                                    message: "Por favor digite a data",
                                },
                                // () => ({
                                //     validator(rule, value) {
                                //         if (value != '' && value != null) {
                                //             if (value.length < 10) {
                                //                 return Promise.reject('Data Inválida');
                                //             } else {
                                //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                //                     return Promise.resolve();
                                //                 } else {
                                //                     return Promise.reject('Data Inválida');
                                //                 }
                                //             }

                                //         } else {
                                //             // if (false) {
                                //             if (requiredInSelectedValidadeDeTeste) {
                                //                 return Promise.reject('');
                                //             } else {
                                //                 return Promise.resolve();
                                //             }
                                //         }
                                //     },
                                // }),
                            ]}
                        >
                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                <DatePicker
                                    value={selectedDateValidadeDeTeste}
                                    onChange={setSelectedDateValidadeDeTeste}
                                    required={false}
                                    style={{
                                        width: '100%'
                                    }}
                                    okLabel="Ok"
                                    clearLabel="Limpar"
                                    cancelLabel="Cancelar"
                                    clearable
                                    format="dd/MM/yyyy"
                                    className="datePickerCOlors"

                                />
                            </MuiPickersUtilsProvider>
                            {/* <Input
                                disabled={!requiredInSelectedValidadeDeTeste}
                                onChange={(e) => onChangeDataValidadeDeTeste(e)}
                                placeholder="31/12/2000"
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col {...selectedDataEnvioDaProposta}>
                        <Form.Item
                            name="envio_proposta"
                            label="Envio da Proposta"
                            rules={[
                                {
                                    required: requiredInSelectedEnvioDaProposta,
                                    // required: false,
                                    message: "Por favor digite a data",
                                },
                                // () => ({
                                //     validator(rule, value) {
                                //         if (value != '' && value != null) {
                                //             if (value.length < 10) {
                                //                 return Promise.reject('Data Inválida');
                                //             } else {
                                //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                //                     return Promise.resolve();
                                //                 } else {
                                //                     return Promise.reject('Data Inválida');
                                //                 }
                                //             }

                                //         } else {
                                //             // if (false) {
                                //             if (requiredInSelectedEnvioDaProposta) {
                                //                 return Promise.reject('');
                                //             } else {
                                //                 return Promise.resolve();
                                //             }
                                //         }
                                //     },
                                // }),
                            ]}
                        >
                            {/* <Input
                                disabled={disabledSelectedEnvioDaProposta}
                                onChange={(e) => onChangeDataEnvioProposta(e)}
                                placeholder="31/12/2000"
                            /> */}
                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                <DatePicker
                                    value={selectedDateEnvioDaProposta}
                                    onChange={setSelectedDateEnvioDaProposta}
                                    required={false}
                                    disabled={disabledSelectedEnvioDaProposta}
                                    style={{
                                        width: '100%'
                                    }}
                                    okLabel="Ok"
                                    clearLabel="Limpar"
                                    cancelLabel="Cancelar"
                                    clearable
                                    format="dd/MM/yyyy"
                                    className="datePickerCOlors"

                                />
                            </MuiPickersUtilsProvider>
                        </Form.Item>
                    </Col>
                    <Col {...selectedVigenciaAddContrato}>
                        <Form.Item
                            name="vigencia"
                            label="Primeira Vigência"
                            rules={[
                                {
                                    required: requiredSelectedVigencia,
                                    // required: false,
                                    message: "Por favor digite a data",
                                },
                                // () => ({
                                //     validator(rule, value) {
                                //         if (value != '' && value != null) {
                                //             if (value.length < 10) {
                                //                 return Promise.reject('Data Inválida');
                                //             } else {
                                //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                //                     return Promise.resolve();
                                //                 } else {
                                //                     return Promise.reject('Data Inválida');
                                //                 }
                                //             }

                                //         } else {
                                //             // if (false) {
                                //             if (requiredSelectedVigencia) {
                                //                 return Promise.reject('');
                                //             } else {
                                //                 return Promise.resolve();
                                //             }
                                //         }
                                //     },
                                // }),
                            ]}
                        >
                            {/* <Input
                                disabled={disabledSelectedVigencia}
                                onChange={(e) => onChangeDataVigencia(e)}
                                placeholder="31/12/2000"
                            /> */}
                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                <DatePicker
                                    value={selectedDateVigencia}
                                    onChange={setSelectedDateVigencia}
                                    required={false}
                                    disabled={disabledSelectedVigencia}
                                    style={{
                                        width: '100%'
                                    }}
                                    okLabel="Ok"
                                    clearLabel="Limpar"
                                    cancelLabel="Cancelar"
                                    clearable
                                    format="dd/MM/yyyy"
                                    className="datePickerCOlors"

                                />
                            </MuiPickersUtilsProvider>
                        </Form.Item>
                    </Col>
                    {estaEditandoUsuario &&
                        <Col {...selectedVigencialAtual}>
                            <Form.Item
                                name="vigencia_atual"
                                label="Vigência Atual"
                                rules={[
                                    {
                                        // required: !disabledSelectedVigencia,
                                        required: false,
                                        message: "Por favor digite a data",
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            if (value != '' && value != null) {
                                                if (value.length < 10) {
                                                    return Promise.reject('Data Inválida');
                                                } else {
                                                    if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject('Data Inválida');
                                                    }
                                                }

                                            } else {
                                                // if (!disabledSelectedVigencia) {
                                                if (false) {
                                                    return Promise.reject('');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    disabled
                                // onChange={(e) => onChangeDataVigencia(e)}
                                // placeholder="31/12/2000"
                                />
                            </Form.Item>
                        </Col>
                    }

                    {estaEditandoUsuario ?
                        <Col {...inputValorContratadoEstaEditando}>
                            <Form.Item
                                name="valor_contratado"
                                label="Valor Contratado (R$)"
                                rules={[
                                    {
                                        // required: !disabledSelectedValorContratado,
                                        required: false,
                                        message: "Por favor digite a valor",
                                    },
                                ]}
                            >
                                <Input
                                    disabled={disabledSelectedValorContratado}
                                    onChange={(e) => onChangeMoney(e)}
                                    placeholder="0,00"
                                    maxLength={21}
                                />
                            </Form.Item>
                        </Col>
                        :
                        <Col {...inputValorContratado}>
                            <Form.Item
                                name="valor_contratado"
                                label="Valor Contratado (R$)"
                                rules={[
                                    {
                                        // required: !disabledSelectedValorContratado,
                                        required: false,
                                        message: "Por favor digite a valor",
                                    },
                                ]}
                            >
                                <Input
                                    disabled={disabledSelectedValorContratado}
                                    onChange={(e) => onChangeMoney(e)}
                                    placeholder="0,00"
                                    maxLength={21}
                                />
                            </Form.Item>
                        </Col>
                    }

                    <Divider className="dividerPermisaoDoApp" />
                </Row>
                <Row>
                    {isHiddenBotaoAddProrrogacao &&
                        valueTipoDeContrato === "Cliente" ||
                        isHiddenBotaoAddProrrogacao &&
                        valueTipoDeContrato === "Associação" ?
                        <>
                            <Col span={24}>
                                <Button
                                    disabled={isAddInputsAditivoProrrogacao}
                                    style={{ marginBottom: 20 }}
                                    type="primary"
                                    onClick={onClickAditivoProrrogacaoAdicionar}
                                >Aditivo/Prorrogação</Button>
                            </Col>
                            {isAddInputsAditivoProrrogacao ?
                                <>
                                    <Col {...colAditivoProrrogacaoInputs}>
                                        <Spin spinning={loadingAditivo}>
                                            <Row>
                                                <Col {...selectedSituacaoAddContratoTable}>
                                                    <Form.Item
                                                        name="situacao_contrato_table"
                                                        label="Situação"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Por favor selecione uma situação!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            loading={loadingSituacaoSelected}
                                                            notFoundContent={loadingSituacaoSelected ? <Spin size="small" /> : null}
                                                            optionFilterProp="children"
                                                            placeholder="Selecione..."
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                        >
                                                            {
                                                                dadosSituacaoList.map((item) => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={item.id}
                                                                            key={item.id}
                                                                        >
                                                                            {item.nome}
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col {...selectedModalidadeAddContratoTable}>
                                                    <Form.Item
                                                        name="tipo_aditivo"
                                                        label="Tipo de Aditivo"
                                                        // antiga modalidade
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Por favor selecione a modalidade!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Selecione..."
                                                            showSearch
                                                            optionFilterProp="children"
                                                            loading={loadingTipoAditivoSelected}
                                                            notFoundContent={loadingTipoAditivoSelected ? <Spin size="small" /> : null}
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                        >
                                                            {
                                                                dadosTipoAditivoList.map((item) => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={item.id}
                                                                            key={item.id}
                                                                        >
                                                                            {item.nome}
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col {...selectedDataEnvioDaPropostaTable}>
                                                    <Form.Item
                                                        name="envio_proposta_table"
                                                        label="Envio da Proposta"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Por favor digite a data",
                                                            },
                                                            // () => ({
                                                            //     validator(rule, value) {
                                                            //         if (value != '' && value != null) {
                                                            //             if (value.length < 10) {
                                                            //                 return Promise.reject('Data Inválida');
                                                            //             } else {
                                                            //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                            //                     return Promise.resolve();
                                                            //                 } else {
                                                            //                     return Promise.reject('Data Inválida');
                                                            //                 }
                                                            //             }

                                                            //         } else {
                                                            //             return Promise.resolve();
                                                            //         }
                                                            //     },
                                                            // }),
                                                        ]}
                                                    >
                                                        {/* <Input
                                                        onChange={(e) => onChangeDataEnvioPropostaTable(e)}
                                                        placeholder="31/12/2000"
                                                    /> */}
                                                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                            <DatePicker
                                                                value={selectedDateEnvioPropostaTable}
                                                                onChange={setSelectedDateEnvioPropostaTable}
                                                                required={false}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                                okLabel="Ok"
                                                                clearLabel="Limpar"
                                                                cancelLabel="Cancelar"
                                                                clearable
                                                                format="dd/MM/yyyy"
                                                                className="datePickerCOlors"

                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Form.Item>
                                                </Col>
                                                <Col {...selectedVigenciaAddContratoTable}>
                                                    <Form.Item
                                                        name="vigencia_table"
                                                        label="Vigência"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Por favor digite a data",
                                                            },
                                                            // () => ({
                                                            //     validator(rule, value) {
                                                            //         if (value != '' && value != null) {
                                                            //             if (value.length < 10) {
                                                            //                 return Promise.reject('Data Inválida');
                                                            //             } else {
                                                            //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                            //                     return Promise.resolve();
                                                            //                 } else {
                                                            //                     return Promise.reject('Data Inválida');
                                                            //                 }
                                                            //             }

                                                            //         } else {
                                                            //             return Promise.resolve();
                                                            //         }
                                                            //     },
                                                            // }),
                                                        ]}
                                                    >
                                                        {/* <Input
                                                        onChange={(e) => onChangeDataVigenciaTable(e)}
                                                        placeholder="31/12/2000"
                                                    /> */}
                                                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                            <DatePicker
                                                                value={selectedDateVigenciaTable}
                                                                onChange={setSelectedDateVigenciaTable}
                                                                required={false}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                                okLabel="Ok"
                                                                clearLabel="Limpar"
                                                                cancelLabel="Cancelar"
                                                                clearable
                                                                format="dd/MM/yyyy"
                                                                className="datePickerCOlors"

                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Form.Item>
                                                </Col>
                                                <Col {...inputValorContratadoTable}>
                                                    <Form.Item
                                                        name="valor_contratado_table"
                                                        label="Valor Contratado (R$)"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Por favor digite a valor",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => onChangeMoneyTable(e)}
                                                            placeholder="0,00"
                                                            maxLength={21}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col {...selectedVigenciaAddContratoTable}>
                                                    <Form.Item
                                                        name="observacao_table"
                                                        label="Observações"
                                                        rules={[
                                                        ]}
                                                    >
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                </Col>
                                                <Col {...salvarAditivoProrrogacaoInTable}>
                                                    <Button disabled={!isEditando}
                                                        onClick={onClickCancelarCidadesContrato}
                                                        style={{ marginTop: 30, marginBottom: 20, marginRight: 10 }}>
                                                        Cancelar</Button>
                                                    <Button
                                                        style={{ marginTop: 30, marginBottom: 20 }}
                                                        type="primary"
                                                        disabled={loadingAditivo}
                                                        onClick={onClickSalvarAditivoProrrogacaoTable}
                                                    >{isEditando ? "Atualizar" : "Salvar"}</Button>
                                                </Col>
                                            </Row>
                                        </Spin>
                                    </Col>

                                    <Col {...cardLayout}>
                                        <Table
                                            style={{ marginTop: 6 }}//ou 25
                                            className="tabelaAssociacaoAdd"
                                            rowKey={(record) => record.uuid}
                                            size="small"
                                            columns={columnsAditivoProrrogacao}
                                            dataSource={dadosTabelaStateAditivoProrrogacao}
                                            scroll={{ y: 150 }}
                                            pagination={{ locale: { items_per_page: '' } }}
                                        />
                                    </Col>
                                </> : ""}
                            <Divider className="dividerPermisaoDoApp" />
                        </>
                        : ""}
                </Row>
                <Row>
                    <Col {...rowColNomeAndTipo}>
                        <Row>
                            <Col {...inputSelectTipoDaAssociacao}>
                                <Form.Item
                                    name="estados"
                                    label="Modo Estado (App)"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor selecione um estado!',
                                        },
                                    ]}
                                >
                                    <Select
                                        ref={refFormModoEstadoSelect}
                                        loading={loadingEstadosAndCitySelected}
                                        notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        onDeselect={(id: any, obj: any) => {
                                            if (estaEditandoUsuario) {
                                                Modal.confirm({
                                                    className: 'classmodalDadosContatoInatendimento',
                                                    title: 'Atenção',
                                                    icon: <ExclamationCircleOutlined />,
                                                    content: `Todos usuários vinculados a este estado deste contrato
                                                 irão perder o acesso, deseja realmente excluir?`
                                                    ,
                                                    okText: 'Sim',
                                                    okType: 'danger',
                                                    cancelText: 'Cancelar',
                                                    onOk() {
                                                        // ja exclui altumatico
                                                    },
                                                    onCancel() {
                                                        let todos = [...props.formRef.current.getFieldValue('estados')]

                                                        todos?.push(id);

                                                        props.formRef.current.setFieldsValue(
                                                            {
                                                                estados: todos,
                                                            }
                                                        );

                                                    },
                                                });
                                            }
                                        }}
                                        mode="multiple"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        dropdownRender={menu => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoModoEstado}
                                                        block
                                                        onClick={onClickAddTodosEstadosGov}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            dadosEstadosAndCityList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isFiltroAssoc ?
                                <Col {...inputSelectTipoDaAssociacao}>
                                    <Button
                                        onClick={onClickAtivarModalFiltros}
                                        type="primary"
                                        className="botaoFiltrosAssoc"
                                        style={{ marginBottom: 10 }}
                                    >
                                        Seleção de Cidades por Associação
                                    </Button>
                                </Col>
                                : ""}
                        </Row>
                        <Modal
                            title="Seleção de Cidades por Associação"
                            className="modalFiltros"
                            visible={modalFiltrosVisible}
                            onOk={clickSubmitModalFiltros}
                            onCancel={clickCancelModalFiltros}
                            footer={[
                                <Button key="back" loading={loadingSpinModal} onClick={clickCancelModalFiltros}>
                                    Cancelar
                                </Button>,
                                <Button key="submit" loading={loadingSpinModal} type="primary" onClick={clickSubmitModalFiltros}>
                                    Inserir
                                </Button>,
                            ]}
                        >
                            <Spin spinning={loadingSpinModal}>
                                <Form
                                    name="formModalFiltros"
                                    layout="vertical"
                                    ref={modalFiltroAssocFormRef}
                                    initialValues={{
                                        remember: true,
                                    }}
                                >
                                    <Row>
                                        <Col {...tipoAssocModal}>
                                            <Form.Item
                                                name="tipoAssociacao"
                                                label="Tipo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o tipo Associação!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    onChange={onchangeTipoAssocModal}
                                                    placeholder="Selecione..."
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    <Select.Option
                                                        value="Regional"
                                                        key="1"
                                                    >
                                                        Regional
                                                    </Select.Option>
                                                    <Select.Option
                                                        value="Estadual"
                                                        key="2"
                                                    >
                                                        Estadual
                                                    </Select.Option>
                                                    <Select.Option
                                                        value="Federal"
                                                        key="3"
                                                    >
                                                        Federal
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...estadosAssocModal}>
                                            <Form.Item
                                                name="estadosAssoc"
                                                label="Estados (Filtro)"
                                                rules={[
                                                    {
                                                        required: !disabledEstadosAssocModal,
                                                        message: 'Por favor selecione o estado!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled={disabledEstadosAssocModal}
                                                    loading={loadingEstadosAndCitySelected}
                                                    notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                                    placeholder="Selecione..."
                                                    onChange={onChangeFiltroEstadosAssoc}
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dadosEstadosAndCityList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...cidadesAssocModal}>
                                            <Form.Item
                                                name="associacoesFiltro"
                                                label="Associação (Filtro)"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a associação!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    loading={loadingDadosSelectAssocList}
                                                    notFoundContent={loadingDadosSelectAssocList ? <Spin size="small" /> : null}
                                                    placeholder="Selecione..."
                                                    optionFilterProp="children"
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dadosSelectAssocList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </Modal>
                        <Row>
                            <Col {...inputSelectEstadosCity}>
                                <Form.Item
                                    name="estadosCity"
                                    label="Estados"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Por favor selecione um estado!",
                                        },
                                    ]}
                                >
                                    <Select
                                        ref={refFormEstadoCitySelect}
                                        showSearch
                                        loading={loadingEstadosAndCitySelected}
                                        notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        mode="multiple"
                                        onDeselect={onDeselectEstadosAndCity}
                                        onSelect={onSelectEstadosAndCity}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        dropdownRender={menu => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button
                                                        ref={refBotaoEstadoCity}
                                                        block onClick={onClickAddTodosEstadosCidades}
                                                        type="dashed"
                                                    >Todos</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            dadosEstadosAndCityList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isHiddenCidades ?
                                <Col {...inputSelectCidades}>
                                    <Form.Item
                                        name="cidadesSelect"
                                        // label="Modo Cidade (App)"
                                        label="Municípios"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Por favor selecione uma cidade!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={onChangeSelectCidade}
                                            ref={refFormCidadesSelect}
                                            showSearch
                                            loading={loadingCidadesSelected}
                                            notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                            placeholder="Selecione..."
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            dropdownRender={menu => (
                                                <div>

                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button block onClick={onClickAddTodasCidadesInTable} type="dashed">Todos</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dadosCidades.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome + " - " + item.uf}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : ""}
                        </Row>
                    </Col>
                    <Col {...cardLayout}>
                        <Text>Municípios</Text>
                        <Button
                            onClick={() => {
                                if (estaEditandoUsuario) {
                                    Modal.confirm({
                                        className: 'classmodalDadosContatoInatendimento',
                                        title: 'Atenção',
                                        icon: <ExclamationCircleOutlined />,
                                        content: `Todos usuários vinculados as cidade deste contrato \n
                                     irão perder o acesso, deseja realmente excluir?`
                                        ,
                                        okText: 'Sim',
                                        okType: 'danger',
                                        cancelText: 'Cancelar',
                                        onOk() {
                                            onClickRemoverTodasAsCidadesContratoTable()
                                        },
                                        onCancel() {
                                        },
                                    });
                                } else {
                                    onClickRemoverTodasAsCidadesContratoTable()
                                }
                            }}
                            hidden={dadosTabelaState.length != 0 ? false : true}
                            className="botaoRemoverCidadesContratoAll"
                            danger
                            type="primary">
                            Remover Todos
                        </Button>
                        <Table
                            rowKey={(record) => record.uuid}
                            loading={loadingTableCidades}
                            style={{ marginTop: 6 }}
                            className="tabelaAssociacaoAdd"
                            size="small"
                            columns={columns} dataSource={dadosTabelaState}
                            scroll={{ y: 200 }}
                            pagination={{ locale: { items_per_page: '' } }}
                        />
                    </Col>
                </Row>
                <Divider className="dividerPermisaoDoApp" />
                <Row>
                    <Col {...inputObservaçoesInAddContrato}>
                        <Form.Item
                            name="observacao"
                            label="Observações"
                            rules={[
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Spin >
            <Modal
                title="Dados do Plano"
                visible={isModalDadosDoPlanoOpen}
                onOk={() => { setIsModalDadosDoPlanoOpen(false) }}
                footer={[
                    <></>
                    // <Button key="back" loading={loadingSpinModal} onClick={clickCancelModalFiltros}>
                    //     Cancelar
                    // </Button>
                    ,
                    <Button
                        type="primary"
                        onClick={() => { setIsModalDadosDoPlanoOpen(false) }}
                    >
                        OK
                    </Button>,
                ]}
            // onCancel={() => { setIsModalDadosDoPlanoOpen(false) }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Nome:</p>
                    <p>{refVIEWDadosPlanoSelect.current?.nome}</p>
                </div>
                {/* <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Descrição:</p>
                    <p>{refVIEWDadosPlanoSelect.current?.descricao}</p>
                </div> */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <p style={{ marginRight: 5 }}>Valor:</p>
                    <p>{convertMoedaUSParaBR(refVIEWDadosPlanoSelect.current?.valor)}</p>
                </div>
                <Divider className="dividerPermisaoDoApp" style={{ margin: '5px 0px' }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p style={{ marginRight: 5 }}>Modulos:</p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.modulos.map((value) => {
                            return (
                                <span key={value.uuid}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>

                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10
                    }}
                >
                    <p style={{ marginRight: 5 }}>Recursos:</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}
                    >
                        {refVIEWDadosPlanoSelect.current?.recursos.map((value) => {
                            return (
                                <span key={value.uuid}>
                                    <Tag >
                                        {value.descricao}
                                    </Tag>
                                </span>
                            )
                        })}
                    </div>
                </div>

            </Modal>
        </>
    );
}

export default FormCriarContrato;
