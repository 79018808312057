import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Button, Form, notification, Typography, Spin, Row, Col, Breadcrumb, Divider, Select, Modal,
    Input, Radio, Table, Space, Tag, Popconfirm, Tooltip, Switch
} from 'antd';
import Highlighter from 'react-highlight-words';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import MomentUtils from '@date-io/moment';
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import Icon, {
    SolutionOutlined,
    SearchOutlined, ExclamationCircleOutlined, EditOutlined, TeamOutlined} from '@ant-design/icons';
import {
    cardLayout,
    botaoSalvar,
    selectedVinteQuatro,
    selectedCidade,
    selectedPoder,
    campoValidadeDoTesteCol,
    modalAddModuloInputs,
    modaisDeConfig,
    colMeioIni,
    colMeioMeio,
    colModalVincularUsuarioInputs,
    colModalVincularUsuarioInputs2,
    inputAtendViewMeioIni
} from './GridStyle';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
// import locale from 'antd/es/date-picker/locale/pt_BR';
import axios from 'axios';
import { format, addDays } from 'date-fns'
import {
    urlContratoVendedores, urlPoder, urlBuscaCidades, urlAtendimentosAtendimento, urlBuscaUserByCelular,
    urlAssociacaoAll, urlContratosAutomatico,
    urlCargo, urlUsersAutomaticoAtendimento, urlContratosAtendimentos, urlContratoUsuarios, urlAtendimentosAtendimentoVerificaExiste,
    urlContratoEstados, urlContrato, urlAtendimentoIdTelefone, urlCidadesContrato,
    urlContratosPlano,
    urlAtendimentoIdContato,
    urlAtendimentoIdContrato
} from '../../../../services/request/urls';
import {
    convertDataBrParaUS, convertDataEHoraBrParaUS, convertDataUSParaBR, convertMoedaUSParaBRInicioSemDigitos
} from './../../../../services/Conversores';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';
import { getToken } from '../../../../services/token';

import { mask, unMask } from '../../../../utils/MascaraDeCampos';

import { FaSearch, FaPlus, FaFileSignature, FaWhatsapp } from 'react-icons/fa';
import { ImFloppyDisk } from 'react-icons/im';
const IconSerach = (props: any) => { return <Icon {...props} component={FaSearch} ></Icon> }
const IconMais = (props: any) => { return <Icon {...props} component={FaPlus} ></Icon> }
const IconClipboard = (props: any) => { return <Icon {...props} component={FaFileSignature} ></Icon> }
const IconFloppyDisk = (props: any) => { return <Icon {...props} component={ImFloppyDisk} ></Icon> }
const IconWhatsApp = (props: any) => <Icon {...props} component={FaWhatsapp} ></Icon>

const { Title, Text } = Typography;

interface DadosCidadesAll {
    "id": number,
    "uuid": string,
    "nome": string,
    "microrregiao": {
        "mesorregiao": {
            "estado": {
                "id": number,
                "uuid": string,
                "sigla": string,
                "nome": string
            }
        }
    }
}
interface DadosAssociacaoAll {
    id: number,
    nome: string,
    tipo: string,
    uuid: string
}

interface DadosContatoEdit {
    atendimentoId: number
    data: string
    data_proximo_contato: string
    vendedorId: string
    id: number
    importante: boolean
    nome: string
    observacao: string
    status: string
    tipo: string
    telefone: string
    uuid: string
    vendedor: { nome: string }
}

interface DadosUrlAtendimentoEContato {
    associacaoId: number | null,
    cidadeId: number | null,
    contatos: Array<DadosContatoEdit>,
    contrato: { nome: string, validade_teste: string | null, tipo_contrato: { id: number, nome: string } },
    contratoId: number,
    data: string,
    data_proximo_contato: string,
    id: number,
    nome: string,
    poderId: number,
    status: string,
    uuid: string,
    vendedorId: number,
    telefone_principal: string | null,
    cidade: { populacao: number }
}

interface interfaceConfigs {
    nome: string,
    id: number
}

interface interfaceNewConfigs {
    nome: string,
    uuid: number
}

interface interfaceConfigsListContrato {
    nome: string,
    id: number,
    poder: {
        nome: string
    }
}

interface dadosUsuariosTabelaModal {
    id: number,
    nome: string,
    ativo: boolean,
    uuid: string,
    validade_teste: string,
    celular: string,
}

const AddOrEditAtendimentos: React.FC = (props, parans) => {

    const { idParam }: any = useParams();

    const refAllDados = useRef<null | DadosUrlAtendimentoEContato>(null);
    const refNomeCidadeParaModalContato = useRef<any>(null);
    const refIdPoderParaModalContato = useRef<any>(2);

    const refContratoSituacao = useRef<{ nome: string, id: number } | null>(null);

    const refClickModal = useRef<boolean>(true);
    const formRef = useRef<any>(null);
    const refFormModal = useRef<any>(null);
    const refFormModalCONTRATO = useRef<any>(null);
    const refFormModalVincularUsuario = useRef<any>(null);
    const history = useHistory();
    const inputNomeContato = useRef<any>(null);
    const inputNomeModalVinUser = useRef<any>(null);
    const idAtendimento = useRef<any>(null);
    const refInitialValueEditIdContato = useRef<undefined | string | number>(undefined);

    const [loadingFormAdd, setLoadingFormAdd] = useState(false);

    const [dadosCidadesList, setDadosCidadesList] = useState<Array<DadosCidadesAll>>([]);
    const [loadingSelectedCidades, setLoadingSelectedCidades] = useState(false);
    const [dadosAssocList, setDadosAssocList] = useState<Array<DadosAssociacaoAll>>([]);
    const [loadingSelectedAssoc, setLoadingSelectedAssoc] = useState(false);
    const [modalAddContato, setModalAddContato] = useState(false);
    const [modalAddCONTRATO, setModalAddCONTRATO] = useState(false);
    const [modalVincularUsuario, setModalVincularUsuario] = useState(false);
    const [modalListUserVinculados, setModalListUserVinculados] = useState(false);
    const [dadosTabelaListUserVinculados, setDadosTabelaListUserVinculados] = useState<Array<dadosUsuariosTabelaModal>>([]);

    const [modalViewDadosContato, setModalViewDadosContato] = useState(false);

    const [dadosDoCargoList, setDadosDoCargoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingCargoListSelected, setLoadingCargoListSelected] = useState(false);
    const refDadosCargoList = React.useRef<Array<interfaceConfigs>>([]);

    const [dadosVendedoresList, setDadosVendedoresList] = useState<Array<interfaceConfigs>>([]);
    const [loadingVendedoresSelected, setLoadingVendedoresSelected] = useState(false);
    const [loadingListContrato, setLoadingListContrato] = useState(false);
    const [dataListContrato, setDataListContrato] = useState<Array<interfaceConfigsListContrato>>([]);

    const [dadosPoderList, setDadosPoderList] = useState<Array<interfaceConfigs>>([]);
    const [loadingPoderSelected, setLoadingPoderSelected] = useState(false);
    const refDadosPoderList = React.useRef<Array<interfaceConfigs>>([]);

    const [loadingPlanoSelected, setLoadingPlanoSelected] = useState(false);
    const [dadosPlanoList, setDadosPlanoList] = useState<Array<interfaceNewConfigs>>([]);
    const refDadosPlanoList = useRef<Array<interfaceNewConfigs>>([]);

    const [selectedRowKeysTable, setSelectedRowKeysTable] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaContato, setDadosTabelaContato] = useState<Array<DadosContatoEdit>>([]);
    const [loadingTable, setLoadingTable] = useState(false);

    // const [initialValueEditIdContato, setInitialValueEditIdContato] = useState<undefined | string | number>(undefined);

    const [valorOnChangeCity, setValorOnChangeCity] = useState('cidade');
    const [valueTagStatus, setValueTagStatus] = useState('');

    const [dadosViewDadosContatoInModal, setDadosViewDadosContatoInModal] = useState<any>(undefined);

    const [selectedDate, handleDateChange] = useState<any>(null);
    const [selectedDateVincularUser, setSelectedDateVincularUser] = useState<any>(null);
    const [selectedDateVincularUserProximoContato, setSelectedDateVincularUserProximoContato] = useState<any>(null);

    const [stateValueStatusModalContato, setStateValueStatusModalContato] = useState<string | null>(null);
    const [stateValueTipoModalContato, setStateValueTipoModalContato] = useState<string | null>(null);


    const [switchImportantChecked, setSwitchImportantChecked] = useState(false);

    const [isVisibleDateProximoContato, setIsVisibleDateProximoContato] = useState(true);

    const [id, setId] = useState(undefined);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);


    const encontrarCargoPorId = useCallback((id): any => {

        const recebeFilter = refDadosCargoList.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0]

    }, []);

    const encontrarPoderPorId = useCallback((id): any => {

        const recebeFilter = refDadosPoderList.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0]

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosVendedoresList = useCallback(() => {

        setLoadingVendedoresSelected(true);
        const buscaDadosVendedores = async () => {
            try {
                let result = await axios.get(urlContratoVendedores,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosVendedoresList(result.data);

                setLoadingVendedoresSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingVendedoresSelected(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar vendedores!',
                    });
                }
            }
        }

        buscaDadosVendedores();

    }, []);

    const buscaDadosContratosAndSetSelected = useCallback((): void => {

        setLoadingListContrato(true);

        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlContratosAtendimentos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                setDataListContrato(result.data);

                setLoadingListContrato(false);

            } catch (error) {

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingListContrato(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosCidadesList = useCallback(() => {

        setLoadingSelectedCidades(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlBuscaCidades,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosCidadesList(result.data)

                setLoadingSelectedCidades(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedCidades(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosAssociacaoList = useCallback(() => {

        setLoadingSelectedAssoc(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlAssociacaoAll,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosAssocList(result.data)

                setLoadingSelectedAssoc(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedAssoc(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);


    const buscarDadosPlanoList = useCallback((setValue?: string) => {

        setLoadingPlanoSelected(true);
        const requestAsync = async () => {
            try {
                let result = await axios.get(urlContratosPlano,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refDadosPlanoList.current = result.data;
                setDadosPlanoList(result.data)

                if (setValue) {

                    const plano = result.data.filter((plano: any) => plano?.nome == setValue)

                    if (plano.length > 0) {
                        refFormModalVincularUsuario.current?.setFieldsValue({
                            plano_id: plano[0].id,
                        });
                    }
                } else {
                    if (result.data.length > 0) {
                        refFormModalVincularUsuario.current?.setFieldsValue({
                            plano_id: result.data[0].id,
                        });
                    }
                }

                setLoadingPlanoSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingPlanoSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        requestAsync();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const encontrarNomePoderPeloID = useCallback((id?: number | string | null) => {
        let resultadoPoder = dadosPoderList.filter((poder) => poder.id == id)
        if (resultadoPoder.length > 0) {
            return resultadoPoder[0].nome
        }
        return ''
    }, [dadosPoderList]);
    const buscarDadosPoderList = useCallback(() => {

        setLoadingPoderSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlPoder,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosPoderList(result.data);
                refDadosPoderList.current = result.data

                setLoadingPoderSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingPoderSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosCargoList = useCallback(() => {

        setLoadingCargoListSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlCargo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosDoCargoList(result.data);
                refDadosCargoList.current = result.data


                setLoadingCargoListSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingCargoListSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarAtendimentoEContatoESetCampos = useCallback((id: number | string) => {

        setLoadingFormAdd(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlAtendimentosAtendimento + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                const {
                    poderId, cidadeId, associacaoId, contatos, nome, status, contrato, telefone_principal, cidade
                }: DadosUrlAtendimentoEContato = result.data

                refAllDados.current = result.data

                refNomeCidadeParaModalContato.current = nome
                refIdPoderParaModalContato.current = poderId

                refContratoSituacao.current = contrato?.tipo_contrato

                if (cidadeId) {
                    setValorOnChangeCity('cidade')
                } else { setValorOnChangeCity('associacao') }

                formRef.current.setFieldsValue(
                    {
                        cidade: cidadeId,
                        associacao: associacaoId,
                        poder_id: poderId,
                        contratoMostrar: contrato?.nome,
                        status: status,
                        validadeDeteste: contrato?.validade_teste ? convertDataUSParaBR(contrato.validade_teste) : undefined,
                        populacao: cidade?.populacao ? convertMoedaUSParaBRInicioSemDigitos(cidade?.populacao.toString()) : undefined,
                    }
                );

                if (telefone_principal) {

                    const originalValue = unMask(telefone_principal);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    formRef.current?.setFieldsValue({
                        telefone_principal: maskedValue
                    })

                }

                setValueTagStatus(status);

                setDadosTabelaContato(contatos)

                setLoadingFormAdd(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingFormAdd(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    const comonentDidMount = useEffect((): void => {

        if (idParam) {
            buscarAtendimentoEContatoESetCampos(idParam);
            idAtendimento.current = idParam
            setId(idParam)
        }

        buscarDadosCidadesList();
        buscarDadosAssociacaoList();
        buscarDadosPoderList();
        buscarDadosVendedoresList();
        buscaDadosContratosAndSetSelected();
        buscarDadosCargoList();
        buscarDadosPlanoList()

    }, [idParam]);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/atendimentos");
    }, []);

    const clickCancelModalAddContato = useCallback(() => {
        setModalAddContato(false);
        refFormModal.current.resetFields();

        refClickModal.current = true
    }, []);

    const clickCancelModalAddCONTRATO = useCallback(() => {
        setModalAddCONTRATO(false);
        refFormModalCONTRATO.current.resetFields();
    }, []);

    const clickSubmitSalvarAtendimento = useCallback((): void => {

        formRef.current?.validateFields()
            .then((values: any) => {

                let obj = {
                    id: parseInt(idAtendimento.current),
                    telefone_principal: unMask(values.telefone_principal),
                }
                // values.id = parseInt(idAtendimento.current)
                // values.contrato = { id: values.contrato_id }

                const salvarDadosModal = async () => {
                    try {
                        setLoadingFormAdd(true);

                        let resultCidade = await axios.patch(urlAtendimentoIdTelefone(idAtendimento.current), obj,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram atualizados com sucesso!',
                        });
                        setLoadingFormAdd(false);

                        // onClickVoltarConfigOfSystem();

                    } catch (error) {

                        let msgErro: any = (error as Error);
                        setLoadingFormAdd(false);

                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                        });

                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const clickSubmitModalAddCONTRATO = useCallback((): void => {

        refFormModalCONTRATO.current?.validateFields()
            .then((values: any) => {

                values.id = parseInt(idAtendimento.current)
                // values.contrato = { id: values.contrato_id }

                const salvarDadosModal = async () => {
                    try {
                        setLoadingFormAdd(true);

                        let resultCidade = await axios.patch(urlAtendimentoIdContrato(idAtendimento.current), values,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram atualizados com sucesso!',
                        });
                        setLoadingFormAdd(false);
                        buscarAtendimentoEContatoESetCampos(idAtendimento.current);
                        clickCancelModalAddCONTRATO();
                    } catch (error) {
                        let msgErro: any = (error as Error);
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const clickCancelModalVincularUsuario = useCallback(() => {
        setModalVincularUsuario(false);
        refFormModalVincularUsuario.current.resetFields();
    }, []);

    const clickSubmitModalVincularUsuario = useCallback((): void => {

        const salvarDadosModalVincularUsuario = async (values: any) => {
            try {
                setLoadingFormAdd(true);

                let resultCidade = await axios.post(urlUsersAutomaticoAtendimento, values,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Usuário criado com sucesso!',
                });

                setLoadingFormAdd(false);
                buscarAtendimentoEContatoESetCampos(idAtendimento.current);
                clickCancelModalVincularUsuario();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        refFormModalVincularUsuario.current?.validateFields()
            .then((values: any) => {


                values = {
                    ...values,
                    nome: values.nome,
                    cargo_id: values.cargo_id,
                    celular: unMask(values.celular),
                    validade_teste: convertDataBrParaUS(selectedDateVincularUser),
                    data_proximo_contato: selectedDateVincularUserProximoContato && selectedDateVincularUserProximoContato != '' ? convertDataEHoraBrParaUS(selectedDateVincularUserProximoContato) : undefined,
                    contrato_id: refAllDados.current?.contratoId,
                    // cidades: [{ id: refAllDados.current?.cidadeId }]
                    cidade_id: refAllDados.current?.cidadeId
                }

                //poder do atendimento
                const nomePoder = encontrarPoderPorId(refAllDados.current?.poderId)?.nome

                const nomeCargo = encontrarCargoPorId(values.cargo_id)?.nome
                if (
                    nomePoder &&
                    nomePoder === 'Executivo'
                ) {
                    if (nomeCargo &&
                        nomeCargo === 'Vereador(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Vereador no contrato de poder Executivo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModalVincularUsuario(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                    if (nomeCargo &&
                        nomeCargo === 'Presidente da Câmara') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Presidente da Câmara no contrato de poder Executivo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModalVincularUsuario(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }

                }

                if (
                    nomePoder &&
                    nomePoder === 'Legislativo'
                ) {
                    if (nomeCargo &&
                        nomeCargo === 'Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModalVincularUsuario(values);
                            },
                            onCancel() {
                            },
                        });
                        return false

                    }
                    if (nomeCargo &&
                        nomeCargo === 'Ex-Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Ex-Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModalVincularUsuario(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                    if (nomeCargo &&
                        nomeCargo === 'Vice Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Vice Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModalVincularUsuario(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                }


                salvarDadosModalVincularUsuario(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [selectedDateVincularUser, selectedDateVincularUserProximoContato]);

    const criarContratoAutomatico = useCallback((): void => {

        const salvarDadosModal = async () => {
            try {
                setLoadingFormAdd(true);

                let obj = {
                    "poder_id": refAllDados.current?.poderId,
                    "nome": refAllDados.current?.nome,
                    "vendedor_id": refAllDados.current?.vendedorId,
                    "cidade_id": refAllDados.current?.cidadeId,
                }

                let result = await axios.post(urlContratosAutomatico, obj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                let objContrato = {
                    id: parseInt(idAtendimento.current),
                    contrato_id: result.data.id
                }

                let resultContrato = await axios.patch(urlAtendimentoIdContrato(idAtendimento.current), objContrato,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Contrato criado com sucesso!',
                });
                setLoadingFormAdd(false);
                buscarAtendimentoEContatoESetCampos(idAtendimento.current);
                clickCancelModalAddCONTRATO();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }
        salvarDadosModal();

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refFormModal.current.setFieldsValue(
            { telefone: maskedValue }
        );
        if (maskedValue.length == 16) {
            refFormModal.current.validateFields(["telefone"])
        }

    }, []);

    const onChangeInputTelefonePrincipal = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        formRef.current.setFieldsValue(
            { telefone_principal: maskedValue }
        );

        if (maskedValue.length == 16) {
            formRef.current.validateFields(["telefone_principal"])
        }

    }, []);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlBuscaUserByCelular + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const onChangeInputTelefoneModalVinUsr = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refFormModalVincularUsuario.current.setFieldsValue(
            { celular: maskedValue }
        );
        if (maskedValue.length == 16) {
            refFormModalVincularUsuario.current.validateFields(["celular"])
        }

    }, []);

    // const onChangeDataValidadeTeste = useCallback((e: any) => {
    //     const originalValue = unMask(e.target.value);
    //     const maskedValue = mask(originalValue, [
    //         "99/99/9999"
    //     ]);

    //     refFormModalVincularUsuario?.current?.setFieldsValue(
    //         { validade_teste: maskedValue }
    //     );

    // }, []);

    const onClickButtonVincularUsuario = useCallback(() => {

        let dataSomada = format(
            addDays(
                new Date(),
                7
            )
            , 'yyyy-MM-dd'
        )
        let dataEHoraSomada = format(
            addDays(
                new Date(),
                7
            )
            , 'yyyy-MM-dd HH:mm'
        )

        setModalVincularUsuario(true)

        setIsVisibleDateProximoContato(false);

        //poder do atendimento
        const poderNome = encontrarPoderPorId(refAllDados.current?.poderId)?.nome

        setTimeout(() => {
            refFormModalVincularUsuario.current.setFieldsValue({
                // validade_teste: dataSomada,
                viewContrato: refAllDados.current?.contrato?.nome + ' - ' + poderNome,
                viewCidade: refAllDados.current?.nome,
                cargo_id: 28,
            });

            if (refContratoSituacao.current?.nome === 'Período de Teste' ||
                refContratoSituacao.current?.nome === 'Automático') {

                setSelectedDateVincularUser(moment(dataSomada))

            }

            // setSelectedDateVincularUserProximoContato(moment(dataEHoraSomada))
            inputNomeModalVinUser.current?.focus();
        }, 500);



    }, []);

    const clickCancelModalListUserVinculados = useCallback(() => {
        setModalListUserVinculados(false);
    }, []);

    const onClickListUsuarioVinculadosDoContrato = useCallback(() => {

        const buscaDeDados = async () => {

            try {

                if (refAllDados.current?.contratoId) {

                    setLoadingFormAdd(true);

                    //abrir modal
                    setModalListUserVinculados(true)

                    let resultUsuarios = await axios.get(urlContratoUsuarios + "/" + refAllDados.current?.contratoId, {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                    setDadosTabelaListUserVinculados(resultUsuarios.data);

                    setLoadingFormAdd(false);
                } else {
                    notification.error({
                        message: 'Erro',
                        description: `Contrato inexistente!`
                    });
                }

            } catch (error) {
                setLoadingFormAdd(false);
                let msgErro: any = (error as Error);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : `Não foi possivel buscar usuários, entre em contato com suporte!`
                });
            }

        }

        buscaDeDados();

    }, []);

    //abrir navegar em add contrato
    const clickBotaoEditarContrato = useCallback((): void => {

        const buscaDeDados = async () => {

            // if (selectedRowKeys.length == 1) {
            if (refAllDados.current?.contratoId) {

                let objSelecionado: any = {}

                try {
                    let resultContrato = await axios.get(urlContrato + '/' + refAllDados.current.contratoId,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    objSelecionado = resultContrato.data

                    let resultVendedores = await axios.get(urlContratoVendedores + '/' + refAllDados.current?.contratoId,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    objSelecionado.vendedores = resultVendedores.data;

                    let resultEstados = await axios.get(urlContratoEstados + '/' + refAllDados.current?.contratoId,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    objSelecionado.estados = resultEstados.data;

                    let resultCidades = await axios.get(urlCidadesContrato + '/' + refAllDados.current?.contratoId,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    objSelecionado.cidades = resultCidades.data;

                    localStorage.setItem('@GovFacil:contrato', JSON.stringify(objSelecionado));

                    history.push('/contrato/adicionar');
                } catch (error) {

                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, []);

    const clickBotaoViewDadosContatoModal = useCallback((idModal): void => {
        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let vendedorObj = dadosVendedoresList.filter((element) => {
            return element.id === parseInt(objClicado[0].vendedorId)
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        // iulhpuihewf

        let objView = {
            data: moment(objClicado[0].data).format('DD/MM/YYYY HH:mm'),
            data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato).format('DD/MM/YYYY HH:mm') : '',
            id: objClicado[0]?.id,
            nome: objClicado[0]?.nome,
            observacao: objClicado[0]?.observacao,
            status: objClicado[0]?.status,
            tipo: objClicado[0]?.tipo,
            telefone: fotMatTelefone,
            uuid: objClicado[0]?.uuid,
            vendedor: vendedorObj[0]?.nome,
        }

        setModalViewDadosContato(true);

        setDadosViewDadosContatoInModal(objView)

    }, [dadosTabelaContato, dadosVendedoresList]);

    const clickBotaoEditarModal = useCallback((idModal): void => {
        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        setModalAddContato(true);


        setTimeout(() => {
            refFormModal.current?.setFieldsValue(
                {
                    nome: objClicado[0].nome,
                    telefone: fotMatTelefone,
                    vendedor_id: objClicado[0].vendedorId,
                    // data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : undefined,
                    status: objClicado[0].status,
                    tipo: objClicado[0].tipo,
                    observacao: objClicado[0].observacao,
                }
            );

            handleDateChange(objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : null)

            setSwitchImportantChecked(objClicado[0].importante)
            setStateValueStatusModalContato(objClicado[0].status)
            setStateValueTipoModalContato(objClicado[0].tipo)
        }, 500);

        // setInitialValueEditIdContato(objClicado[0].id)
        refInitialValueEditIdContato.current = objClicado[0].id

    }, [dadosTabelaContato]);

    const clickBotaoAddModalWithInputsPreInseridos = useCallback((idModal): void => {

        refInitialValueEditIdContato.current = undefined

        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        setModalAddContato(true);


        setTimeout(() => {
            refFormModal.current?.setFieldsValue(
                {
                    nome: objClicado[0].nome,
                    telefone: fotMatTelefone,
                    vendedor_id: objClicado[0].vendedorId,
                    // data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : undefined,
                    status: objClicado[0].status,
                    tipo: objClicado[0].tipo,
                    // observacao: objClicado[0].observacao,
                }
            );
            setStateValueStatusModalContato(objClicado[0].status)
            setStateValueTipoModalContato(objClicado[0].tipo)
        }, 500);

        handleDateChange(null)

    }, [dadosTabelaContato]);



    const onChangeSwitchIsVisibleDateProximoContato = useCallback((checked): void => {
        let dataEHoraSomada = format(
            addDays(
                new Date(),
                7
            )
            , 'yyyy-MM-dd HH:mm'
        )

        setTimeout(() => {
            setSelectedDateVincularUserProximoContato(moment(dataEHoraSomada))
        }, 500);

        setIsVisibleDateProximoContato(checked)
    }, []);

    const onChangeSwitchContatoImportant = useCallback((checked): void => {
        setSwitchImportantChecked(checked)
    }, []);

    const clickSubmitModalAddContato = useCallback((inativarContrato = false): void => {

        setLoadingFormAdd(true);

        refFormModal.current?.validateFields()
            .then((values: any) => {

                values = {
                    ...values,
                    atendimento_id: parseInt(idAtendimento.current),
                    telefone: unMask(values.telefone),
                    // telefone_principal: unMask(values.telefone),
                    importante: switchImportantChecked,
                    // data_proximo_contato: values?.data_proximo_contato ? convertDataEHoraBrParaUS(values.data_proximo_contato) : undefined
                    data_proximo_contato: selectedDate ? convertDataEHoraBrParaUS(selectedDate) : null
                }


                if (refInitialValueEditIdContato.current) {
                    values.id = refInitialValueEditIdContato.current;

                    const editarDadosModal = async () => {
                        try {
                            let resultCidade = await axios.put(urlAtendimentoIdContato(idAtendimento.current), values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });


                            buscarAtendimentoEContatoESetCampos(idAtendimento.current);

                            if (inativarContrato) {

                                if (refAllDados.current?.contratoId) {
                                    await axios.patch(urlContrato + "/" +
                                        refAllDados.current?.contratoId,
                                        { status: false },
                                        {
                                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                                            headers: { 'Authorization': 'Bearer ' + getToken() }
                                        })
                                }
                            }

                            clickCancelModalAddContato();

                            // setInitialValueEditIdContato(undefined);
                            refInitialValueEditIdContato.current = undefined

                            setLoadingFormAdd(false);
                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {


                    const salvarDadosModal = async () => {
                        try {

                            let resultCidade = await axios.post(urlAtendimentoIdContato(idAtendimento.current), values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });

                            if (inativarContrato) {

                                if (refAllDados.current?.contratoId) {

                                    await axios.patch(urlContrato + "/" +
                                        refAllDados.current?.contratoId,
                                        { status: false },
                                        {
                                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                                            headers: { 'Authorization': 'Bearer ' + getToken() }
                                        })
                                }
                            }

                            setLoadingFormAdd(false);

                            buscarAtendimentoEContatoESetCampos(idAtendimento.current);

                            clickCancelModalAddContato();
                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }

                    const addContratoESalvarDadosModal = async (valores: any) => {

                        try {

                            valores.telefone_principal = unMask(values.telefone);

                            let resultCidade = await axios.post(urlAtendimentosAtendimento, valores,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });

                            idAtendimento.current = resultCidade.data.id
                            setId(resultCidade.data.id);

                            //SALVAR DADOS MODAL
                            values.atendimento_id = parseInt(resultCidade.data.id)

                            let resultmODAL = await axios.post(urlAtendimentoIdContato(resultCidade.data.id), values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });

                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });

                            buscarAtendimentoEContatoESetCampos(resultCidade.data.id);
                            clickCancelModalAddContato();
                            setLoadingFormAdd(false);


                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                            });
                        }
                    }
                    formRef.current.validateFields()
                        .then((values: any) => {

                            if (values.cidadeOuAssociacao === 'cidade') {

                                values.cidade_id = values.cidade
                            } else {

                                values.associacao_id = values.associacao
                            }

                            if (!id) {
                                addContratoESalvarDadosModal(values);
                            } else {
                                salvarDadosModal();
                            }


                        })
                        .catch((errorInfo: any) => {
                            refClickModal.current = true

                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });




                }


            })
            .catch((errorInfo: any) => {
                refClickModal.current = true
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [id, selectedDate, switchImportantChecked]);

    const onClickAddContato = useCallback((): void => {

        //get local storage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const addContato = async (valores: any) => {

            try {

                let result = await axios.post(urlAtendimentosAtendimentoVerificaExiste, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });


                setModalAddContato(true);

                if (localUserData.user.vendedor) {
                    refFormModal.current.setFieldsValue(
                        { vendedor_id: localUserData.user.id }
                    );
                }

                inputNomeContato.current.focus();

            } catch (error) {
                let msgErro: any = (error as Error);

                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                // setModalAddContato(true);
                // inputNomeContato.current.focus();

                //////////////////////////////////////////////////////////ANTIGO

                if (values.cidadeOuAssociacao === 'cidade') {

                    values.cidade_id = values.cidade
                } else {

                    values.associacao_id = values.associacao
                }

                handleDateChange(null);
                setSwitchImportantChecked(false);

                if (!id) {
                    addContato(values);
                } else {

                    //apenas abrir modal
                    setModalAddContato(true);

                    if (localUserData.user.vendedor) {
                        refFormModal.current.setFieldsValue(
                            { vendedor_id: localUserData.user.id }
                        );
                    }

                    inputNomeContato.current.focus();
                }


            })
            .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [id]);

    // const onChangeDataProximoContato = useCallback((e: any) => {
    //     const originalValue = unMask(e.target.value);
    //     const maskedValue = mask(originalValue, [
    //         "99/99/9999 99:99"
    //     ]);

    //     refFormModal.current.setFieldsValue(
    //         { data_proximo_contato: maskedValue }
    //     );

    // }, []);

    //Search da tabela de listagem

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex]?.nome ? record[dataIndex].nome.toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any): any => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : ''
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem

    const columnsModalListUserVinculados = [
        {
            title: 'Nome do Usuario',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '15%',
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...getColumnSearchPropsObjNome('cargo'),
            width: '8%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (cargo: any) => (
                cargo?.nome
            ),
        },
        {
            title: 'Telefone',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            width: '12%',
            render: (celular: any) => {
                const originalValue = unMask(celular);
                const maskedValue = mask(originalValue, [
                    "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]);
                return (
                    maskedValue
                )
            }
        },
        // {
        //     title: 'Validade teste',
        //     dataIndex: 'validade_teste',
        //     ...getColumnSearchPropsData('validade_teste'),
        //     width: '15%',
        //     render: (criado: any) => {
        //         let date = criado ? moment(criado).format('DD/MM/YYYY') : ''
        //         return (
        //             date ? date : ''
        //         )
        //     },
        // },
    ];

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeysTable.includes(record.id.toString())) {
            let filter = selectedRowKeysTable.filter((val) => {
                return val != record.id.toString()
            })
            if (filter.length === 1) {
                setSelectedRowKeysTable(filter);
            } else if (filter.length === 0) {
                setSelectedRowKeysTable(filter);
            } else {
                setSelectedRowKeysTable(filter);
            }

        } else {
            if (selectedRowKeysTable.length == 0) {
                setSelectedRowKeysTable([record.id.toString()]);
            } else {
                let teste = selectedRowKeysTable.concat(record.id.toString());
                setSelectedRowKeysTable(teste);
            }
        }
    }, [selectedRowKeysTable]);

    //checkbox da tabela de listagem
    const onSelectChangeClickChekbox = useCallback((selectedRowKeysTable): void => {
        if (selectedRowKeysTable.length > 1) {
            setSelectedRowKeysTable(selectedRowKeysTable);
        } else if (selectedRowKeysTable.length === 0) {
            setSelectedRowKeysTable(selectedRowKeysTable);
        } else {
            setSelectedRowKeysTable(selectedRowKeysTable);
        }
    }, [selectedRowKeysTable]);

    const rowSelectionContato = {
        // selectedRowKeysTable,
        // onChange: onSelectChangeClickChekbox,
        columnWidth: '1%'
    };

    const columnsTableContato = [
        {
            title: 'Data do contato',
            dataIndex: 'data',
            ...getColumnSearchPropsData('data'),
            width: '5%',
            render: (criado: any) => {
                let date = moment(criado).format('DD/MM/YYYY HH:mm')
                return (
                    date ? date : ''
                )
            },
        },
        {
            title: 'Nome do contato',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '7%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            // render: (vendedor: any) => (
            //     vendedor?.nome
            // ),
            render: (nome: any, record: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        zIndex: 5
                    }}>
                        <Text>{nome}</Text>
                        {
                            record?.telefone ?
                                <Button
                                    className='botaoWhatsApp_fwebfhewvouyfgvwa'
                                    type="primary"
                                    size='small'
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        const link = document.createElement('a');
                                        link.href = `https://api.whatsapp.com/send/?phone=55${record.telefone.trim()}&text&type=phone_number&app_absent=1`
                                        link.target = "_blank";

                                        document.body.appendChild(link);

                                        link.click();

                                        return
                                    }}
                                >
                                    <IconWhatsApp />
                                </Button>
                                :
                                <></>
                        }
                    </div >

                )
            }
        },
        {
            title: 'Próximo contato',
            dataIndex: 'data_proximo_contato',
            ...getColumnSearchPropsData('data_proximo_contato'),
            width: '5%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (criado: any) => {
                let date = criado ? moment(criado).format('DD/MM/YYYY HH:mm') : ''
                return (
                    date ? date : ''
                )
            },
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendedor',
            ...getColumnSearchPropsObjNome('vendedor'),
            width: '3%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (vendedor: any) => (
                vendedor?.nome
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchProps('status'),
            width: '3%',
            render: (val: any) => {
                let cor = '';
                switch (val) {
                    case 'Finalizado':
                        cor = '#006';
                        break;
                    case "Em teste":
                        cor = '#F2C521';
                        break;
                    case "Sem Retorno":
                        cor = '#f00';
                        break;
                    case "Processo em andamento":
                        cor = '#999';
                        break;
                    case "A Finalizar":
                        cor = '#2db7f5';
                        break;
                    case "Efetivado":
                        cor = '#17751b';
                        break;
                    case "Não renovou":
                        cor = '#F0F';
                        break;
                    case "Sem interesse":
                        cor = '#930';
                        break;
                    case "Habilitado / Sem interesse":
                        cor = '#f93';
                        break;
                    case "Em Contato":
                        cor = '#7931ec';
                        break;
                    case "Controle de Qualidade":
                        cor = '#7bd280';
                        break;
                    case "Bloqueado":
                        cor = '#ae2424';
                        break;
                    // case "Em teste":
                    //     cor = 'purple';
                    //     break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val}>
                            {val}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ...getColumnSearchProps('tipo'),
            width: '2.5%',
            render: (val: any) => {
                let cor = '';
                switch (val) {
                    case 'Finalizado':
                        cor = '#006';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag key={val}>
                            {val}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Ações',
            dataIndex: 'id',
            // ...getColumnSearchProps('created_at'),
            width: '8%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (idModal: any) => (
                <>
                    <Row>
                        <Tooltip title="Adicionar" color="green" key="adicionargreen">
                            <Button
                                type="primary"
                                className="botaoVincularUsuarioInAtendimentos"
                                onClick={() => clickBotaoAddModalWithInputsPreInseridos(idModal)}
                            >
                                <IconMais />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Editar" color="gold" key="editargold">
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                className="EditarCidadesContratoSalvas"
                                onClick={() => clickBotaoEditarModal(idModal)}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Visualizar" color="blue" key="visualizarblue">
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                onClick={() => clickBotaoViewDadosContatoModal(idModal)}
                            >
                                <SearchOutlined />
                            </Button>
                        </Tooltip>
                    </Row>
                </>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={loadingFormAdd}>
                <Row>
                    <Col {...cardLayout}>
                        <Title level={3}><SolutionOutlined style={{ fontSize: 35, marginRight: 10 }} /> {!id ? "Cadastrar" : "Editar"} Atendimento</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Atendimentos</a></Breadcrumb.Item>
                            <Breadcrumb.Item>{!id ? "Cadastrar" : "Editar"} Atendimento</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={formRef}
                    name="formNewAtendimentos"
                    layout="horizontal"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Row>
                        <Col {...colMeioIni}>
                            <Row>
                                {!id &&
                                    <>
                                        <Col {...selectedVinteQuatro}>
                                            <Form.Item
                                                name="cidadeOuAssociacao"
                                                // label="Poder"
                                                initialValue='cidade'
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione!',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group onChange={e => {
                                                    setValorOnChangeCity(e.target.value)
                                                    if (e.target.value === 'cidade') {
                                                        let valorCampoCidade = formRef.current.getFieldValue('cidade');
                                                        if (valorCampoCidade) {
                                                            let arrCidadeSelecionada = dadosCidadesList.filter(element => {
                                                                return element.id === valorCampoCidade
                                                            });

                                                            refNomeCidadeParaModalContato.current = arrCidadeSelecionada[0]?.nome
                                                        }
                                                    } else {
                                                        //associacao
                                                        let valorCampoAssociacao = formRef.current.getFieldValue('associacao');
                                                        if (valorCampoAssociacao) {
                                                            let arrAssociacaoSelecionada = dadosAssocList.filter(element => {
                                                                return element.id === valorCampoAssociacao
                                                            });

                                                            refNomeCidadeParaModalContato.current = arrAssociacaoSelecionada[0]?.nome
                                                        }
                                                    }
                                                }}>
                                                    <Radio value='cidade'>Cidade</Radio>
                                                    <Radio value='associacao'>Associação</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                {dadosTabelaContato.length != 0 &&
                                    <Col {...selectedVinteQuatro}>
                                        <Form.Item
                                            name="status"
                                            label="Status"
                                            style={{ margin: 0, marginBottom: 15 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor selecione a cidade!',
                                                },
                                            ]}
                                        >
                                            <Tag
                                                style={{
                                                    fontSize: 16
                                                }}
                                                color={
                                                    valueTagStatus === 'Finalizado' ?
                                                        '#006' :
                                                        valueTagStatus === "Em teste" ?
                                                            '#F2C521' :
                                                            valueTagStatus === "Sem Retorno" ?
                                                                '#f00' :
                                                                valueTagStatus === "A Finalizar" ?
                                                                    '#2db7f5' :
                                                                    valueTagStatus === "Efetivado" ?
                                                                        '#17751b' :
                                                                        valueTagStatus === "Não renovou" ?
                                                                            '#F0F' :
                                                                            valueTagStatus === "Habilitado / Sem interesse" ?
                                                                                '#f93' :
                                                                                valueTagStatus === "Sem interesse" ?
                                                                                    '#930' :
                                                                                    valueTagStatus === "Em Contato" ?
                                                                                        '#7931ec' :
                                                                                        valueTagStatus === "Controle de Qualidade" ?
                                                                                            '#7bd280' :
                                                                                            valueTagStatus === "Bloqueado" ?
                                                                                                '#ae2424' :
                                                                                                valueTagStatus === "Processo em andamento" ?
                                                                                                    '#999' : ''
                                                }
                                            >
                                                {valueTagStatus}
                                            </Tag>
                                        </Form.Item>
                                    </Col>
                                }
                                {/* <Row> */}
                                <Col {...selectedPoder}>
                                    <Form.Item
                                        name="poder_id"
                                        label="Poder"
                                        initialValue={2}
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o poder!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            disabled={id}
                                            loading={loadingPoderSelected}
                                            notFoundContent={loadingPoderSelected ? <Spin size="small" /> : null}
                                            optionFilterProp="children"
                                            onSelect={(id: any, obj: any) => {

                                                refIdPoderParaModalContato.current = id

                                            }}
                                            placeholder="Selecione..."
                                            filterOption={(input: any, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {dadosPoderList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* </Row>
                            <Row> */}
                                <Col {...selectedCidade}>
                                    {
                                        valorOnChangeCity === 'cidade' ?

                                            <Form.Item
                                                name="cidade"
                                                label="Cidade"
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a cidade!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled={id}
                                                    loading={loadingSelectedCidades}
                                                    notFoundContent={loadingSelectedCidades ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeParaModalContato.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        dadosCidadesList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome + ' - ' + item?.microrregiao?.mesorregiao?.estado?.sigla}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                name="associacao"
                                                label="Associação"
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a associação!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled={id}
                                                    loading={loadingSelectedAssoc}
                                                    notFoundContent={loadingSelectedAssoc ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeParaModalContato.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        dadosAssocList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                    }
                                </Col>
                                {/* <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" /> */}
                            </Row>
                        </Col>
                        <Col {...colMeioMeio}>
                            {dadosTabelaContato.length != 0 &&
                                <Row>
                                    <Col {...selectedPoder} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        // backgroundColor: 'red',
                                        justifyContent: 'space-between',
                                        // alignItems: 'flex-end',

                                    }}>
                                        <Form.Item
                                            name="telefone_principal"
                                            label="Telefone Principal"
                                            style={{
                                                margin: 0, marginBottom: 15,
                                                width: '100%'
                                            }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor digite o telefone!',
                                                },
                                                () => ({
                                                    validator(rule, value) {
                                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                            if (value != '' && value != null) {
                                                                value = unMask(value);

                                                                if (value.length >= 10) {
                                                                    resolve();
                                                                } else {
                                                                    reject("Telefone inválido");
                                                                }
                                                            } else {
                                                                resolve();
                                                            }
                                                        });
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                type="tel"
                                                autoComplete="off"
                                                onChange={(e) => onChangeInputTelefonePrincipal(e)}
                                                placeholder="(44) 9 9999-9999"
                                            />
                                        </Form.Item>
                                        <Tooltip title="Salvar" color="green" key="salvarTelefonePrincipal">
                                            <Button
                                                style={{
                                                    marginBottom: 15,
                                                    marginLeft: 5
                                                }}
                                                className="botaoVincularUsuarioInAtendimentos"
                                                type="primary"
                                                onClick={() => clickSubmitSalvarAtendimento()}
                                            >
                                                <IconFloppyDisk />
                                            </Button>
                                        </Tooltip>
                                    </Col>

                                    <Col {...selectedCidade}>
                                        <Form.Item
                                            name="populacao"
                                            label="População"
                                            style={{ margin: 0, marginBottom: 15 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor digite o telefone!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col {...inputAtendViewMeioIni} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        // backgroundColor: 'red',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Form.Item
                                            name="contratoMostrar"
                                            label="Contrato"
                                            style={{
                                                margin: 0, marginBottom: 15,
                                                width: '100%'
                                            }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor selecione o Contrato!',
                                                },
                                            ]}
                                        >
                                            {/* <Select
                                                disabled
                                                showSearch
                                                placeholder="Selecione..."
                                                optionFilterProp="children"
                                                loading={loadingListContrato}
                                                notFoundContent={loadingListContrato ? <Spin size="small" /> : null}
                                                filterOption={(input: any, option: any) => (
                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                )}
                                            >
                                                {
                                                    dataListContrato.map((item: any) => {
                                                        return (
                                                            <Select.Option
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.nome}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select> */}
                                            <Input
                                                disabled
                                                autoComplete="off"
                                            // onChange={onChangeInputNumberNumero}
                                            // placeholder="Nome"
                                            />
                                        </Form.Item>
                                        <Tooltip title="Editar contrato" color="blue" key="editarcontrato">
                                            <Button
                                                style={{
                                                    marginBottom: 15,
                                                    marginLeft: 5
                                                }}
                                                disabled={!refAllDados.current?.contrato}
                                                type="primary"
                                                onClick={() => clickBotaoEditarContrato()}
                                            >
                                                <IconClipboard />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                    <Col {...campoValidadeDoTesteCol} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        // backgroundColor: 'red',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Form.Item
                                            name="validadeDeteste"
                                            label="Validade do teste"
                                            style={{
                                                margin: 0, marginBottom: 15,
                                                width: '100%'
                                            }}
                                            hidden={
                                                refContratoSituacao.current?.nome === 'Período de Teste' ||
                                                    refContratoSituacao.current?.nome === 'Automático' ?
                                                    false : true
                                            }
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor selecione o Contrato!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col {...botaoSelecionarContrato} style={{ backgroundColor: 'red' }}>
                                        {dadosTabelaContato.length != 0 &&
                                            valorOnChangeCity === 'cidade' &&
                                            <Button
                                                style={{ marginBottom: 15 }}
                                                type="primary"
                                                onClick={() => setModalAddCONTRATO(true)}
                                            >Selecionar contrato</Button>
                                        }
                                    </Col> */}

                                    <Col {...selectedVinteQuatro}>
                                        {dadosTabelaContato.length != 0 &&
                                            valorOnChangeCity === 'cidade' &&
                                            <>
                                                <Button
                                                    style={{ marginBottom: 15 }}
                                                    type="primary"
                                                    onClick={() => setModalAddCONTRATO(true)}
                                                >Selecionar contrato</Button>
                                                <Button
                                                    style={{ marginBottom: 15, marginLeft: 5 }}
                                                    disabled={!refAllDados.current?.contrato}
                                                    type="primary"
                                                    className="botaoVincularUsuarioInAtendimentos"
                                                    onClick={() => onClickButtonVincularUsuario()}
                                                >Vincular usuário</Button>

                                                <Tooltip title="Listar" color="blue" key="listarusuaurio">
                                                    <Button
                                                        style={{
                                                            marginBottom: 15,
                                                            marginLeft: 5
                                                        }}
                                                        disabled={!refAllDados.current?.contrato}
                                                        type="primary"
                                                        onClick={() => onClickListUsuarioVinculadosDoContrato()}
                                                    >
                                                        <TeamOutlined />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            }
                            {/* <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" /> */}
                        </Col>
                        {/* <Col {...colMeioFim}>
                            <Row>
                                <Col span={24}>
                                    {dadosTabelaContato.length != 0 &&
                                        valorOnChangeCity === 'cidade' &&
                                        <Button
                                            style={{ marginBottom: 15 }}
                                            type="primary"
                                            onClick={() => setModalAddCONTRATO(true)}
                                        >Selecionar contrato</Button>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {dadosTabelaContato.length != 0 &&
                                        valorOnChangeCity === 'cidade' &&
                                        <Button
                                            style={{ marginBottom: 15 }}
                                            disabled={!refAllDados.current.contrato}
                                            type="primary"
                                            onClick={() => onClickButtonVincularUsuario()}
                                        >Vincular usuário</Button>
                                    }
                                </Col>
                            </Row>
                        </Col> */}
                        <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button
                                style={{ marginBottom: 20 }}
                                type="primary"
                                onClick={() => onClickAddContato()}
                            >Adicionar Contato</Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={24}>
                        {dadosTabelaContato.length != 0 &&
                            <Table
                                rowKey={(record) => record.id.toString()}
                                loading={loadingTable}
                                onRow={(record, rowIndex) => {
                                    return {
                                        // onClick: event => { onclickTable(record, rowIndex) }, // click row
                                    };
                                }}
                                // rowSelection={rowSelectionContato}
                                columns={columnsTableContato} dataSource={dadosTabelaContato}
                                // scroll={{ x: 695 }}
                                scroll={{ x: 995 }}
                                size="small"
                                pagination={{ locale: { items_per_page: '' } }}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Divider />
                    <Col {...botaoSalvar}>
                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>

                            <Button style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                                Cancelar
                            </Button>
                            {/* {id &&
                                <Button type="primary" onClick={clickSubmitSalvarAtendimento} >
                                    Atualizar
                                </Button>
                            } */}
                        </div>
                    </Col>
                </Row>
                <Modal
                    title="Adicionar Contato"
                    visible={modalAddContato}
                    className="modalAddAtendimentos"
                    onOk={() => {
                        if (refClickModal.current) {
                            refClickModal.current = false
                            clickSubmitModalAddContato()
                            setStateValueStatusModalContato(null)
                            setStateValueTipoModalContato(null)
                        }
                    }}
                    onCancel={() => {
                        clickCancelModalAddContato()
                        setStateValueStatusModalContato(null)
                        setStateValueTipoModalContato(null)
                    }}
                    footer={[
                        <Button key="back" disabled={loadingFormAdd} onClick={() => {
                            clickCancelModalAddContato()
                            setStateValueStatusModalContato(null)
                            setStateValueTipoModalContato(null)
                        }}>
                            Cancelar
                        </Button>,
                        <React.Fragment key='fragmento'>
                            {!id ?
                                <Popconfirm
                                    key="pop"
                                    placement="topRight"
                                    title={() => (
                                        <div className='popConfirmEditAtendimentos'>
                                            <Text>
                                                {`Deseja realmente salvar contato para cidade de ${refNomeCidadeParaModalContato?.current} - ${encontrarNomePoderPeloID(refIdPoderParaModalContato?.current)}?`}
                                            </Text>
                                        </div>
                                    )}
                                    onConfirm={() => {
                                        if (refClickModal.current) {
                                            refClickModal.current = false
                                            clickSubmitModalAddContato()
                                            setStateValueStatusModalContato(null)
                                            setStateValueTipoModalContato(null)
                                        }
                                    }}
                                    onCancel={() => {
                                        setStateValueStatusModalContato(null)
                                        setStateValueTipoModalContato(null)
                                    }}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button key="talvez" type="primary" loading={loadingFormAdd}>
                                        Salvar
                                    </Button>
                                </Popconfirm>
                                :
                                <Button key="submit" type="primary" loading={loadingFormAdd} onClick={() => {
                                    if (refClickModal.current) {
                                        refClickModal.current = false
                                        clickSubmitModalAddContato()
                                        setStateValueStatusModalContato(null)
                                        setStateValueTipoModalContato(null)
                                    }
                                }}>
                                    Salvar
                                </Button>
                            }
                        </React.Fragment>,
                        <React.Fragment key='frgBotaoSalvarEInavitacr'>
                            {
                                (id && stateValueStatusModalContato != null &&
                                    [
                                        'Habilitado / Sem interesse',
                                        'Não renovou',
                                        'Sem interesse',
                                        'Bloqueado'
                                    ].includes(stateValueStatusModalContato)) ?
                                    <Tooltip title="Salvar e Inativar Contrato" color="red" key="tolptsalareinativrconatrao">
                                        <Button key="submit"
                                            type="primary"
                                            loading={loadingFormAdd}
                                            onClick={() => {
                                                if (refClickModal.current) {
                                                    refClickModal.current = false
                                                    clickSubmitModalAddContato(true)
                                                    setStateValueStatusModalContato(null)
                                                    setStateValueTipoModalContato(null)
                                                }
                                            }}
                                            danger
                                        >
                                            Salvar e Inativar
                                        </Button>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </React.Fragment>,
                    ]}
                >
                    <Spin spinning={loadingFormAdd}>
                        <Form
                            ref={refFormModal}
                            name="formModalAddContato"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col {...modalAddModuloInputs} style={{ marginBottom: 5, marginLeft: 5 }}>
                                    <Row>
                                        <Text strong>{valorOnChangeCity === 'cidade' ?
                                            'Cidade:' : 'Associação'
                                        }</Text>
                                        <Text style={{ marginLeft: 5 }}>
                                            {refNomeCidadeParaModalContato?.current}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col {...modalAddModuloInputs} style={{ marginBottom: 5, marginLeft: 5 }}>
                                    <Row>
                                        <Text strong>
                                            {'Poder: '}
                                        </Text>
                                        <Text style={{ marginLeft: 5 }}>
                                            {encontrarNomePoderPeloID(refIdPoderParaModalContato?.current)}
                                            {/* {refIdPoderParaModalContato?.current - 1} */}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o nome!",
                                            }
                                        ]}
                                        name="nome"
                                        label="Nome do contato"
                                        style={{ margin: 5 }}
                                    >
                                        <Input
                                            ref={inputNomeContato}
                                            autoComplete="off"
                                            // onChange={onChangeInputNumberNumero}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o telefone!",
                                            }
                                        ]}
                                        name="telefone"
                                        label="Telefone do contato"
                                        style={{ margin: 5 }}
                                    >
                                        <Input
                                            type="tel"
                                            autoComplete="off"
                                            onChange={(e) => onChangeInputTelefone(e)}
                                            placeholder="(44) 9 9999-9999"
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o data!",
                                            }
                                        ]}
                                        name="data_hora_contato"
                                        label="Data e Hora do contato"
                                        style={{ margin: 5 }}
                                    >
                                        <Input
                                        // onChange={onChangeInputNumberNumero}
                                        // placeholder="Ex: 1.1"
                                        />
                                    </Form.Item>
                                </Col> */}
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        name="vendedor_id"
                                        label="Vendedor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o vendedor!',
                                            },
                                        ]}
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            // mode="multiple"
                                            loading={loadingVendedoresSelected}
                                            notFoundContent={loadingVendedoresSelected ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            {
                                                dadosVendedoresList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    {/* Apenas escrito */}
                                    {/* <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o data!",
                                            },
                                            () => ({
                                                validator(rule, value) {
                                                    if (value != '' && value != null) {
                                                        if (value.length < 10) {
                                                            return Promise.reject('Data Inválida');
                                                        } else {
                                                            if (moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject('Data Inválida');
                                                            }
                                                        }

                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                            }),
                                        ]}
                                        name="data_proximo_contato"
                                        label="Próximo contato"
                                        style={{ margin: 5 }}

                                    >
                                        <Input
                                            autoComplete="off"
                                            placeholder="31/12/2000"
                                            onChange={onChangeDataProximoContato}
                                        // onChange={onChangeInputNumberNumero}
                                        // placeholder="Ex: 1.1"
                                        />
                                    </Form.Item> */}
                                    {/* Calendar Ant */}
                                    {/* <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Preencha o data!",
                                            },
                                            // () => ({
                                            //     validator(rule, value) {
                                            //         if (value != '' && value != null) {
                                            //             if (value.length < 10) {
                                            //                 return Promise.reject('Data Inválida');
                                            //             } else {
                                            //                 if (moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
                                            //                     return Promise.resolve();
                                            //                 } else {
                                            //                     return Promise.reject('Data Inválida');
                                            //                 }
                                            //             }

                                            //         } else {
                                            //             return Promise.resolve();
                                            //         }
                                            //     },
                                            // }),
                                        ]}
                                        name="data_proximo_contato"
                                        label="Próximo contato"
                                        style={{ margin: 5 }}

                                    >
                                        <DatePicker
                                            // showTime
                                            locale={locale}
                                            showTime={{ format: 'HH:mm' }}
                                            format="DD/MM/YYYY HH:mm"
                                        // onChange={onChange}
                                        // onOk={onOk}
                                        />
                                    </Form.Item> */}
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Preencha o data!",
                                            },
                                            // () => ({
                                            //     validator(rule, value) {
                                            //         if (value != '' && value != null) {
                                            //             if (value.length < 10) {
                                            //                 return Promise.reject('Data Inválida');
                                            //             } else {
                                            //                 if (moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
                                            //                     return Promise.resolve();
                                            //                 } else {
                                            //                     return Promise.reject('Data Inválida');
                                            //                 }
                                            //             }

                                            //         } else {
                                            //             return Promise.resolve();
                                            //         }
                                            //     },
                                            // }),
                                        ]}
                                        name="data_proximo_contato"
                                        label="Próximo contato"
                                        style={{ margin: 5 }}

                                    >
                                        {/* <DatePicker
                                            // showTime
                                            locale={locale}
                                            showTime={{ format: 'HH:mm' }}
                                            format="DD/MM/YYYY HH:mm"
                                        // onChange={onChange}
                                        // onOk={onOk}
                                        /> */}
                                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                            <DateTimePicker
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                required={false}
                                                style={{
                                                    width: '100%'
                                                }}
                                                okLabel="Ok"
                                                clearLabel="Limpar"
                                                cancelLabel="Cancelar"
                                                clearable
                                                format="dd/MM/yyyy HH:mm"
                                                className="datePickerCOlors"
                                            />
                                            {/* <KeyboardDateTimePicker
                                                variant="dialog"
                                                // ampm={false}
                                                // label="With keyboard"
                                                required={false}
                                                invalidLabel='chines'
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                // onError={}
                                                // disablePast
                                                format="dd/MM/yyyy HH:mm"
                                            /> */}
                                        </MuiPickersUtilsProvider>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    {/* <Switch checkedChildren="Sim" unCheckedChildren="Não" defaultChecked /> */}
                                    <Form.Item
                                        rules={[
                                            {
                                                // required: true,
                                                // message: "Preencha o status!",
                                            }
                                        ]}
                                        name="important"
                                        label="Contato Importante?"
                                        style={{ margin: 5 }}
                                    >
                                        <>
                                            <Switch checkedChildren="Sim" unCheckedChildren="Não" checked={switchImportantChecked} onChange={onChangeSwitchContatoImportant} />
                                        </>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o status!",
                                            }
                                        ]}
                                        name="status"
                                        label="Status"
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            onChange={(value) => {
                                                setStateValueStatusModalContato(value)
                                            }}
                                            // mode="multiple"
                                            // loading={loadingVendedoresSelected}
                                            // notFoundContent={loadingVendedoresSelected ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            <Select.Option
                                                value={"Efetivado"}
                                                key={"Efetivado"}
                                            >
                                                {"Efetivado"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Em Contato"}
                                                key={"Em Contato"}
                                            >
                                                {"Em Contato"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Em teste"}
                                                key={"Em teste"}
                                            >
                                                {"Em teste"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Finalizado"}
                                                key={"Finalizado"}
                                            >
                                                {"Finalizado"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Habilitado / Sem interesse"}
                                                key={"Habilitado / Sem interesse"}
                                            >
                                                {"Habilitado / Sem interesse"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Sem Retorno"}
                                                key={"Sem Retorno"}
                                            >
                                                {"Sem Retorno"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Não renovou"}
                                                key={"Não renovou"}
                                            >
                                                {"Não renovou"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Processo em andamento"}
                                                key={"Processo em andamento"}
                                            >
                                                {"Processo em andamento"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Sem interesse"}
                                                key={"Sem interesse"}
                                            >
                                                {"Sem interesse"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Controle de Qualidade"}
                                                key={"Controle de Qualidade"}
                                            >
                                                {"Controle de Qualidade"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Bloqueado"}
                                                key={"Bloqueado"}
                                            >
                                                {"Bloqueado"}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o tipo!",
                                            }
                                        ]}
                                        name="tipo"
                                        label="Tipo"
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            onChange={(value) => {
                                                setStateValueTipoModalContato(value)
                                            }}
                                            // mode="multiple"
                                            // loading={loadingVendedoresSelected}
                                            // notFoundContent={loadingVendedoresSelected ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            <Select.Option
                                                value={"Qualidade"}
                                                key={"Qualidade"}
                                            >
                                                {"Qualidade"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Contratação"}
                                                key={"Contratação"}
                                            >
                                                {"Contratação"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Renovação"}
                                                key={"Renovação"}
                                            >
                                                {"Renovação"}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        name="contrato"
                                        label="Selecione o Contrato"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Por favor selecione o Contrato!',
                                            },
                                        ]}
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Selecione..."
                                            optionFilterProp="children"
                                            loading={loadingListContrato}
                                            notFoundContent={loadingListContrato ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => (
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            )}
                                        >
                                            {
                                                dataListContrato.map((item: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                <Col {...modaisDeConfig}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Por favor comente!",
                                            }
                                        ]}
                                        name="observacao"
                                        label="Observação"
                                    >
                                        <Input.TextArea style={{ maxHeight: 200, height: 100 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title={<Text
                    // style={{
                    //     backgroundColor: 'red',
                    //     width: '100%',
                    //     height: '100%'
                    // }}
                    >{"Adicionar Contrato"}</Text>}
                    visible={modalAddCONTRATO}
                    className="modalContrato"
                    onOk={clickSubmitModalAddCONTRATO}
                    onCancel={clickCancelModalAddCONTRATO}
                    closable={true}
                    footer={[
                        <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddCONTRATO}>
                            Cancelar
                        </Button>,
                        <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddCONTRATO}>
                            Salvar
                        </Button>,
                    ]}
                >
                    <Spin spinning={loadingFormAdd}>
                        <Form
                            ref={refFormModalCONTRATO}
                            name="formModalAddCONTRATO"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col {...selectedVinteQuatro}>
                                    <Form.Item
                                        name="contrato_id"
                                        label="Contrato"
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o Contrato!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Selecione..."
                                            optionFilterProp="children"
                                            loading={loadingListContrato}
                                            notFoundContent={loadingListContrato ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                            dropdownRender={menu => (
                                                <div>
                                                    {!refAllDados.current?.contrato &&
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Button block
                                                                onClick={criarContratoAutomatico}
                                                                // ref={refButonAddOrgao}
                                                                type="dashed"
                                                            >Criar Automático</Button>
                                                        </div>
                                                    }
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {
                                                dataListContrato.map((item: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome + " - " + item.poder.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title="Vincular Usuário"
                    visible={modalVincularUsuario}
                    className="modalVincularUsuario"
                    onOk={clickSubmitModalVincularUsuario}
                    onCancel={clickCancelModalVincularUsuario}
                    footer={[
                        <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalVincularUsuario}>
                            Cancelar
                        </Button>,
                        <Button key="submit" type="primary"
                            loading={loadingFormAdd}
                            onClick={clickSubmitModalVincularUsuario}
                        >
                            Salvar
                        </Button>,
                    ]}
                >
                    <Spin spinning={loadingFormAdd}>
                        <Form
                            ref={refFormModalVincularUsuario}
                            name="formModalAddVincularUsuario"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        name="viewContrato"
                                        label="Contrato"
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Por favor selecione o Contrato!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...colModalVincularUsuarioInputs2}>
                                    <Form.Item
                                        name="viewCidade"
                                        label="Cidade"
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Por favor selecione o Contrato!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        name="plano_id"
                                        label="Selecione o Plano"
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o plano.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            loading={loadingPlanoSelected}
                                            notFoundContent={loadingPlanoSelected ? <Spin size="small" /> : null}
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            {
                                                dadosPlanoList.map((item) => {
                                                    return (

                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >

                                                            {item.nome}

                                                        </Select.Option>

                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                <Col {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o nome!",
                                            }
                                        ]}
                                        name="nome"
                                        label="Nome do usuário"
                                        style={{ margin: 0, marginBottom: 15 }}
                                    >
                                        <Input
                                            ref={inputNomeModalVinUser}
                                            autoComplete="off"
                                            // onChange={onChangeInputNumberNumero}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...colModalVincularUsuarioInputs2}>
                                    <Form.Item
                                        name="celular"
                                        label="Telefone"
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o telefone!",
                                                max: 16,
                                            },
                                            () => ({
                                                validator(rule, value) {
                                                    return new Promise((resolve: (value?: any) => void, reject) => {
                                                        //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                        if (value != '' && value != null) {
                                                            value = unMask(value);
                                                            if (value.length > 10) {
                                                                buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                                    if (valorPromessa) {
                                                                        reject("Celular já existe!");
                                                                    } else {
                                                                        resolve();
                                                                    }
                                                                }).catch(error => {
                                                                    resolve();
                                                                })
                                                            } else {
                                                                reject("Celular inválido");
                                                            }
                                                        } else {
                                                            reject('');
                                                        }
                                                    });
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            type="tel"
                                            autoComplete="off"
                                            onChange={(e) => onChangeInputTelefoneModalVinUsr(e)}
                                            placeholder="(44) 9 9999-9999"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        name="cargo_id"
                                        label="Cargo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o cargo!',
                                            },
                                        ]}
                                        style={{ margin: 0, marginBottom: 15 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            // mode="multiple"
                                            loading={loadingCargoListSelected}
                                            notFoundContent={loadingCargoListSelected ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCargo = removeAcento(option?.children);
                                                return listCargo.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            {
                                                dadosDoCargoList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col {...colModalVincularUsuarioInputs2}>
                                    <Form.Item
                                        initialValue={false}
                                        name="enviar_whatsapp"
                                        label="Enviar WhatsApp Cadastro"
                                        style={{ margin: 1, marginBottom: 15 }}
                                        rules={[
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Radio.Button value={true}>Sim</Radio.Button>
                                            <Radio.Button value={false}>Não</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                // required: true,
                                                // message: "Preencha o status!",
                                            }
                                        ]}
                                        name="isdateproximocontato"
                                        label="Deseja alterar a data do Próximo Contato?"
                                        style={{ margin: 5 }}
                                    >
                                        <>
                                            <Switch
                                                checkedChildren="Sim"
                                                unCheckedChildren="Não"
                                                checked={isVisibleDateProximoContato}
                                                onChange={onChangeSwitchIsVisibleDateProximoContato}
                                            />
                                        </>
                                    </Form.Item>
                                </Col>


                                <Col hidden={!isVisibleDateProximoContato} {...colModalVincularUsuarioInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Preencha o data!",
                                            },
                                            // () => ({
                                            //     validator(rule, value) {
                                            //         if (value != '' && value != null) {
                                            //             if (value.length < 10) {
                                            //                 return Promise.reject('Data Inválida');
                                            //             } else {
                                            //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                            //                     return Promise.resolve();
                                            //                 } else {
                                            //                     return Promise.reject('Data Inválida');
                                            //                 }
                                            //             }

                                            //         } else {
                                            //             return Promise.resolve();
                                            //         }
                                            //     },
                                            // }),
                                        ]}
                                        name="data_proximo_contato"
                                        label="Próx. Contato"
                                        style={{ margin: 0, marginBottom: 15 }}
                                    >
                                        {/* <Input
                                            autoComplete="off"
                                            placeholder="31/12/2000"
                                            onChange={onChangeDataValidadeTeste}
                                        /> */}
                                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                            <DateTimePicker
                                                value={selectedDateVincularUserProximoContato}
                                                onChange={setSelectedDateVincularUserProximoContato}
                                                required={false}
                                                style={{
                                                    width: '100%'
                                                }}
                                                okLabel="Ok"
                                                clearLabel="Limpar"
                                                cancelLabel="Cancelar"
                                                clearable
                                                format="dd/MM/yyyy HH:mm"
                                                className="datePickerCOlors"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Form.Item>
                                </Col>

                                {/* 'verificacao apenas pela col, para saber se deve ser uma ou a outra' */}
                                {isVisibleDateProximoContato ?
                                    <Col hidden={
                                        refContratoSituacao.current?.nome === 'Período de Teste' ||
                                            refContratoSituacao.current?.nome === 'Automático' ?
                                            false : true
                                    } {...colModalVincularUsuarioInputs}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Preencha o data!",
                                                },
                                            ]}
                                            name="validade_teste"
                                            label="Validade do Teste"
                                            style={{ margin: 0, marginBottom: 15 }}
                                        >
                                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                <DatePicker
                                                    value={selectedDateVincularUser}
                                                    onChange={setSelectedDateVincularUser}
                                                    required={false}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    okLabel="Ok"
                                                    clearLabel="Limpar"
                                                    cancelLabel="Cancelar"
                                                    clearable
                                                    format="dd/MM/yyyy"
                                                    className="datePickerCOlors"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Form.Item>
                                    </Col>
                                    :
                                    <Col hidden={
                                        refContratoSituacao.current?.nome === 'Período de Teste' ||
                                            refContratoSituacao.current?.nome === 'Automático' ?
                                            false : true
                                    } {...colModalVincularUsuarioInputs}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Preencha o data!",
                                                },
                                            ]}
                                            name="validade_teste"
                                            label="Validade do Teste"
                                            style={{ margin: 0, marginBottom: 15 }}
                                        >
                                            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                <DatePicker
                                                    value={selectedDateVincularUser}
                                                    onChange={setSelectedDateVincularUser}
                                                    required={false}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    okLabel="Ok"
                                                    clearLabel="Limpar"
                                                    cancelLabel="Cancelar"
                                                    clearable
                                                    format="dd/MM/yyyy"
                                                    className="datePickerCOlors"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title="Lista de usuários vinculados ao contrato"
                    visible={modalListUserVinculados}
                    className="modalVincularUsuario"
                    onOk={clickCancelModalListUserVinculados}
                    onCancel={clickCancelModalListUserVinculados}
                    footer={[
                        <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalListUserVinculados}>
                            Fechar
                        </Button>,
                        // <Button key="submit" type="primary"
                        //     loading={loadingFormAdd}
                        //     onClick={clickSubmitModalListUserVinculados}
                        // >
                        //     Salvar
                        // </Button>,
                    ]}
                >
                    <Table
                        rowKey={(record) => record.uuid}
                        loading={loadingFormAdd}
                        columns={columnsModalListUserVinculados}
                        dataSource={dadosTabelaListUserVinculados}
                        scroll={{ y: 200 }}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Modal>
                <Modal
                    title="Dados Contato"
                    visible={modalViewDadosContato}
                    className="classmodalDadosContatoInatendimento"
                    onOk={() => setModalViewDadosContato(false)}
                    onCancel={() => setModalViewDadosContato(false)}
                    footer={[
                        <Button key="back" onClick={() => setModalViewDadosContato(false)}>
                            Fechar
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>{'Nome:'}</Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.nome}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Telefone: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.telefone}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Vendedor: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.vendedor}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Status: </Text>
                                <Tag
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 14
                                    }}
                                    color={
                                        dadosViewDadosContatoInModal?.status === 'Finalizado' ?
                                            '#006' :
                                            dadosViewDadosContatoInModal?.status === "Em teste" ?
                                                '#F2C521' :
                                                dadosViewDadosContatoInModal?.status === "Sem Retorno" ?
                                                    '#f00' :
                                                    dadosViewDadosContatoInModal?.status === "A Finalizar" ?
                                                        '#2db7f5' :
                                                        dadosViewDadosContatoInModal?.status === "Efetivado" ?
                                                            '#17751b' :
                                                            dadosViewDadosContatoInModal?.status === "Não renovou" ?
                                                                '#F0F' :
                                                                dadosViewDadosContatoInModal?.status === "Habilitado / Sem interesse" ?
                                                                    '#f93' :
                                                                    dadosViewDadosContatoInModal?.status === "Sem interesse" ?
                                                                        '#930' :
                                                                        dadosViewDadosContatoInModal?.status === "Em Contato" ?
                                                                            '#7931ec' :
                                                                            dadosViewDadosContatoInModal?.status === "Controle de Qualidade" ?
                                                                                '#7bd280' :
                                                                                dadosViewDadosContatoInModal?.status === "Bloqueado" ?
                                                                                    '#ae2424' :
                                                                                    dadosViewDadosContatoInModal?.status === "Processo em andamento" ?
                                                                                        '#999' : ''
                                    }
                                >
                                    {dadosViewDadosContatoInModal?.status}
                                </Tag>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Tipo: </Text>
                                <Tag
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 14
                                    }}
                                // color={}
                                >
                                    {dadosViewDadosContatoInModal?.tipo}
                                </Tag>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Data do contato: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.data}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Data do próximo contato: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.data_proximo_contato}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Observação: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.observacao}
                                </Text>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
            </Spin >
        </>
    );
}

export default AddOrEditAtendimentos;
