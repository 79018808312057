import { notification } from "antd";

export const EmitirErro = (error: any, emitirAlert: boolean = true, keyNotification: null | string = null) => {

    let msgErro = new Error(error)

    if (msgErro?.message === 'Error: Network Error') {

        notification.error({
            message: 'Erro',
            description:
                'Você está sem conexão com a internet',
        });

    } else if (
        msgErro?.message === 'Requisicao cancelada!' ||
        msgErro?.message === 'CanceledError: canceled' ||
        msgErro?.message === 'canceled'
    ) {

    } else if (error?.response?.data?.message) {

        if (keyNotification) {
            notification.error({
                key: keyNotification,
                message: 'Erro',
                description: error.response.data.message,
            });
        }

    } else {
        if (process.env.NODE_ENV === 'development') console.log(error)

        if (emitirAlert) {
            if (keyNotification) {
                notification.error({
                    key: keyNotification,
                    message: 'Erro',
                    description:
                        'Entre em contato com o suporte!',
                });
            }
        }
    }

}
