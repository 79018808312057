import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {
    notification,
    Tag
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { stateGlobal } from '../../interfaces/Global';
import { useGetColumnSearchProps } from '../../hooks/FilterTable/stringCLearFiltros';
import { useGetColumnSearchPropsData } from '../../hooks/FilterTable/date';
import AcessosService from '../../services/http/services/Acessos/AcessosService';
import { IAcessosList, IAcessosListFilter } from './interface';
import { useGetColumnSearchPropsObj } from '../../hooks/FilterTable/objString';

const NAME_LS_STORAGE_FILTERS = '@GovFacil:FiltrosAcessos'
const NAME_LS_STORAGE_SORTERS = '@GovFacil:SortersAcessos'

export const useAcessos = () => {

    const history = useHistory();
    const propsState = useSelector((state: stateGlobal) => state.global);
    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IAcessosList>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    const [filterTipo_acessos, setFilterTipo_acessos] = useState<IAcessosListFilter>(() => {

        let dadosLocalStogFiltro: string | null = localStorage.getItem(NAME_LS_STORAGE_FILTERS);
        let localStogFiltro: any = null;
        if (typeof dadosLocalStogFiltro == 'string') {
            localStogFiltro = JSON.parse(dadosLocalStogFiltro);
        }

        if (localStogFiltro) {
            if (localStogFiltro) {
                return localStogFiltro
            } else {
                return []
            }
        } else {
            return []
        }
    });

    const [sortersTableListContrato, setSortersTableListContrato] = useState<{
        field: string | null
        order?: 'ascend' | 'descend'
    }>(() => {

        let dadosLocalStogFiltro: string | null = localStorage.getItem(NAME_LS_STORAGE_SORTERS);
        let localStogFiltro: any = null;
        if (typeof dadosLocalStogFiltro == 'string') {
            localStogFiltro = JSON.parse(dadosLocalStogFiltro);
        }

        if (localStogFiltro) {
            if (localStogFiltro) {
                return localStogFiltro
            } else {
                return null
            }
        } else {
            return null
        }
    });

    //executa apenas ao iniciar o component
    useEffect(() => {
        const buscaDeDados = async () => {
            try {
                setLoadingTable(true)

                let data = await AcessosService.getAcessos(signal.token)

                setDadosTabelaState(data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);


    //abrir navegar em add contrato
    const handleBtnAdicionar = useCallback((): void => {

        history.push(`/acessos/adicionar`);

    }, []);

    //abrir navegar em add contrato
    const handleBtnEditar = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                setLoadingTable(true)
                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    history.push(`/acessos/${objSelecionado?.id}`);

                } catch (error) {

                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);


    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);

                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);

                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);

                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);

                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);


    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);

            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);

            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);

            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);


    const columnSearchPropsNome = useGetColumnSearchProps('nome', refFuncaoLimparFiltrosString);
    const columnSearchPropsOBJPlano = useGetColumnSearchPropsObj('plano');
    const columnSearchPropsDataVigenciaAcesso = useGetColumnSearchPropsData('vigencia_acesso');

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = useMemo(() => {

        return [
            {
                title: 'Nome',
                dataIndex: 'nome',
                ...columnSearchPropsNome,
                width: '10%',
                defaultFilteredValue: (filterTipo_acessos?.nome && filterTipo_acessos?.nome !== null) ? filterTipo_acessos.nome : undefined,
                sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
                defaultSortOrder: ((sortersTableListContrato?.field && sortersTableListContrato?.field === 'nome') && (typeof sortersTableListContrato?.order === 'string')) ?
                    sortersTableListContrato?.order : undefined,
                showSorterTooltip: false,
            },
            {
                title: 'Plano',
                dataIndex: 'plano',
                ...columnSearchPropsOBJPlano,
                width: '6%',
                // defaultFilteredValue: (filterTipo_acessos?.plano?.nome && filterTipo_acessos?.plano?.nome !== null) ? filterTipo_acessos?.plano?.nome : undefined,
                // onFilter: (value: any, record: any) => {
                //     return record.plano?.nome?.indexOf(value) === 0
                //     // return true
                // },
                // sorter: (a: any, b: any) => a?.plano?.nome?.localeCompare(b?.plano?.nome),
                // defaultSortOrder: ((sortersTableListContrato?.field && sortersTableListContrato?.field === 'nome') && (typeof sortersTableListContrato?.order === 'string')) ?
                //     sortersTableListContrato?.order : undefined,
                showSorterTooltip: false,
                render: (plano: any): any => {
                    return (
                        plano?.nome
                    )
                },
            },
            {
                title: 'Vigência acesso',
                dataIndex: 'vigencia_acesso',
                ...columnSearchPropsDataVigenciaAcesso,
                width: '4%',
                defaultFilteredValue: (filterTipo_acessos?.vigencia_acesso && filterTipo_acessos?.vigencia_acesso !== null) ? filterTipo_acessos.vigencia_acesso : undefined,
                showSorterTooltip: false,
                sorter: (a: any, b: any): any => {

                    let aMonMen: number = 0
                    let bMonMen: number = 0
                    if (a?.vigencia_acesso) {
                        aMonMen = moment(a.vigencia_acesso).unix()
                    } else {
                        aMonMen = 0
                    }
                    if (b?.vigencia_acesso) {
                        bMonMen = moment(b.vigencia_acesso).unix()
                    } else {
                        bMonMen = 0
                    }

                    return aMonMen - bMonMen
                },
                defaultSortOrder: ((sortersTableListContrato?.field && sortersTableListContrato?.field === 'vigencia_acesso') && (typeof sortersTableListContrato?.order === 'string')) ?
                    sortersTableListContrato?.order : undefined,
                render: (vigencia_acesso: any): any => {
                    let data: any = null;
                    if (vigencia_acesso) {
                        data = moment(vigencia_acesso).format('DD/MM/YYYY')
                    }
                    return (
                        data
                    )
                },
            },
            {
                title: 'Teste',
                dataIndex: 'teste',
                // ...getColumnSearchProps('codigo'),
                width: '2%',
                render: (val: any) => {
                    let color = 'green';
                    let texto = 'Não';
                    if (val) {
                        color = 'blue'
                        texto = 'Sim'
                    }
                    return (
                        <span>
                            <Tag style={{ fontSize: 14 }} color={color} key={val}>
                                {texto}
                            </Tag>
                        </span>
                    )
                },
            },
        ];
    }, [filterTipo_acessos, columnSearchPropsNome, columnSearchPropsOBJPlano, columnSearchPropsDataVigenciaAcesso])

    return {
        selectedRowKeys,
        handleBtnAdicionar,
        handleBtnEditar,
        hasSelectedEdit,
        loadingTable,
        onclickTable,
        rowSelection,
        columns,
        dadosTabelaState,
        refContagemDoFiltro,
        propsState,
    }
}
