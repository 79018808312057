import React from 'react';
import {
    Table, Button, Row,
    Typography
} from 'antd';
import locale from 'antd/es/locale/pt_BR';

import { useAcessos } from './useAcessos';

const { Text } = Typography;
const NAME_LS_STORAGE_FILTERS = '@GovFacil:FiltrosAcessos'
const NAME_LS_STORAGE_SORTERS = '@GovFacil:SortersAcessos'

const Acessos: React.FC = () => {

    const {
        selectedRowKeys,
        handleBtnAdicionar,
        handleBtnEditar,
        hasSelectedEdit,
        loadingTable,
        onclickTable,
        rowSelection,
        columns,
        dadosTabelaState,
        refContagemDoFiltro,
        propsState,
    } = useAcessos();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                }}
            >
                <Row style={{ flexWrap: 'wrap' }} id='rowBtnAcessos_cewhjbwefogqfvw'>
                    <Button
                        type="primary"
                        className="btn_success_Acessos_fewuybcoqbfvcqow"
                        disabled={selectedRowKeys.length > 0}
                        onClick={handleBtnAdicionar}
                        style={{
                            margin: 3
                        }}

                    >
                        Adicionar
                    </Button>
                    <Button
                        type="primary"
                        className="btn_warning_Acessos_fewuybcoqbfvcqow"
                        onClick={handleBtnEditar}
                        disabled={!hasSelectedEdit}
                        style={{
                            margin: 3
                        }}
                    >
                        Editar
                    </Button>
                </Row>
            </div>
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.uuid}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 800 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource

                        if (extra.action === 'filter') {
                            localStorage.setItem(NAME_LS_STORAGE_FILTERS, JSON.stringify(filters));
                        }

                        if (extra.action === 'sort') {
                            localStorage.setItem(NAME_LS_STORAGE_SORTERS, JSON.stringify(sorter));
                        }

                    }
                }
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}

export default Acessos;
