import axios, { CancelToken } from 'axios';
import { getToken } from "../../../token";
import { urlHttpGov } from "../../../request/urls";
import { IReqModulos } from '../../../../pages/Acessos/Gerenciar/components/ModulosDegustacao';
import { IReqRecursos } from '../../../../pages/Acessos/Gerenciar/components/RecursosDegustacao';

class OthersService {

    async getRecursos(signal?: CancelToken): Promise<Array<IReqRecursos>> {

        const { data } = await axios.get(`${urlHttpGov}/contratos/plano/recurso`, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            cancelToken: signal
        });

        return data;
    }

    async getModulosDegustacaoByIdPlano(planoId: number | string, signal?: CancelToken): Promise<Array<IReqModulos>> {

        const { data } = await axios.get(`${urlHttpGov}/contratos/plano/${planoId}/modulo/degustacao`, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            cancelToken: signal
        });

        return data;
    }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OthersService();
